export class TransactionModel {
  tranId?: number;
  cp_identity?: string;
  connector_id?: number;
  meter_start?: number;
  meter_end?: number;
  start_time?: Date;
  stop_time?: Date;
  reservationId?: string;
  activitylog?:string;
  amp?:number;
  current?:number;
  voltage?:number;
  bookingId?:number;
  soc?:string;
  vin?:string;
  last_update?: Date;
  meter_current?: number;
  /*
   * S- Booking accepted
   * R -  Charging Running
   * C - Charging Complete
   */
  status?: string;
  unitConsumed?:number;
  stoppedReason?:string;
}
