import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { AppSettings } from "../config/AppSettings";
import { Tools } from '../modules/tools';
import {GridPage} from '../util/components/gridtable/gridtable.component';

@Injectable({
  providedIn: 'root'
})
export class ActivebookingService {

  constructor(private http: HttpClient, private appsetting: AppSettings,private tools: Tools) {}

  getChargersLiveStatus() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/chargerslivestatus", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  getBookingDetails(pageinfo : GridPage, searchField) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("page", pageinfo.number.toString());
    httpParam=httpParam.append("size",pageinfo.size.toString());
   httpParam=httpParam.append("status",searchField.status);
   httpParam=httpParam.append("startTime", this.tools.formatStartDateToDDMMYY(searchField.startdate));
    httpParam=httpParam.append("stopTime",this.tools.formatEndDateToDDMMYY(searchField.enddate));    
    if (searchField.chargerId!="-") {
      httpParam = httpParam.append("chargerId", searchField.chargerId);
    }  
    if (searchField.bookingId) {
      httpParam=httpParam.append("bookingId",searchField.bookingId);
    } 
    if (searchField.username) {
      httpParam=httpParam.append("username",searchField.username);
    }
    return this.http.get( this.appsetting.get("server-url") + '/api/booking-data',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }

  cancelBooking(bookingId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("bookingId", bookingId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/changebookingstatus", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }


  getPDF(bookingId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("bookingId", bookingId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/downloadpdfinvoice", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "blob"
      })
      .map(res => res);
  }

  getBooking(bookingId:number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("bookingId",bookingId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/getbooking", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  
}
