import { Component, OnInit, ViewChild, DoCheck } from "@angular/core";

import { HttpClient } from "@angular/common/http";

import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { ProfileOcppService } from "../../services/ProfileOcpp.service";
import { Tools } from "../../modules/tools";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { ProfileOcppModel } from "../../models/ProfileOcppModel.model";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { from } from "rxjs";
import { OperatorModel } from "src/app/models/operator.model";
import { OperatorService } from "src/app/services/operator.service";
import { OperatorDetailComponent } from "../../components/operator-detail/operator-detail.component";
import { ChargerComponent } from "../charger/charger.component";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ChargerModel } from "src/app/models/Charger.model";
@Component({
  selector: 'app-ocpp-server-status',
  templateUrl: './ocpp-server-status.component.html',
  styleUrls: ['./ocpp-server-status.component.css']
})
export class OcppServerStatusComponent implements OnInit {
  topbartext: TopbarText[] = [{ key: "serverStatus", displayText: "OCPP Server Status" }];
  status:string;
  state:string;
  data: ProfileOcppModel;
  serverIp:string;
  serverPort:string;
  endpoint:string;
  _data: ProfileOcppModel;
  _isSaved: boolean = false;
  page: GridPage;
  _menuId: string;
  datalist: Array<ProfileOcppModel>;
  _action: string;
  searchField = {
    searchText: ""
  };

  isDetailScreen: boolean = false;
  _disableSaveCancel: boolean = true;
  isStationScreen: boolean = false;
  isOperatorScreen: boolean = false;
  isChargerScreen: boolean = false;
  _chargeraction: string = "";
  _stationParam: any;
  constructor(private tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private stationservice: ProfileOcppService,
    private ngbModalService: NgbModal,
    private operatorservice: OperatorService,
    private managechargerservice: ManageChargerService,
    private httpClient: HttpClient
  ) {
    
    this._menuId = this.tools.getMenuName("main/serverStatus");
    this.httpClient.get("assets/config/Ocpp-config.json").subscribe(conf =>{
      console.log(conf);
    this.serverIp=conf["serverIP"];
    this.endpoint=conf["endpoint"];
    this.serverPort=conf["serverPort"]
      
  })
    console.log();
    this.objGlobal.setActiveMenu(this._menuId);
    this.status="Running"
    if(this.status=="Running"){
      this.state="Stop"
    }else{
      this.state="Running"
    }
  
  //  this.getList();
   // this.data = new ProfileOcppModel();
   // this.operator = new OperatorModel();
   // this.getOperators();
  }
  getActionButtonClass(){
    if (this.state == "Stop") {
      return "btn-danger";
    } else {
      return "btn-success";
    }
  }
  getActionButtonClassOnState(){
    if (this.status == "Stop") {
      return "btn-danger";
    } else if(this.status == "Running"){
      return "btn-success";
    }
  }
  ngOnInit() {
  }
  HandleStatus(){
    if(this.state=="Start"){
      this.state="Stop"
      this.status="Running"
    }else{
      this.state="Start"
      this.status="Stop"
    }
  }
}
