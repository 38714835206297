import { Component, OnInit } from "@angular/core";
import {
  GridPage,
  gridColumns
} from "src/app/util/components/gridtable/gridtable.component";
import { Tools } from "src/app/modules/tools";
import { global } from "src/app/modules/global";
import { TopbarText } from "../topbar/topbar.component";

@Component({
  selector: "app-connectivity-status-report",
  templateUrl: "./connectivity-status-report.component.html",
  styleUrls: ["./connectivity-status-report.component.css"]
})
export class ConnectivityStatusReportComponent implements OnInit {
  searchField = {
    searchText: ""
  };
  topbartext: TopbarText[] = [
    {
      key: "connectivitystatusreport",
      displayText: "Connectivity Status Report"
    }
  ];
  page: GridPage;
  _menuId: string;
  datalist: any;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-5" },
    {
      columnHeader: "",
      columnFieldName: "Id",
      columnClasses: "c-col-pc-0"
    },
    {
      columnHeader: "Connectivity Status",
      columnFieldName: "connectivityStatus",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Charger Id",
      columnFieldName: "chargerId",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Station",
      columnFieldName: "stationName",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Operator",
      columnFieldName: "operator",
      columnClasses: "c-col-pc-25"
    },
    {
      columnHeader: "Duration",
      columnFieldName: "duration",
      columnClasses: "c-col-pc-15"
    }
  ];
  constructor(private tools: Tools, private objGlobal: global) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/connectivitystatus");
    this.objGlobal.setActiveMenu(this._menuId);
  }
  loadData() {}
  ngOnInit() {}
}
