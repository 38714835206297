import { Component, OnInit,Input,Output,EventEmitter, AfterContentInit, AfterViewInit } from '@angular/core';

export class gridColumns {
  columnHeader : string;
  columnFieldName? : string;
  columnClasses? : string="";
  callbackFunction? : Function;  
  callbackFunctionRowPrm? : Function; 
  isSrCol? : boolean=false;
  callClassFunction? : Function;
}
export class GridPage {    
  offset? : number=0; /*Start record from*/
  totalPages?: number=0; /*number of pages*/
  totalElements? :number=0; /*number of records*/
  last? : boolean=true; /*is last page*/
  first? : boolean=true; /*is first page*/
  size? : number=0; /*page size*/
  number? : number=0; /* current page*/
  numberOfElements? : number; /*Total number of record*/
 }
@Component({
  selector: 'app-gridtable',
  templateUrl: './gridtable.component.html',
  styleUrls: ['./gridtable.component.css']
})

export class GridtableComponent implements OnInit {
  @Input()
  gridcolumns : gridColumns[];
  @Input()
  data :Array<object>;
  @Input()
  pdfButton : boolean = false;
  @Input()
  deleteButton : boolean = false;
  @Input()
  page : GridPage;  
  @Input()
  currentKeyValue : string;
  @Input()
  keyFieldName : string;
  @Output()
  loadDataEvent : EventEmitter<object>= new EventEmitter<object>();
  @Output()
  gridRowClickEvent : EventEmitter<object>=new EventEmitter<object>();
  @Output()
  pdfClickedEvent : EventEmitter<object>=new EventEmitter<object>();
  @Output()
  deleteClickedEvent : EventEmitter<object>=new EventEmitter<object>();
  @Output()
  rowDblClickEvent : EventEmitter<object>=new EventEmitter<object>();

  pages : number[] = [];
  maxPageButton : number =5;

  constructor() { 
    //console.log("test");
/*    if(this.data ==undefined) {
      this.data=new Array<object>();
    }*/
  }

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    this.page.number=0;
    this.triggerLoadDataEvent(0);
    this.setPages();
  }
  setPages() {
    this.pages=[];
    this.pages.push(1);
    for(let i=2; i<=this.page.totalPages && i<=this.maxPageButton;i++) {
        this.pages.push(i);
    }    
  }
  movePageButton(moveBy : number) {
    if((moveBy == -1 && this.pages[0] > 1) || (moveBy==1 && 
        this.pages[this.pages.length -1] <this.page.totalPages) ) {
        for (let i=0; i<this.pages.length; i++) {
            this.pages[i] = this.pages[i] + moveBy;
        }
    }
  }

  movePage(moveBy : number) {
    if((moveBy == -1 && (this.page.number + 1) == this.pages[0] && this.page.number > 0) ||
        (moveBy == 1 && (this.page.number +11) == this.pages[this.pages.length-1] && (this.page.number +1) < this.page.totalPages))  {
        this.movePageButton(moveBy);
    }
    if((moveBy == -1  && (this.page.number +1) >1) || (moveBy==1 && (this.page.number +1) < this.page.totalPages)) {
        this.page.number=this.page.number + moveBy;
        this.triggerLoadDataEvent(this.page.number);
    }      
  }
  setPageNumber(page: number){      
      this.triggerLoadDataEvent(page);
  }
  triggerLoadDataEvent(page:number) {
    this.page.number=page;
    var options ={pageSize : +this.page.size,
      from : ((this.page.number) * this.page.size)};
      this.loadDataEvent.emit(options);      
  }
  selectRow(obj) {
    this.gridRowClickEvent.emit(obj);
  }
  getValue(obj:any,col : gridColumns) {  
    let retvalue: string;
    let colfieldname =col.columnFieldName.split(".");
    if(col.callbackFunctionRowPrm) {
      retvalue=col.callbackFunctionRowPrm(obj);
    } else {
      let objColVal :any = obj;
      colfieldname.forEach(part => {
        if(objColVal) {
          objColVal=objColVal[part];
        }
      })

      if(col.callbackFunction) {
        //retvalue= col.callbackFunction(obj[col.columnFieldName]);
        retvalue= col.callbackFunction(objColVal);
      } else {
        retvalue=objColVal;
        //retvalue= obj[col.columnFieldName];
      }
    }
    return retvalue;
  }
  highlightGridRow(obj) {
    if(obj[this.keyFieldName]==this.currentKeyValue) {
      return true;
    } else {
      return false;
    }
  }
  cutText=function(text : string, length : number) {
    if(text.length > length) {
       return (text.substr(1,length-3) + "...");
    } else {
        return text;
    }   
  }
  deleteRec(obj :object,index : number) {
    let options = {obj : obj, index : index}
    this.deleteClickedEvent.emit(options);
  }
  
  pdfRec(obj :object,index : number) {
    let options = {obj : obj, index : index}
    this.pdfClickedEvent.emit(options);
  }
  onDblClick(O) {
    
    this.rowDblClickEvent.emit(O);
    
  }
  getColClass(obj:any,col : gridColumns) {
    let retvalue: string;
    let colfieldname =col.columnFieldName.split(".");
    let objColVal :any = obj;
    colfieldname.forEach(part => {
      if(objColVal) {
        objColVal=objColVal[part];
      }
    });
    if(col.callClassFunction) {      
      retvalue= col.callClassFunction(objColVal);
    } 
    if(col.columnClasses) {
      retvalue= retvalue? retvalue + " " + col.columnClasses :col.columnClasses;      
    }
    return retvalue;
  }
}
