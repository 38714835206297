import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppSettings } from '../config/AppSettings';
import { Tools } from '../modules/tools';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private appsetting : AppSettings,
    private tools: Tools) { }

  getLiveCp(stationId:number,chargerId:number,port:number) {
    let header = new HttpHeaders();
    let param : HttpParams=new HttpParams(); 
    param=param.append("stationId", stationId.toString());
    param=param.append("chargerId", chargerId.toString());
    param=param.append("port", port.toString());
    return this.http.get( this.appsetting.get("server-url") + '/api/dashboard/getlivecp',
      {headers : header, observe : "body", params : param,responseType : "json"})
    .map(res=>res);
  }

  getData(){
    let header = new HttpHeaders();
    let param : HttpParams=new HttpParams();
    return this.http.get( './assets/test.json',
    {headers : header, observe : "body", params : param,responseType : "json"})
  .map(res=>res);
  }

  getRevenue(searchField : any) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));
    return this.http.get( this.appsetting.get("server-url") + '/api/dashboard/revenue',{headers : header, observe : "body", params : httpParam,responseType : "json"})
        .map(res=>res);
  }

  getLiveTransaction(stationid:number,chargerId:number,port:number) {
    let header = new HttpHeaders();
    let param : HttpParams=new HttpParams(); 
    param=param.append("stationId", stationid.toString());
    param=param.append("chargerId", chargerId.toString());
    param=param.append("port", port.toString());
    return this.http.get( this.appsetting.get("server-url") + '/api/dashboard/getlivetransaction',
      {headers : header, observe : "body", params : param,responseType : "json"})
    .map(res=>res);
  }
  getLoad(stationid:number,chargerId:number,port:number){
    let header = new HttpHeaders();
    let param : HttpParams=new HttpParams(); 
    param=param.append("stationId", stationid.toString());
    param=param.append("chargerId", chargerId.toString());
    param=param.append("port", port.toString());
    return this.http.get( this.appsetting.get("server-url") + '/api/dashboard/getlatestload',
      {headers : header, observe : "body", params : param,responseType : "json"})
    .map(res=>res);
  }
  getUf(searchField : any) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));
    return this.http.get( this.appsetting.get("server-url") + '/api/dashboard/getuf',{headers : header, observe : "body", params : httpParam,responseType : "json"})
        .map(res=>res);
  }

  getTopLoad(searchField : any) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));
    return this.http.get( this.appsetting.get("server-url") + '/api/dashboard/gettopload',{headers : header, observe : "body", params : httpParam,responseType : "json"})
        .map(res=>res);
  }

  getLineChart(searchField : any) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));
    return this.http.get( this.appsetting.get("server-url") + '/api/dashboard/powerconsumption',{headers : header, observe : "body", params : httpParam,responseType : "json"})
        .map(res=>res);
  }

  getAlert(){
    let header = new HttpHeaders();
    let param : HttpParams=new HttpParams(); 
    return this.http.get( this.appsetting.get("server-url") + '/api/dashboard/getalarm',
      {headers : header, observe : "body", params : param,responseType : "json"})
    .map(res=>res);
  }

  

}
