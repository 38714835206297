import { Component, OnInit, ViewChild } from '@angular/core';
import { TopbarText } from "../topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import {EmspService } from "src/app/services/emsp.service";
import { EmspModel } from "../../models/emsp.model";
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";

import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";

class searchField {
  partyId?: string;
  businessName?: string;
  version?:number;
}
@Component({
  selector: 'app-emsp',
  templateUrl: './emsp.component.html',
  styleUrls: ['./emsp.component.css']
})
export class EmspComponent implements OnInit {
  datalist: Array<EmspModel>;
  isDetailScreen: boolean = false;
  data: EmspModel;
  _data:EmspModel;
  _action: string;
  _isSaved: boolean = false;
  page: GridPage;
  _menuId: string;
  dateNow: Date = new Date();
  topbartext: TopbarText[] = [{ key: "eMSP", displayText: "eMSP" }];
  searchField: searchField;

  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Id",
      columnFieldName: "id",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "BusinessName",
      columnFieldName: "businessName",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "CountryCode",
      columnFieldName: "countryCode",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Currency",
      columnFieldName: "currency",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "PartyId",
      columnFieldName: "partyId",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "DateTime",
      columnFieldName: "genTS",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.defaultdateTimeFormat
    }
  ];
 
  constructor(private ngbModalService: NgbModal,
    public emspService: EmspService,
    public tools: Tools,
    public objGlobal: global,
    public auth: AuthService,
  ) {
    this._data = new EmspModel();
  this.data = new EmspModel();
   this.searchField = new searchField();
   this.searchField.version=1;
    this.page = new GridPage();
    this._menuId = this.tools.getMenuName("main/emsp");
    this.objGlobal.setActiveMenu(this._menuId);
    
    this.page.size = 10; //Default page size
    this.geteMSPData();
   }
  ngOnInit() {
  }
    onRowDblClick(emsp: any) {
      this._action = "update";
      this.isDetailScreen = true;
      console.log(emsp);
     this.geteMSPDetail(emsp.id);
    }
    disableSave(f: any) {
      let isDisableSave: boolean = true;
          if(f.valid){
            if (this.isModified()) {
                if (this.isAllowAddorEdit()) {
            isDisableSave = false;
              }
            }
          }
      console.log(f);
      return isDisableSave;
    }
    geteMSPData() {
     this.emspService.getEmspList(this.page, this.searchField).subscribe(data => {
      // if(data["result"].content.booking.status!=="P"){
        if (data["success"]) {  
          console.log(data["result"].content);
          this.datalist = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
  
          }
        } else {
          alert("Failed to get Transaction Details!");
        }
      
      });
    }
    geteMSPDetail(Id:number) {
      console.log(Id)
      if (Id) {
       this.emspService.getDetail(Id).subscribe(data => {
        if (data["success"]) {
        this.data = data["result"];
      
        }
      })
    }
}
saveData() {
    
 
  this.emspService.save(this.data,this._action).subscribe(data => {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    if (data["success"]) {
      this._isSaved = true;
      const modalRef = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRef.componentInstance.title = "Information";
      modalRef.componentInstance.message = "eMSP saved successfully!";
      modalRef.componentInstance.btnCLass = "btn-info";
      modalRef.result.then(
        result => {
          this.data = data["result"];
          
          this._data = this.tools.copyFullObject(this.data);
          if (result == "ok") {
            //this.getList();
            this.isDetailScreen = false;
          }
        },
        reason => {
          this.data = data["result"];
          this._data = this.tools.copyFullObject(this.data);
        }
      );
    } else {
      const modalRef = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRef.componentInstance.title = "Error";
      modalRef.componentInstance.message = "Failed to save eMSP!";
      modalRef.componentInstance.btnCLass = "btn-warning";
      modalRef.result.then(
        result => {},
        reason => {}
      );
      // alert("station not saved");
    }
  });
}
async onAddNew() {
  this._action = "new";
  this.data = new EmspModel;
  this.data.version="2.1";
  this.data.countryCode="IN";
  this.data.currency="INR";
  this._data = this.tools.copyFullObject(this.data);
  this.isDetailScreen = true;
}
isAllowAdd() {
  let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
  return isAdd;
}

isAllowAddorEdit() {
  let isAllowSave = false;
  if (this._action == "new") {
    isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
  } else if (this._action == "update") {
    isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
  }
  return isAllowSave;
};

isModified() {
  if (!(this.data && this._data)) {
    return false;
  } else if (this.data.partyId!= this._data.partyId) {
    return true;
  } else if (this.data.businessName != this._data.businessName) {
    return true;
  } else if (this.data.version != this._data.version) {
    return true;
  }else if (this.data.countryCode != this._data.countryCode) {
    return true;
  }else if (this.data.currency != this._data.currency) {
    return true;
  }else if (this.data.cpoEndpoint != this._data.cpoEndpoint) {
    return true;
  }else if (this.data.emspEndpoint != this._data.emspEndpoint) {
    return true;
  }
  //else if 
 /// (this.subscription.planId !=this.dataSubscriptionId() ) {
  
 //   return true;
//  }
  return false;
}

onBackbuttonClick() {
  if (this.isModified() == true) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message =
      "Corporate detail has been modified. Are you sure to exit without save?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          this._action = "";
          this.isDetailScreen = false;

          if (this._isSaved) {
            this.geteMSPData();
          }
          this._isSaved = false;
        }
      },
      reason => {}
    );
  } else {
    this._action = "";

    this.isDetailScreen = false;
    if (this._isSaved) {
      this.geteMSPData();
    }
    this._isSaved = false;
  }
}
  onCloseDetailScreen(isChange: boolean) {
    if (isChange == true) {
     // this.getDatalist();
    }
    this.isDetailScreen = false;
  }
}
