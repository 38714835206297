
export class SubscriptionModel {
    name?:string;
    subId?: number;
    scope?: string;
    status?:string;
    formula?:string;
    gents?: Date;
    modts?: Date;
}
