import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NgInformationModalContent } from "src/app/util/components/modalcomponent/modal-content";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.css"]
})
export class ForgotpasswordComponent implements OnInit {
  confirmpassword: string;
  password: string;
  validationmsg: string;
  username: string;
  authtoken: string;
  type: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngbModalService: NgbModal,
    private authservice: AuthService
  ) {
    this.setQryStringParams();
  }

  ngOnInit() {}
  setQryStringParams() {
    this.route.queryParams.subscribe(params => {
      this.username = params.username;
      this.authtoken = params.token;
      this.type = params.type;
    });
  }
  hideValidation() {
    return this.validationmsg == undefined;
  }

  onLoginSubmit() {
    if (this.password != this.confirmpassword) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const modalRef = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRef.componentInstance.title = "Warning";
      modalRef.componentInstance.message = "Confirm password mismatch!";
      modalRef.componentInstance.btnCLass = "btn-warning";
      modalRef.result.then(
        result => {
          if (result == "ok") {
          }
        },
        reason => {}
      );
    } else {
      if (this.type == "M") {
        this.submitMobileResetPassword();
      } else {
        this.submitResetPassword();
      }
    }
  }

  submitResetPassword() {
    let param = {
      username: this.username,
      password: this.password,
      token: this.authtoken
    };
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    let pwdsuccess: Boolean = false;
    this.authservice.resetForgotPassword(param).subscribe(
      data => {
        if (data["success"]) {
          modalRef.componentInstance.title = "Success";
          modalRef.componentInstance.message = "Password changed!";
          modalRef.componentInstance.btnCLass = "btn-success";
          pwdsuccess = true;
        } else {
          modalRef.componentInstance.title = "Warning";

          modalRef.componentInstance.btnCLass = "btn-warning";
          switch (data["message"]) {
            case "MISSING_USERNAME":
              modalRef.componentInstance.message =
                "Missing user name in request!";
              break;
            case "MISSING_TOKEN":
              modalRef.componentInstance.message = "Missing token in request!";
              break;
            case "INVALID_USERID":
              modalRef.componentInstance.message = "User is not valid!";
              break;
            case "TOKEN_ALREADYUSED":
              modalRef.componentInstance.message = "Token already used!";
              break;
            case "INVALID_TOKEN":
              modalRef.componentInstance.message = "Token is not valid!";
              break;
          }
        }
        modalRef.result.then(
          result => {
            if (result == "ok") {
              if (pwdsuccess) {
                this.router.navigate(["/login"]);
              }
            }
          },
          reason => {}
        );
      },
      error => {
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Error resetting password!";
        modalRef.componentInstance.btnCLass = "btn-error";
        modalRef.result.then(
          result => {
            if (result == "ok") {
            }
          },
          reason => {}
        );
      }
    );
  }
  submitMobileResetPassword() {
    let param = {
      username: this.username,
      password: this.password,
      token: this.authtoken
    };
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    let pwdsuccess: Boolean = false;

    this.authservice.resetAppUserForgotPassword(param).subscribe(
      data => {
        if (data["success"]) {
          modalRef.componentInstance.title = "Success";
          modalRef.componentInstance.message = "Password changed!";
          modalRef.componentInstance.btnCLass = "btn-success";
          pwdsuccess = true;
        } else {
          modalRef.componentInstance.title = "Warning";

          modalRef.componentInstance.btnCLass = "btn-warning";
          switch (data["message"]) {
            case "MISSING_USERNAME":
              modalRef.componentInstance.message =
                "Missing user name in request!";
              break;
            case "MISSING_TOKEN":
              modalRef.componentInstance.message = "Missing token in request!";
              break;
            case "INVALID_USERID":
              modalRef.componentInstance.message = "User is not valid!";
              break;
            case "TOKEN_ALREADYUSED":
              modalRef.componentInstance.message = "Token already used!";
              break;
            case "INVALID_TOKEN":
              modalRef.componentInstance.message = "Token is not valid!";
              break;
          }
        }
        modalRef.result.then(
          result => {},
          reason => {}
        );
      },
      error => {
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Error resetting password!";
        modalRef.componentInstance.btnCLass = "btn-error";
        modalRef.result.then(
          result => {
            if (result == "ok") {
            }
          },
          reason => {}
        );
      }
    );
  }
  passwordvalidationMessage() {
    return "Password length 8-30 character, at least 1 capital, 1 small, 1 special symbol and 1 digit!";
  }

  disableSave(f) {
    return !f.valid;
  }
}
