import { Component, OnInit, ViewChild, Input,AfterViewInit } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { Tools } from "src/app/modules/tools";
import * as XLSX from "xlsx";
import { ActivatedRoute } from '@angular/router';
import { OperatorModel } from "src/app/models/operator.model";
import { AuthService } from "src/app/services/auth.service";
import { global } from "src/app/modules/global";
import { OperatorDetailComponent } from "../../components/operator-detail/operator-detail.component";

import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "src/app/util/components/gridtable/gridtable.component";
import { OperatorService } from "../../services/operator.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/util/components/modalcomponent/modal-content";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ChargerComponent } from "../../components/charger/charger.component";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";

export class ChargerData {
  SerialNo?: String;
  Type?: String;
  Category?: String;
  NoofPort?: String;
  Connector?: String;
  Mode?: String;
  Status?:String;
  Capacity?:String;
  Station?:String;
}
@Component({
  selector: "app-manage-charger",
  templateUrl: "./manage-charger.component.html",
  styleUrls: ["./manage-charger.component.css"]
})
export class ManageChargerComponent implements OnInit, AfterViewInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  @ViewChild(ChargerComponent) chargercomp: ChargerComponent;
  topbartext: TopbarText[] = [
    { key: "manage Charger", displayText: "Charger" }
  ];
  charger: Array<ManageChargerModel>;
  chargerData:Array<ChargerData>;
  lastModificationDate;
  _userParam: any;
  page: GridPage;
  _menuId: string;

  _action: string;
  searchField = {
    searchText: "",
    searchStatus:""
  };
  isimportscreen:boolean=true;
  isDetailScreen: boolean = false;
  _disableSaveCancel: boolean = true;
  isScreen: boolean = false;
  isimport:boolean = false;
  paramStatus: boolean = false;
  creationDate;
  data: ManageChargerModel;
  _stationaction: "new";
  @Input()
  _param: any;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Display Name",
      columnFieldName: "label",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Charger Type",
      columnFieldName: "chargerType",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Category",
      columnFieldName: "category",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "No.of Port",
      columnFieldName: "number_of_chargingpoints",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "Connector",
      columnFieldName: "connector",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Mode",
      columnFieldName: "mode",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.chargerMode
    },
    {
      columnHeader: "Current Status",
      columnFieldName: "ocppStatus",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Capacity",
      columnFieldName: "chargerCapacity",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "Station",
      columnFieldName: "station.stationname",
      columnClasses: "c-col-pc-10"
    }
  ];
  constructor(
    private route: ActivatedRoute,
    private tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private managechargerservice: ManageChargerService,
    private ngbModalService: NgbModal
  ) {
    console.log(this.paramStatus);
    if(!this.paramStatus){
    this.searchField.searchStatus="All";
   
    }
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/managecharger");
    this.objGlobal.setActiveMenu(this._menuId);
    
    this.data = new ManageChargerModel();
  }

  ngOnInit() {

    this.route.queryParams.filter(params => params.Status)
    .subscribe(params => {
     if(params.Status){
     this.paramStatus = true;
      this.searchField.searchStatus = params.Status;
      console.log(this.searchField.searchStatus ); 
      console.log(this.paramStatus); 
      this.getList();
     }
    }
  );
}


  saveEvent(obj) {
    if (obj.success == true) {
      this.getList();
      this.isDetailScreen = false;
      this.chargercomp.getStations();
    } else {
      this.getList();
      this.isDetailScreen = false;
    }
  }

  ngAfterViewInit() {}

  onAddNew() {
    this._action = "new";
    this.isDetailScreen = true; 
    this.chargercomp.onAddNew();
  }

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
    return isdelete;
  }
  getList() {
    this.managechargerservice
      .getChargerList(this.page, this.searchField)
      .subscribe(data => {
        if (data["success"]) {
         // console.log(data["result"].content);
          this.charger = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          this.createRevenueData(this.charger);
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
         
        } else {
          alert("Failed to get operator list!");
        }
      });
  }
  connectedDisconnected(event){
    console.log(event.target.value);
    this.searchField.searchStatus=event.target.value;
    this.getList();
  }
  _id: any;
  onRoleRowDblClick(mcharger: any) {
    //alert(mcharger.chargerId);
    this._action = "update";
    this.isDetailScreen = true;
    // this._userParam={id : rolelistval.operatorId};
    //this.router.navigate(['user']);
    this.chargercomp.getDetail(mcharger.chargerId);
    this.isScreen = false;
  }




  deleteCharger(recObj) {
    if (recObj && recObj.obj) {
      const objCharger: ManageChargerModel = recObj.obj;
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to delete charger?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            //console.log("test delete user");
            this.managechargerservice
              .deleteCharger(objCharger.chargerId)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Charger deleted successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      this.getList();
                    },
                    reason => {
                      this.getList();
                    }
                  );
                } else {
                  alert("Failed to delete charger!");
                }
              });
          }
        },
        reason => {}
      );
    }
  }
  onBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Operator detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
    }
  }

  export(){
    var csvData = this.ConvertToCSV( this.chargerData);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    var x:Date = new Date();
    var link:string ="Charger_"+ this.tools.formatDateToDDMMYY(x) +'.csv';
    a.download = link.toLocaleLowerCase();
    a.click();

  }
  uploadfile(){
    this.isimport=true;
    this.isimportscreen=false;
  }
  downloadExcel  () {
    let curdate = new Date();
    let curdatestring = this.tools.formatDateToDDMMYY(curdate);
    const worksheet = XLSX.utils.json_to_sheet(this.chargerData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Chargers_"+curdatestring+".xlsx");
  };
  createRevenueData(dataArray){
  //  console.log(dataArray)
    if(dataArray){
      let chargerlist: Array<ChargerData> = new Array<ChargerData>();
      
      let totalUnit=0;
      let totalAmt=0;
      let totalArray:ChargerData = new ChargerData();
   //   console.log(dataArray.length)
      for(let i=0;i<dataArray.length;i++){
        const charger: ChargerData = new ChargerData();
        charger.SerialNo = dataArray[i].chargerName;
        charger.Type = dataArray[i].chargerType;
        charger.Category = dataArray[i].category;
        charger.NoofPort = dataArray[i].number_of_chargingpoints;
        charger.Connector = dataArray[i].connector;
        charger.Mode= this.tools.chargerMode(dataArray[i].mode);
        charger.Status = dataArray[i].ocppStatus;
        charger.Capacity = dataArray[i].chargerCapacity;
        charger.Station = dataArray[i].station.stationname;
        //console.log(charger);
        chargerlist.push(charger);
        totalAmt=totalAmt+dataArray[i].amount;
        totalUnit= totalUnit+dataArray[i].unit;
      }
      
      this.chargerData = chargerlist;
    }
  }

  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
        //Now convert each value to string and comma-separated
        row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }

}
