import { OperatorModel } from './operator.model';

export class UserRole {
    roleId : number;
    roleName : string;
    description : string;
    privileges : string[];
}
export class UserModel {
    userId : number;
    name : string;
    username : string;
    userEmail? : string;
    password : string;
    userRePassword : string;
    distributorId : number;
    roleId? : number[];
    roleName : string[];
    operatorId? : number;
}

export class LoginUser{
    userId? : number;   
    name? : string;
    username? : string;
    userEmail? : string;
    token? : string;
}

