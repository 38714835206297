import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { Tools } from "src/app/modules/tools";
import { OperatorModel } from "src/app/models/operator.model";
import { AuthService } from "src/app/services/auth.service";
import { global } from "src/app/modules/global";
import { OperatorDetailComponent } from "../../components/operator-detail/operator-detail.component";

import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "src/app/util/components/gridtable/gridtable.component";
import { OperatorService } from "../../services/operator.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/util/components/modalcomponent/modal-content";
import { DateObject } from "ngx-bootstrap/chronos/types";
import { ThemeService } from "ng2-charts";

@Component({
  selector: "app-operators",
  templateUrl: "./operators.component.html",
  styleUrls: ["./operators.component.css"]
})
export class OperatorsComponent implements OnInit, AfterViewInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  @ViewChild(OperatorDetailComponent) operatorComp: OperatorDetailComponent;
  topbartext: TopbarText[] = [
    {
      key: "manage_charge_point_operator",
      displayText: "Manage Operator"
    }
  ];
  operators: Array<OperatorModel>;
  lastModificationDate;
  _userParam: any;
  page: GridPage;
  _menuId: string;

  _action: string;
  searchField = {
    searchText: ""
  };
  isDetailScreen = false;
  _disableSaveCancel: boolean = true;
  isScreen: boolean = false;
  creationDate;
  data: OperatorModel;

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Operator",
      columnFieldName: "operatorName",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Address1",
      columnFieldName: "addressline1",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Address2",
      columnFieldName: "addressline2",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "PostalCode",
      columnFieldName: "operatorPincode",
      columnClasses: "c-col-pc-10"
    },

    {
      columnHeader: "City",
      columnFieldName: "operatorCity",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "State",
      columnFieldName: "operatorState",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Country",
      columnFieldName: "operatorCountry",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Tax Description",
      columnFieldName: "taxDescription",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Tax Rate",
      columnFieldName: "taxrate1",
      columnClasses: "c-col-pc-10"
    }
  ];
  constructor(
    private tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private operatorservice: OperatorService,
    private ngbModalService: NgbModal
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/operators");
    this.objGlobal.setActiveMenu(this._menuId);
    this.getList();
    this.data = new OperatorModel();
  }

  ngOnInit() {}

  saveEvent(obj) {
    if (obj.success == true) {
      this.getList();
      this.isDetailScreen = false;
    } else {
      this.getList();
      this.isDetailScreen = false;
    }
  }

  ngAfterViewInit() {}

  onAddNew() {
    this._action = "new";

    this.isDetailScreen = true;

    this.operatorComp.onAddNew();
  }

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
    return isdelete;
  }
  getList() {
    this.operatorservice
      .getOperatorList(this.page, this.searchField)
      .subscribe(data => {
        if (data["success"]) {
          this.operators = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        } else {
          alert("Failed to get operator list!");
        }
      });
  }

  _id: any;
  onRoleRowDblClick(rolelistval: any) {
    this._action = "update";
    this.isDetailScreen = true;
    // this._userParam={id : rolelistval.operatorId};
    //this.router.navigate(['user']);
    this.operatorComp.getDetail(rolelistval.operatorId);
    this.isScreen = false;
  }

  deleteOperator(recObj) {
    if (recObj && recObj.obj) {
      const objOperator: OperatorModel = recObj.obj;
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to delete operator?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            //console.log("test delete user");
            this.operatorservice
              .deleteOperator(objOperator.operatorId)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Operator deleted successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      this.getList();
                    },
                    reason => {
                      this.getList();
                    }
                  );
                } else {
                  alert("Failed to delete operator!");
                }
              });
          }
        },
        reason => {}
      );
    }
  }
  onBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Operator detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
    }
  }
}
