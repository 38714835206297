import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import "rxjs/add/operator/map";
import { global } from "../modules/global";
import { AppSettings } from "../config/AppSettings";
import { MenuService } from "../services/menu.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoginUser } from "../models/user.model";
@Injectable()
export class AuthService {
  authToken: any;
  userName: any;
  privileges: string[];

  private currentUserSubject: BehaviorSubject<LoginUser>;
  public currentUser: Observable<LoginUser>;
  constructor(
    private http: HttpClient,
    private objGlobal: global,
    private appsetting: AppSettings,
    private menuservice: MenuService
  ) {
    const user: LoginUser = <LoginUser>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUserSubject = new BehaviorSubject<LoginUser>(user);
    this.currentUser = this.currentUserSubject.asObservable();
    if (user) {
      this.loadToken(user);
    }
  }
  public get currentUserValue(): LoginUser {
    return this.currentUserSubject.value;
  }
  authenticateUser(user) {
    sessionStorage.clear();
    let header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    return this.http
      .post<any>(
        this.appsetting.get("server-url") + "/api/token/generate-token",
        user,
        { headers: header, responseType: "json" }
      )
      .pipe(
        map(data => {
          // login successful if there's a jwt token in the response
          if (data && data.success) {
            if (data.result && data.result.token) {
              sessionStorage.setItem(
                "currentUser",
                JSON.stringify(data.result)
              );
              this.currentUserSubject.next(data.result);
              this.loadToken(data.result);
              //this.loadPrivileges();
            }
          }
          return data;
        })
      )
      .catch(err => {
        return Observable.throw(err);
      });
  }

  logoutapi() {
    return new Promise(resolve => {
      this.http
        .post(this.appsetting.get("server-url") + "/api/logout", {})
        .map(data => data)
        .toPromise()
        .then(data => {
          resolve(true);
        });
    });
  }

  /* storeUserData(token,user) {
    sessionStorage.setItem("id_token",token);
    sessionStorage.setItem("user",user);
    this.loadToken();
  }*/

  logout() {
    this.logoutapi().then(() => {
      this.objGlobal.Auth.token = null;
      this.objGlobal.Auth.token = null;
      sessionStorage.clear();
      this.menuservice.menusList = null;
      this.currentUserSubject.next(null);
    });
  }
  loggedin() {
    const jwthelper = new JwtHelperService();
    const currUser = JSON.parse(sessionStorage.getItem("currentUser"));
    let isTokenExpired: boolean = true;
    if (currUser && currUser.token) {
      isTokenExpired = jwthelper.isTokenExpired(currUser.token);
    }
    return !isTokenExpired;
  }
  loadToken(user: LoginUser) {
    this.objGlobal.Auth.token = user.token;
    this.objGlobal.Auth.user = user.name;
  }
  loadPrivileges() {
    let menuPromise: Promise<any>;
    let header = new HttpHeaders({ "Content-Type": "application/json" });
    return new Promise(resolve => {
      this.http
        .get(this.appsetting.get("server-url") + "/api/userprivileges", {
          headers: header,
          responseType: "json"
        })
        .map(data => data)
        .toPromise()
        .then((data: any) => {
          this.privileges = data.result;
          // console.log("privileges " + this.privileges);
          resolve(true);
        });
    });
  }
  getPrivileges(key: string) {
    return this.privileges[key];
  }
  getAllPrivileges() {
    return this.privileges;
  }
  isPrivExists(key: string) {
    if (this.privileges) {
      if (this.privileges.indexOf(key) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  resetpassowrd(data) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("password", data.password);
    httpParam = httpParam.append("key", data.key);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/resetPassword", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  forgotpassword(userId) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("username", userId);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/token/getpasswordlink", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
  changePassword(changepass: any) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/changepassword",
        changepass,
        { headers: header }
      )
      .map(res => res);
  }
  resetForgotPassword(resetpass: any) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/token/resetforgotpwd",
        resetpass,
        { headers: header }
      )
      .map(res => res);
  }

  resetAppUserForgotPassword(resetpass: any) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/app/resetappuserforgotpwd",
        resetpass,
        { headers: header }
      )
      .map(res => res);
  }
}
