import { OperatorModel } from "./operator.model";
export class CommunityModel {
    communityId?:number;
    communityName?:string;
    email?:string ;
    status?:string;
    mobile?:string;
    address?:string;
    gstin?:string;
    bankName?:string;
    generationTime?:Date;
    accountNumber?:string;
    category?:string;
    tariffPlan?:number;
    website?:number;
    communityType?:string;
    
    
   }
   