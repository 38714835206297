import { Component, OnInit } from '@angular/core';
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { DatePipe } from "@angular/common";
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { StationsService } from "src/app/services/Stations.Service";
import { StationModel } from "src/app/models/station.model";
import { ChargerService } from "src/app/services/charger.service";
import { OperatorModel } from "src/app/models/operator.model";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { LogsService } from "../../services/logs.service";

export class searchField {
  stationId?: number;
  operatorId?: number;
  chargerId?: number;
  ocppStatus?:string;
  ocppVersion?:String;
}

export class Logs {
  id?:string;
  cpIdentity?: string;
  eventName?: string;
  eventParameters?: string;
  event_ts?:Date;
}

@Component({
  selector: 'app-stationview',
  templateUrl: './stationview.component.html',
  styleUrls: ['./stationview.component.css']
})

export class StationviewComponent implements OnInit {
  topbartext: TopbarText[] = [
    { key: "stationview", displayText: "Station View" }
  ];
  logsText: string = "";
  logs: Array<Logs>;
  searchField: searchField;
  stationlist: Array<StationModel>;
  station: StationModel;
  operator: OperatorModel;
  chargers: Array<ManageChargerModel>;
  operatorlist: Array<OperatorModel>;
  chargerslist: Array<ManageChargerModel>;
  _menuId: string;
  data: StationModel;
  constructor(
    private ngbModalService: NgbModal,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private objLogService: LogsService,
    public chargerservice: ChargerService,
    private stationservice: StationsService,
    private operatorservice: OperatorService,
    private managechargerservice: ManageChargerService,
    ) {  
      this._menuId = this.tools.getMenuName("main/stationview");
      this.objGlobal.setActiveMenu(this._menuId);
      this.searchField = new searchField();
      this.data = new StationModel();
      this.searchField.operatorId= 0;
      this.searchField.stationId= 0;
      this.searchField.chargerId= 0;
      this.searchField.ocppStatus="Connected";
      this.searchField.ocppVersion="1.6";
      this.getStations();
     // this.getOperators();
     // this.getChargers();
     }

     
    
  ngOnInit() {
  }

  async getStations() {
    await this.stationservice
      .getAllStationsWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let stations: Array<StationModel> = new Array<StationModel>();
          let station: StationModel = new StationModel();
          station.stationId = undefined;
          station.stationname = "All";
          stations.push(station);
          this.stationlist = data["result"];
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }
  setStation(){
  
this.getDetail(this.searchField.stationId)
  }
  
  async getDetail(_id) {
    console.log(_id)
    if (_id) {
      this.stationservice.getStationDetail(_id).subscribe(data => {
        if (data["success"]) {
          this.data = data["result"];
          if (this.data && this.data.operator) {
            this.operator = this.data.operator;
            console.log(this.data.managechargers);
          } else {
            this.operator = new OperatorModel();
          }
          if (this.data.managechargers) {
            let status = this.searchField.ocppStatus;
            let ocppVersion = this.searchField.ocppVersion;
            console.log(status)
            this.chargers = this.tools.copyFullObject(this.data.managechargers);
            
            if (status!="All" && ocppVersion!="All" ){
               const chargers = this.chargers.filter(obj => obj.ocppStatus === status && obj.ocppVersion === ocppVersion );
               this.chargers = chargers;
               console.log(this.chargers);
            }

            if (status!="All" || ocppVersion!="All" ){
              const chargers = this.chargers.filter(obj => obj.ocppStatus === status || obj.ocppVersion === ocppVersion );
              this.chargers = chargers;
              console.log(this.chargers);
           }

         
            
           
          } 
         } else {
          this.data = new StationModel();
          alert("Failed to get station data!");
        }
      });
    }
    
  }
  getStationsList() {
    if (this.searchField.operatorId && this.searchField.operatorId != 0) {
      return this.stationlist.filter(
        obj => obj.operator.operatorId == this.searchField.operatorId
      );
    } else {
      return this.stationlist;
    }
  }
 
      async getChargerLogs(charger:any){
        this.objLogService.getLogsByCharger(charger.chargerName).subscribe(data => {
          if (data["success"]) {
            this.logs = data["result"];
            console.log(this.logs);
          } else {
           this.logs = undefined;
          }
         
        });
        this.getToolTipData();
      }
       getToolTipData(){
        this.logs.forEach(l => {         
          this.logsText =
            l.eventName + " - " + this.formatDate(l.event_ts) +  "\n";
        });
        console.log(this.logsText);
      }
      formatDate(val: Date) {
        let datepipe = new DatePipe("en");
        return datepipe.transform(val, "dd-MMM-yyyy h:mm:ss a");
        
      }

      showFilterCharger(event) {
        let status = this.searchField.ocppVersion ;
        console.log(status)
        if (status!="All" ){
           const chargers = this.chargers.filter(obj => obj.ocppStatus === status);
           this.chargers = chargers;
           console.log(this.chargers);
        }

       else{
          this.chargers;
       }

      }


      /* async getOperators() {
    this.operatorlist = undefined;
    await this.operatorservice
      .getAllOperatorsWithSuccessor()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          this.operatorlist = data["result"];
        } else {
          console.error("Error in fetching alll operators");
        }
      });
  }

  async getChargers() {
    this.chargerslist = undefined;
    await this.managechargerservice
      .getChargersSelectionListWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          this.chargerslist = data["result"];
        } else {
          console.error("Error in fetching all chargers");
        }
      });
  }

  getChargersList() {
    if (this.searchField.stationId && this.searchField.stationId != 0) {
      return this.chargerslist.filter(
        obj => obj.station.stationId == this.searchField.stationId
      );
    } else if (
      this.searchField.operatorId &&
      this.searchField.operatorId != 0
    ) {
      return this.chargerslist.filter(
        obj => obj.station.operator.operatorId == this.searchField.operatorId
      );
    } else {
      return this.chargerslist;
    }
  }
  
   */

 
      }
