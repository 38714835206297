import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "src/app/config/AppSettings";
import { GridPage } from "../util/components/gridtable/gridtable.component";
import { StationModel } from "../models/station.model";

@Injectable()
export class StationsService {
  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  saveStations(userrole) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/stations", userrole, {
        headers: header
      })
      .map(res => res);
  }

  getStationDetail(stationId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("stationId", stationId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/stations", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  getStationList(pageinfo: GridPage, searchField) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/stationlist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
  deleteStation(stationId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("stationId", stationId.toString());
    return this.http
      .delete(this.appsetting.get("server-url") + "/api/stations", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  inactiveStation(stationId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("stationId", stationId.toString());
    let objStation: StationModel = new StationModel();
    objStation.stationId = stationId;
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/inactivestation",
        objStation,
        { headers: header, observe: "body", responseType: "json" }
      )
      .map(res => res);
  }
  getAllStations() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/allstations", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  getAllStationsWithSuccessorOperator() {
    let header = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/allstationswithsuccessoperators",
        {
          headers: header,
          observe: "body",
          responseType: "json"
        }
      )
      .map(res => res);
  }
  alltimeperiodlist(type:string) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
   httpParam=httpParam.append("type", type.toString());
  
      return this.http.get( this.appsetting.get("server-url") + '/api/alltimeperiodlist',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }
}
