import { Component, OnInit, ViewChild, Output, Input,EventEmitter, DoCheck } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { __values } from 'tslib';
//import { RfidDetailComponent } from "../rfid-detail/community.component";
import { OperatorModel } from "src/app/models/operator.model";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { RfidService } from "../../services/rfid.service"
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { RFIDModel } from "src/app/models/rfid.model";

@Component({
  selector: 'app-rfid',
  templateUrl: './rfid.component.html',
  styleUrls: ['./rfid.component.css']
})
export class RfidComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  //@ViewChild(RfidDetailComponent) operatorComp: RfidDetailComponent;
  
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "RFID#",
      columnFieldName: "rfid",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Card#",
      columnFieldName: "cardNo",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.rfidStatus
    },
    {
      columnHeader: "Issue Date",
      columnFieldName: "issuedTime",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Valid Till",
      columnFieldName: "validTime",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Balance",
      columnFieldName: "balanceAmt",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "UserName",
      columnFieldName: "userName",
      columnClasses: "c-col-pc-15"
    }
  ];
  searchField = {
    searchText: "",
    searchRFID:"",
    status:"A"
  };
  dateNow: Date = new Date();
  data: RFIDModel;
    _data: RFIDModel;
 
  selectedFiles: FileList;
  currentFileUpload: File;
  selectedFile = null;
  rfidarrlist: Array<any>;
  progress: { percentage: number } = { percentage: 0 };
  _isSaved: boolean = false;
  datalist: Array<RFIDModel>;
  operator: OperatorModel;
  page: GridPage;
  _action: string;
  isDetailScreen: boolean = false;
  //_disableSaveCancel: boolean = true;
 // isScreen: boolean = true;
  
  _menuId: string;
  topbartext: TopbarText[] = [{ key: "rfids", displayText: "RFID Details" }];
  constructor(
    private ngbModalService: NgbModal,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private rfidservice: RfidService,
  ) { 
    this.data = new RFIDModel();
    //this.rfidarrlist = new RFIDModel();
    this.page = new GridPage();
    this._menuId = this.tools.getMenuName("main/managerfid");
    this.getList();
   // this.searchField.searchText="";
    this.data.validTime=this.dateNow;
    this.data.issuedTime=this.dateNow;
  }
  ngOnInit() {
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  async onAddNew() {
    this.data = new RFIDModel();
    this._data = this.tools.copyFullObject(this.data);
    this._action = "new";
    this.data.status = 'A'
    this.data.issuedTime = this.dateNow;
    this.data.validTime = this.dateNow;
    this.isDetailScreen = true;
  }
  async getDetail(rfid) {
    if (rfid) {
      this.rfidservice.getRfid(rfid).subscribe(data => {
        if (data["success"]) {
          this.data = data["result"];
          this.data.issuedTime=new Date(this.data.issuedTime);
          this.data.validTime=new Date(this.data.validTime);
          this._data = this.tools.copyFullObject(this.data);
        }
      }
      )}
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }
  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }

  getList() {
    this.rfidservice.getRFIDList(this.searchField).subscribe(data => {
       // console.log(this.searchField);
        if (data["success"]) {
          this.datalist = data["result"];
          //console.log(this.datalist);
         /* this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }*/
        } else {
          alert("Failed to get rfid list!");
        }
      });
  }
  onRowDblClick(rfidlistvalue:any) {
    this._action = "update";
    this.isDetailScreen = true;
    //this.isScreen = false;
    //this.router.navigate(['user']);
    this.getDetail(rfidlistvalue.rfid);
  }

  onBackClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Details has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
    }
  }
  isModified() {
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.userName != this._data.userName) {
      return true;
    } else if (this.data.rfid != this._data.rfid) {
      return true;
    } else if (this.data.balanceAmt != this._data.balanceAmt) {
      return true;
    } else if (this.data.status != this._data.status) {
      return true;
    } else if (this.data.validTime != this._data.validTime) {
      return true;
    } else if (this.data.issuedTime != this._data.issuedTime) {
      return true;
    } 
      return false;
  }

  
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  verifyUser(){
      this.rfidservice.verifyUser(this.data.userName).subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
          if (data["success"]) {

            if(data["message"]==="RFIDALREADYASSIGNED"){
              const modalRef = this.ngbModalService.open(
                NgInformationModalContent,
                ngmodaloptions
              );
              let rfid:string=data["result"];
            modalRef.componentInstance.title = "Information";
            modalRef.componentInstance.message = "User found with RFID '"+rfid+"' already assigned ";
            modalRef.componentInstance.btnCLass = "btn-info";
            modalRef.result.then(
              result => {

          });
          } else if(data["message"]==="RFIDREQUESTEDNOTAPPROVED") {
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
            modalRef.componentInstance.title = "Information";
            modalRef.componentInstance.message = "The entered User has requested for RFID";
            modalRef.componentInstance.btnCLass = "btn-warning";
           modalRef.result.then(
            result => {
  }
           ); 
          }else if(data["message"]==="RFIDREQUESTEDANDAPPROVED") {
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
            modalRef.componentInstance.title = "Information";
            modalRef.componentInstance.message = "The entered User has approved for RFID";
            modalRef.componentInstance.btnCLass = "btn-warning";
           modalRef.result.then(
            result => {
              
            }
           ); 
          }
          else if(data["message"]==="RFIDBLOCKED") {
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
            modalRef.componentInstance.title = "Information";
            modalRef.componentInstance.message = "The entered User has a blocked RFID";
            modalRef.componentInstance.btnCLass = "btn-warning";
           
            }
                
           else  if(data["message"]==="USERFOUND"){
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
                  let rfid:string="";
                modalRef.componentInstance.title = "Information";
                modalRef.componentInstance.message = "User found";
                modalRef.componentInstance.btnCLass = "btn-info";
                modalRef.result.then(
                  result => {}
                  );
          }
                }
          else {
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
            modalRef.componentInstance.title = "Information";
            modalRef.componentInstance.message = "Please enter a Valid user ";
            modalRef.componentInstance.btnCLass = "btn-info";
            modalRef.result.then(
              result => {
                
        });
          }

        });
    }
  

  saveData() {
    if(!this.data.userName){
        this.data.status = "A";
      }
    else {
        this.data.status = "I";
      }
      if(this.data.balanceAmt===null){
        this.data.balanceAmt=0.00;
      }
    this.rfidservice.saveRFID(this.data,this._action).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "RFID saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
            if (result == "ok") {
              if (result == "ok") {
                this.getList();
                this.isDetailScreen = false;
              }
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } else if( (data["message"]=="RFIDALREADYASSIGNED") && (((data["result"]!=="Requested"))||(data["result"]!=="Approved")||(data["result"]!=="Blocked"))){
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "The entered User already Mapped with RFID '"+data["result"]+"'";
        modalRef.componentInstance.btnCLass = "btn-warning";
       modalRef.result.then(
        result => {},
        reason => {}
       ); // alert("station not saved");
      }
      else if( (data["message"]=="RFIDREQUESTED")){
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "The entered User already Requested a RFID please approve the request first";
        modalRef.componentInstance.btnCLass = "btn-warning";
       modalRef.result.then(
        result => {},
        reason => {}
       ); // alert("station not saved");
      }
      else if( (data["message"]=="RFIDBLOCKED")){
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "The entered User blocked the RFID please ask to request a new one";
        modalRef.componentInstance.btnCLass = "btn-warning";
       modalRef.result.then(
        result => {},
        reason => {}
       ); // alert("station not saved");
      }
      else if (data["message"]=="USERNOTFOUND"){
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "The entered User not found";
        modalRef.componentInstance.btnCLass = "btn-warning";
       modalRef.result.then(
        result => {},
        reason => {}
       ); // alert("station not saved");
      }
      else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save RFID!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("station not saved");
      }
    });
  }

  
  uploadListener($event: any): void {  

    let files = $event.srcElement.files;  
  
    if (this.isValidCSVFile(files[0])) {  
      
      let input = $event.target;  
      this.selectedFile=files[0].name;
      console.log(this.selectedFile);
      let reader = new FileReader();  
      reader.readAsText(input.files[0]);  
  
      reader.onload = () => {  
        let csvData = reader.result; 
        //console.log(csvData);
        let csvRecordsArray = (<string>csvData).split(/\r?\n|\r/);
        csvRecordsArray.pop();

        //let headersRow = this.getHeaderArray(csvRecordsArray);  
       this.rfidarrlist =  this.getDataRecordsArrayFromCSVFile(csvRecordsArray);  
      };  
  
      reader.onerror = function () {  
        console.log('error is occured while reading file!');  
      };  
  
    } else {  
      alert("Please import valid .csv file.");  
      //this.fileReset();  
    }  
  } 
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {  
  var csvArr = new Array();
    //console.log(csvRecordsArray);
    for (let i=0; i<csvRecordsArray.length; i++) {  
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');  
       let csvRecord = new RFIDModel();  
       csvRecord.rfid = curruntRecord[0]; 
       csvRecord.cardNo = curruntRecord[1];  
       csvRecord.status = "A";
          
       csvRecord.validTime = this.dateNow;  
       csvRecord.issuedTime = this.dateNow;  
       // console.log(csvRecord);   
       csvArr.push(csvRecord);   
    } 
    return csvArr
  }  
  
  isValidCSVFile(file: any) {  
    return file.name.endsWith(".csv");  
  }  

  uploadBulk(){
    this._action = "bulk";
    let ngmodaloptions: NgbModalOptions = {};
    for(let i=0;i<this.rfidarrlist.length;i++){
      this.rfidservice.saveRFID(this.rfidarrlist[i],this._action).subscribe(data => {
        if (data && data["success"]) {
        this.rfidarrlist[i].Success = "Saved- "+data["message"];
        }
        else{
          this.rfidarrlist[i].Success = "Failed - "+data["message"];
        }
      });
      
    }
    console.log(this.rfidarrlist);
    const modalRef = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRef.componentInstance.title = "Information";
    modalRef.componentInstance.message = "RFID bulk data processed successfully!";
    modalRef.componentInstance.btnCLass = "btn-info";
    modalRef.result.then(
      result => {
        if (result == "ok") {
          this.download();
          location.reload(); 
        }
      });
  }

  download(){
    console.log(this.selectedFiles);
    var csvData = this.ConvertToCSV( this.rfidarrlist);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    var x:Date = new Date();
    var filename = this.selectedFile.split('.');
    var link:string =filename[0]+"_Processed_"+ this.tools.formatDateToDDMMYY(x) +'.csv';
    a.download = link.toLocaleLowerCase();
    a.click();

  }
  refreshList(){
   // console.log("Hello World");
    this.getList();
  }

// convert Json to CSV data in Angular2
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
        //Now convert each value to string and comma-separated
        row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }
 
}
