import { OperatorModel } from "./operator.model";
export class AppUserModel {
    username?:string;
    password?:String;
    firstName?:string;
    lastName?:string;
    email?:string ;
    status?:string;
    userType?:string;
    operator?:OperatorModel;
    operatorId?: number;
    corpId?: number;
    address?:string;
    userGroup?:string;
    blockedrfid?:string;
    rfid?:string;
    billing?:string;
    preferredPvtStation?:string;
    preferredStations?:string;
    balanceamount?:number;
    multiBooking?:string;
    tariffPlan?:number;
    balanceUpdates?:string;
    updatedBy?:string;
    updatedTS?:Date;
   }
   