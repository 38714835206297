import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { AppSettings } from "../config/AppSettings";
import { Tools } from '../modules/tools';
import {GridPage} from '../util/components/gridtable/gridtable.component';
import { GSTLocationModel } from '../models/gstlocation.model';
import { GroupAddingModel } from '../models/groupadding.model';
@Injectable({
  providedIn: 'root'
})
export class GSTLocationService {

  constructor(private http: HttpClient, private appsetting: AppSettings,private tools: Tools) {}

  saveGSTLocation(gstlocation:GSTLocationModel,requestType:String) {
    if(requestType==="new"){
      let header = new HttpHeaders();
    console.log(gstlocation);
    return this.http
      .put(this.appsetting.get("server-url") + "/api/newgstlocation",gstlocation, {
        headers: header
      })
      .map(res => res);

    }
    else{
      let header = new HttpHeaders();
      console.log(gstlocation);
      return this.http
        .put(this.appsetting.get("server-url") + "/api/gstlocation",gstlocation, {
          headers: header
        })
        .map(res => res);

    }
   }
  getGSTLocation(locationId: string) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("locationId", locationId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/gstlocation", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }

getGSTLocationList(pageinfo: GridPage,searchField) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
   httpParam=httpParam.append("page", pageinfo.number.toString());
    httpParam=httpParam.append("size",pageinfo.size.toString());
   //httpParam=httpParam.append("status",searchField.status);
  // httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
  //  httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));    
      httpParam = httpParam.append("searchText", searchField.searchText);
      return this.http.get( this.appsetting.get("server-url") + '/api/gstlocationlist',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }

  getAllGSTLocations() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/allgstlocations", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
  saveGroupAdding(groupadding:GroupAddingModel,requestType:String) {
    if(requestType==="new"){
      let header = new HttpHeaders();
    console.log(groupadding);
    return this.http
      .put(this.appsetting.get("server-url") + "/api/addgroupadding",groupadding, {
        headers: header
      })
      .map(res => res);

    }
  }
  getGroupAddinglist(pageinfo: GridPage,searchField) {
    //  alert("hii");

      let header = new HttpHeaders();
      let httpParam: HttpParams = new HttpParams();
      httpParam = httpParam.append("page", pageinfo.number.toString());
      httpParam = httpParam.append("size", pageinfo.size.toString());
      httpParam = httpParam.append("searchText", searchField.searchText);
      httpParam = httpParam.append("searchStatus", searchField.searchStatus);
     // alert("hii");
     
      return this.http
        .get(this.appsetting.get("server-url") + "/api/groupaddinglist", {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json"
        })
        .map(res => res);
    }
    getGroupAdding(id: string) {
      let header = new HttpHeaders();
      let param: HttpParams = new HttpParams();
      param = param.append("groupadding", id.toString());
      return this.http
        .get(this.appsetting.get("server-url") + "/api/groupaddingdetail", {
          headers: header,
          observe: "body",
          params: param,
          responseType: "json"
        })
        .map(res => res);
    }
    getAllGroupAdding() {
      let header = new HttpHeaders();
      let httpParam : HttpParams=new HttpParams();
     
        return this.http.get( this.appsetting.get("server-url") + '/api/allgroupadding',{headers : header, observe : "body", params : httpParam,responseType : "json"})
      .map(res=>res);
    }
    inactiveGroupAdding(id: number) {
      let header = new HttpHeaders();
      let param: HttpParams = new HttpParams();
      param = param.append("id", id.toString());
      return this.http
        .delete(this.appsetting.get("server-url") + "/api/inactiveGroupAdding", {
          headers: header,
          observe: "body",
          params: param,
          responseType: "json"
        })
        .map(res => res);
    }
}