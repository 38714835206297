export class EmspModel{
    businessName?:string;
    countryCode?:string;
    partyId?:String;
    id?:number;
    currency?:String;
    version?:string;
    cpoEndpoint?:string;
    emspEndpoint?:string;
    authkey1?:string;
    authkey2?:string;
    authkey3?:string;
}