import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
export class TopbarText {
  key: string;
  displayText: string;
  class?: string;
}
@Component({
  selector: 'app-top-text',
  templateUrl: './top-text.component.html',
  styleUrls: ['./top-text.component.css']
})
export class TopTextComponent implements OnInit {

  @Input()
  topbartext: TopbarText[];
  @Output()
  tabClick: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  chantVal = { selectedTabIndex: 0 };
  @Input()
  isChildTopbar: boolean = false;

  constructor(
    public objGlobal: global,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit() {}
  onLogoutClick() {
    this.auth.logout();
    //this.flashmessage.show("You are logged out successfully",{cssClass : "alert-danger",  timeout : 5000});
    this.router.navigate(["login"]);
    return false;
  }
  isSelectedTab(index: number) {
    return this.chantVal.selectedTabIndex == index;
  }
  onTabClick(tbtext: TopbarText, i: number) {
    if (this.topbartext.length > 1) {
      this.chantVal.selectedTabIndex = i;
      this.tabClick.emit(tbtext.key);
    }
  }
  getTopTextClass(i: number) {
    if (this.topbartext[i].class) {
      return this.topbartext[i].class;
    } else {
      return "col-md-10";
    }
  }
  onProfileClick() {
    this.setDefaultMenuActive();
    this.objGlobal.currentMenuPath = "profile";
    this.router.navigate(["main/profile"]);
  }
  setDefaultMenuActive() {
    this.objGlobal.activeMainMenu = "profile";
    this.objGlobal.activeMenu = "profile";
  }

  onChangePassword() {
    this.router.navigate(["main/changepassword"]);
  }
}