import { Component, OnInit } from '@angular/core';
import { ManageChargerModel } from 'src/app/models/ManageCharger.model';
import { TopbarText } from "../../components/topbar/topbar.component";
import { Tools } from "src/app/modules/tools";

import { AuthService } from "src/app/services/auth.service";
import { global } from "src/app/modules/global";
import {NavigationExtras, Router,ActivatedRoute} from '@angular/router';
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "src/app/util/components/gridtable/gridtable.component";

import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/util/components/modalcomponent/modal-content";

import { StationModel } from "src/app/models/station.model";
import { ChargerService } from "src/app/services/charger.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";

import { ChargerModel } from "src/app/models/Charger.model";
import { StationsService } from "src/app/services/Stations.Service";
import { config } from 'process';
import { LogComponent } from "../log/log.component";
@Component({
  selector: 'app-cn',
  templateUrl: './cn.component.html',
  styleUrls: ['./cn.component.css']
})
export class CnComponent implements OnInit {
  topbartext: TopbarText[] = [
    { key: "cn", displayText: "Charger_Start_Stop" }
  ];
  data: ManageChargerModel;
  _data: ManageChargerModel;
  port:number[]=[];
  chargerport:[];
  constructor(  private tools: Tools,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private objGlobal: global,
    private auth: AuthService,
    private manageChargerService: ManageChargerService,
    private ngbModalService: NgbModal,
    public chargerservice: ChargerService) {
      this.data = new ManageChargerModel();
     // route.snapshot.params.id
      console.log(this.activatedRoute.snapshot.queryParams.id);
      let charger=this.activatedRoute.snapshot.queryParams.id;
    this.getDetail(charger);
   
    
     }

  ngOnInit() {
  }
  getDetail(charger) {
    console.log(charger);
    if (charger) {
      this.manageChargerService.getChargerDetailCn(charger).subscribe(data => {
        if (data["success"]) {
         
          this.data = data["result"];
    
          
          }
       
          var port=[];
          if(this.data.number_of_chargingpoints){
            let conlength=this.data.number_of_chargingpoints
            console.log(conlength)
           for(var i=1;i<=conlength;i++){
         port.push(i)
              
           }
           port.map((el)=>{
             console.log(el)
            var ob= Object.values(el)
           this.port.push(el);
           
            
         
           })}
           if(this.port!=null){
             this.chargerport=port[0];
           //  console.log(port[0]);
           }
          this._data = this.tools.copyFullObject(this.data);
        });
      
    }
  }
  startcommand() {
    let command="Start Charging";
    
    this.manageChargerService.exeCommand(this.data.chargerName,this.chargerport,command).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
      alert('Charging Started');
        
      }
    });
         
  }
  stopcommand() {
    let command="Stop Charging";
    
    this.manageChargerService.exeCommand(this.data.chargerName,this.chargerport,command).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        alert('Charging Stopped');
        
      }
    });
         
  }
}
