
import { AuthService } from "src/app/services/auth.service";
import { BookingStatusModel } from "../../models/booking.status.model";
import { BookingModel } from "../../models/booking.model";
import { ChargerService } from "src/app/services/charger.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { LogsService } from "../../services/logs.service";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/util/components/modalcomponent/modal-content";
import { ChargerActionService } from "src/app/services/charger-action.service";
import { Tools } from "../../modules/tools";
import { Router,ActivatedRoute } from "@angular/router";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { TransactionModel } from "src/app/models/transaction.model";
import { PortModel } from "src/app/models/chargerPort.model";
import { ChartConfiguration, ChartYAxe } from "chart.js";

/*export class ChargerAvailableConnection{
  id?:number;
  wsClientId?:string;
  switchts?:string;
  state?:string;
}*/
export class ChargerNetworkConnection{
  id?:number;
  state?:string;
  switchts?:string;
  wsClientId:string;

}
export class searchField {
  searchField?: number;
  chargerId?: string;
  tranId?: number;
  startdate?: Date;
  enddate?: Date;
  status?:string;
  username?:string;
}
export class ProgressDataModel {
  id?: number;
  deviceId?: string;
  archive?:number;
  connectorId?: number;
  statusts?: Date;
  status?:string;
  statusext?:string;
  tranid?:string;
}

export class ProgressValueModel {
  value?: string; 
  date?:string;
  status?:string;
}
export class stopTransaction {
  fromStatus : string;
  chargingpoint : string;
  deviceId : string;
  requestStatus :string;
  bookingid : number;
  forceStop:string;
}
export class EveryChargingModel {
  tranId?: number;
  cp_identity?: string;
  connector_id?: number;
  meter_start?: number;
  meter_end?: number;
  start_time?: string;
  stop_time?: string;
  reservationId?: string;
  activitylog?:string;
  amp?:number;
  load?:string;
  voltage?:number;
  current?:number;
  bookingId?:number;
  soc?:string;
  stoppedReason?:string;
  vin?:string;
  last_update?: string;
  meter_current?: number;
  /*
   * S- Booking accepted
   * R -  Charging Running
   * C - Charging Complete
   */
 
  status?: string;
  unitConsumed?:number;
}

@Component({
  selector: "app-chargeraction",
  templateUrl: "./chargeraction.component.html",
  styleUrls: ["./chargeraction.component.css"],
})
export class ChargeractionComponent implements OnInit, OnDestroy {
  
  topbartext: TopbarText[] = [
    { key: "chargingstatus", displayText: "Charging Sessions" }
  ];
  detailChildTabs = ["Energy", "Graph", "Others"];
  detailChildActiveTab = "Energy";
  selectedGraph = "kW";
  pwConsumptionChart = {
    chartType: "line",
    legend: {
      display: true,
      labels:{
          fontSize: 'small',
          fontColor: 'red',
      }
    },
    chartColor: [{ color: ["rgb(93, 137, 168)"] }],
    options: {
      scales: {
        yAxes: [{ scaleLabel: { display: true, labelString: "kWh" } }],
        xAxes: [{ display : true, ticks : {fontColor : "red", fontSize : "10"}}]
      },
    },
    chartDataSet: [{ data: [], label: "Power Consumption" }],
    xLabels: []
  }
  pwSOCChart= {
    chartType: "line",
    legend: {
      display: true,
      labels:{
          fontSize: 'small',
          fontColor: 'red',
      }
    },
    chartColor: [{ color: ["rgb(93, 137, 168)"] }],
    options: {
      scales: {
        yAxes: [{ scaleLabel: { display: true, labelString: "SOC" } }],
        xAxes: [{ display : true, ticks : {fontColor : "red", fontSize : "10"}}]
      },
    },
    chartDataSet: [{ data: [], label: "State Of Charging" }],
    xLabels: []
  }

  pwAmpChart= {
    chartType: "line",
    legend: {
      display: true,
      labels:{
          fontSize: 'small',
          fontColor: 'red',
      }
    },
    chartColor: [{ color: ["rgb(93, 137, 168)"] }],
    options: {
      scales: {
        yAxes: [{ scaleLabel: { display: true, labelString: "Amp" } }],
        xAxes: [{ display : true, ticks : {fontColor : "red", fontSize : "10"}}]
      },
    },
    chartDataSet: [{ data: [], label: "Current" }],
    xLabels: []
  }
  pwVoltageChart= {
    chartType: "line",
    legend: {
      display: true,
      labels:{
          fontSize: 'small',
          fontColor: 'red',
      }
    },
    chartColor: [{ color: ["rgb(93, 137, 168)"] }],
    options: {
      scales: {
        yAxes: [{ scaleLabel: { display: true, labelString: "Voltage" } }],
        xAxes: [{ display : true, ticks : {fontColor : "red", fontSize : "10"}}]
      },
    },
    chartDataSet: [{ data: [], label: "Voltage" }],
    xLabels: []
  }

  gridcols: gridColumns[] = [
    {
      columnHeader: "Sr",
      isSrCol: true,
      columnClasses: "c-col-pc-3"
    },
    {
      columnHeader: "ChargingId",
      columnFieldName: "tranId",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Station",
      columnFieldName: "station",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Charger",
      columnFieldName: "cp_identity",
      columnClasses: "c-col-pc-18"
    },
    {
      columnHeader: "Connector",
      columnFieldName: "connector_id",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "SOC",
      columnFieldName: "soc",
      columnClasses: "c-col-pc-5"
    },
    {
      columnHeader: "Units",
      columnFieldName: "unitConsumed",
      columnClasses: "c-col-pc-5",
    },
    {
      columnHeader: "StartTime",
      columnFieldName: "start_time",
      columnClasses: "c-col-pc-17",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "StopTime",
      columnFieldName: "stop_time",
      columnClasses: "c-col-pc-17",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    
  ];
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  bookings: Array<BookingModel>;
  //chargerAvailableConnection:ChargerAvailableConnection;
  chargerNetworkConnection: ChargerNetworkConnection;
  isStateCharging: boolean=false;
  isthunderconnect :boolean =false;
  iswificonnect : boolean =false;
  datalist: Array<BookingStatusModel>;
  chargerslist: Array<ManageChargerModel>;
  chargerdata: Array<ManageChargerModel>;
  connectors:Array<PortModel>;
  _menuId: string;
  page: GridPage;
  _data: TransactionModel;
  paramStatus: boolean = false;
  data: EveryChargingModel;
  progressData: Array<ProgressDataModel>;
  progressValues: Array<ProgressValueModel>;
  tranId: number;
  _action: string;
  isInitiate: boolean = false;
  isDetailScreen: boolean = false;
  qrModal: boolean;
  dateNow: Date = new Date();
  searchField: searchField;
  stopTransaction: stopTransaction;
  formGroup: FormGroup;
  constructor(
    private objNetworkService: LogsService,
    private chargerActionService: ChargerActionService,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public chargerservice: ChargerService,
    private managechargerservice: ManageChargerService,
    private ngbModalService: NgbModal,
    private _formBuilder: FormBuilder
  ) {
   
    
    this.stopTransaction = new stopTransaction();
    this.searchField = new searchField();
    console.log(this.paramStatus);
    if (!this.paramStatus) {
      this.searchField.tranId = undefined;
    }
    this._data = new TransactionModel();
    this.data = new EveryChargingModel();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/chargeraction");
    this.objGlobal.setActiveMenu(this._menuId);
    if (this.activatedRoute.snapshot.paramMap.get('chargerId') != null) {
      this.searchField.chargerId = this.activatedRoute.snapshot.paramMap.get('chargerId');
    } else {
      this.searchField.chargerId = "All";
    }

    this.searchField.status = "R";
    this.searchField.startdate = this.dateNow;
    this.searchField.enddate = this.dateNow;
    this.stopTransaction.fromStatus = "Available";
    this.stopTransaction.requestStatus = "StopTransaction";
    this.getChargingData();
    this.getChargers();
  }
  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    //this.dataSource.data = this.getGroups(this.alldata, this.groupByColumns);
    //this.getChargersLiveStatusData();
    console.log(this.route.queryParams);
    this.route.queryParams.filter(params => params.TranId)
      .subscribe(params => {
        if (params.TranId) {
          this.paramStatus = true;
          this.searchField.tranId = params.TranId;
          this.isDetailScreen = true;
          console.log(this.searchField.tranId);
          console.log(this.paramStatus);
          this.getTransactionDetail(this.searchField.tranId);
        }
      }
      );
  }
  ngOnDestroy() {
    //this.subscriptionStatusRefresh.unsubscribe();
  }
  gridDeleteButton() {
    // console.log("abc");
    let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
    //console.log(isdelete)
    // let isdelete : boolean = true;
    return isdelete;
  }
  async getChargers() {
    this.chargerslist = undefined;
    await this.managechargerservice
      .getChargersSelectionListWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          this.chargerslist = data["result"];
        } else {
          console.error("Error in fetching all chargers");
        }
      });
  }
   
  getChargersList() {
    return this.chargerslist;
       
  }
  setCharger(){
    console.log(this.chargerslist);
    /*this.chargerdata = this.chargerslist.filter( obj => obj.chargerId == Number(this.searchField.chargerId));
    console.log(this.chargerdata);
    this.connectors = this.tools.copyFullObject(this.chargerdata[0].chargerPort);*/
  }

  toBookingDetails() {
    let bookingid = this._data.bookingId;
    this.router.navigate(['main/activebooking'], { queryParams: { BookingId: bookingid } });
  }
  getChargingData() {
    this.chargerActionService.getChargingDetails(this.page, this.searchField).subscribe(data => {
      // if(data["result"].content.booking.status!=="P"){
      if (data["success"]) {
        // console.log(data["result"].content);
        this.datalist = data["result"].content;
        this.page.first = data["result"].first;
        this.page.last = data["result"].last;
        this.page.number = data["result"].number;
        this.page.numberOfElements = data["result"].numberOfElements;
        this.page.offset = data["result"].offset;
        this.page.size = data["result"].size;
        this.page.totalElements = data["result"].totalElements;
        this.page.totalPages = data["result"].totalPages;
        if (this.page.number == 0) {
          this.gridComp.setPages();
        }
      } else {
        alert("Failed to get Charger Details!");
      }
    });
  }
  
  closeCharging(recObj) {
    let objCharging: TransactionModel = {};
    if (!this.isDetailScreen) {
      objCharging = recObj.obj;
    }
    else {
      objCharging = recObj;
    }
    if (objCharging && objCharging.status == "R") {
      this.stopTransaction.bookingid = objCharging.bookingId;
      this.stopTransaction.chargingpoint = objCharging.connector_id.toString();
      this.stopTransaction.deviceId = objCharging.cp_identity;
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
     
      ngmodalref.componentInstance.message = "Are you sure to stop Charging?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.chargerActionService
              .closeCharging(this.stopTransaction)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  
                   
                      modalRefOk.componentInstance.message =
                        "Charging Stop request submited successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                     
                      if (this.isDetailScreen) {
                        this.getTransactionDetail(this._data.tranId)
                      } else {
                        this.getChargingData();
                      }
                      
                    },
                    reason => {
                      if (this.isDetailScreen) {
                        this.getTransactionDetail(this._data.tranId)
                      } else {
                        this.getChargingData();
                      }
                    }
                  );
                } if(this.stopTransaction.forceStop==="Y"){
                  const modalRefOk = this.ngbModalService.open(
                NgInformationModalContent,
                ngmodaloptions
              );
              modalRefOk.componentInstance.title = "Information";
              
              modalRefOk.componentInstance.message =
                "Stop forcely request submited successfully!";
                

              modalRefOk.componentInstance.btnCLass = "btn-info";
             }
                else {
                 
                  alert("Failed to stop charging!");
                  
               
                }
              });
          }
        });
    } else if (objCharging && objCharging.status == "I") {
      this.tranId = objCharging.tranId;
      this.stopTransaction.chargingpoint = objCharging.connector_id.toString();
      this.stopTransaction.deviceId = objCharging.cp_identity;
      //const objBooking: BookingModel = recObj.obj;
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to stop Charging?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.chargerActionService
              .changeChargingStatus(this.tranId)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Charging stop request submited successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      if (this.isDetailScreen) {
                        this.getTransactionDetail(this._data.tranId)
                      } else {
                        this.getChargingData();
                      }
                    },
                    reason => {
                      if (this.isDetailScreen) {
                        this.getTransactionDetail(this._data.tranId)
                      } else {
                        this.getChargingData();
                      }
                    }
                  );
                }
                else {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Failed to cancel charging!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      if (this.isDetailScreen) {
                        this.getTransactionDetail(this._data.tranId)
                      } else {
                        this.getChargingData();
                      }
                    },
                    reason => {
                      if (this.isDetailScreen) {
                        this.getTransactionDetail(this._data.tranId)
                      } else {
                        this.getChargingData();
                      }
                    });
               
                }
              });
          }
        });
    }

    else {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      
      const modalRefOk = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRefOk.componentInstance.title = "Information";
      modalRefOk.componentInstance.message =
        "Charging cannot be stopped in current status";
      modalRefOk.componentInstance.btnCLass = "btn-info";
      modalRefOk.result.then(
        result => {
          this.getChargingData();
        },
        reason => {
          this.getChargingData();
        });
   
    }
  }
  onBackbuttonClick() {
    this.isDetailScreen = false;
    this.getChargingData();
  }
  onCancelButtonClick() {
    this.closeCharging(this._data);
    this.getTransactionDetail(this._data.tranId)
    //this.isDetailScreen = false;
  }
  onStopForcelyClick(){
    this.closeCharging(this._data);
    this.stopTransaction.forceStop="Y"
  }
  onRowDblClick(transaction: any) {
    this._action = "update";
    this.isDetailScreen = true;
    console.log(transaction);
    //this.router.navigate(['user']);
    this.getTransactionDetail(transaction.tranId);
    this.getProgressData(transaction.tranId);
  }
  
  isStateInitiate() {
    if (this._data.status === "I" || this._data.status === "R") {
      return true;
    } else {
      return false
    }

  }

  getTransactionDetail(tranId: number) {
    console.log(tranId)
    if (tranId) {
      this.chargerActionService.getChargingDetail(tranId).subscribe(data => {
        if (data["success"]) {
          this._data = data["result"];
       
          this.data.current = this._data.current;
          this.data.voltage = this._data.voltage;
          this.data.activitylog = this._data.activitylog;
          this.data.bookingId = this._data.bookingId;
          this.data.connector_id = this._data.connector_id;
          this.data.cp_identity = this._data.cp_identity + "-" + this._data.connector_id;
          this.data.reservationId = this._data  .reservationId;
          if(this._data.soc){
          this.data.soc = this._data.soc + "%";
          }
          this.data.tranId = this._data.tranId;
          this.data.vin = this._data.vin;
          this.data.status = this.tools.chargingStatus(this._data.status);
          this.data.start_time = this.tools.defaultdateTimeFormat(this._data.start_time);
          this.data.stop_time = this.tools.defaultdateTimeFormat(this._data.stop_time);
          this.data.last_update = this.tools.defaultdateTimeFormat(this._data.last_update);
          this.data.stoppedReason = this._data.stoppedReason;
          this.data.amp = this.formatNumber3(this._data.amp);
          if(this._data.amp){
          this.data.load = this.data.amp.toString() + " kWh";
          }
           if(this._data.status==="R"){
            this.isStateCharging=true
           }
          this.data.unitConsumed = this.formatNumber3(this._data.unitConsumed);
          console.log(this.data.load);
          //this.data.meter_end = this.formatNumber3(Number(this._data.meter_end));
          //this.data.meter_start = this.formatNumber3(Number(this._data.meter_start));
          this.data.meter_end = this._data.meter_end;
          this.data.meter_start = this._data.meter_start;
          this.data.meter_current = this._data.meter_current;
          this.setChargerTrends();
          this.getNetworkLogsByCharger();
         // this.getOcppStatus();
        } else {
          this.data = new EveryChargingModel();
          alert("Failed to get charging data!");
        }
      });
    }
  }
  formatNumber2(val) {
    let value = 0.00
    if (val) {
      value = val.toFixed(2);
    }
    return value
  }

  formatNumber3(val) {
    let value = 0.000
    if (val) {
      value = val.toFixed(3);
    }
    return value
  }

  getProgressData(tranId: number) {
    this.progressValues = undefined;
    if (tranId) {
     
      this.chargerActionService.getProgressDetail(tranId).subscribe(data => {
        if (data["success"]) {
          const progressDataa: Array<ProgressDataModel> = data["results"];
          let progessdataArray: Array<ProgressValueModel> = new Array<ProgressValueModel>();
      
          for (let i: number = 0; i < progressDataa.length; i++) {
            let progressValue: ProgressValueModel = new ProgressValueModel();
            progressValue.value = this.tools.chargingStatus(progressDataa[i].status) + " " + this.tools.defaultdateTimeFormat(progressDataa[i].statusts);
            progressValue.date = this.tools.defaultdateTimeFormat(progressDataa[i].statusts);
            progressValue.status = this.tools.chargingStatus(progressDataa[i].status);
            console.log(progressValue);
            progessdataArray.push(progressValue);
            console.log(progessdataArray);
          }
          console.log(progessdataArray);
          this.progressValues = progessdataArray;
        }
      
      });
    }
  }

  onProgressClick() {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "lg";
    
    const modalRefOk = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRefOk.componentInstance.title = "Progress";
    
    modalRefOk.componentInstance.btnCLass = "btn-info";
    modalRefOk.result.then(
      result => {
        this.getChargingData();
      },
      reason => {
        this.getChargingData();
      });
 
  }
  showQRCodes() {
    this.qrModal = true;
    this.generateQR();
  }

  hideQRCodes() {
    this.qrModal = false;
  }
  generateQR() {
    // let qrSRC:string;
  }

  setActiveChildTab(tab: string) {
    if (this.detailChildActiveTab != tab) {
      this.detailChildActiveTab = tab;
    }
  }
  
  setChargerTrends() {
    if (this.data.tranId) {
      this.chargerActionService.getChargerTrends(this.data.tranId)
        .subscribe(res => {
          if (res && res["success"]) {
            this.setTrendData(res["result"]);
          } else {
            this.messageBox("Warning", res["message"],"btn-info")
            .then(result => {

            });
          }
        }, err => {
          console.error(err);
          this.messageBox("Error", "Failed to get charger trend data!!", "btn-info")
            .then(result => {

            });
        })
    }
  }
  setTrendData(trenddata) {
    
    let trendGraphCategory = [];
    let graphKwData = [];
    let graphSOCData = [];
    let graphAmpData = [];
    let graphVoltageData = [];
    if (trenddata && trenddata.length > 0) {
      trenddata.sort((a, b) => { a.gents > b.gents });
      trenddata.forEach(element => {
        if (element.gents) {
          trendGraphCategory.push(this.tools.defaultdateTimeFormat(element.gents));
          graphKwData.push(element.kW);
          graphSOCData.push(element.soc);
          graphAmpData.push(element.amp);
          graphVoltageData.push(element.voltage);
        }
      });
      this.pwConsumptionChart.xLabels = trendGraphCategory;
      this.pwConsumptionChart.chartDataSet[0].data = graphKwData;
      this.pwSOCChart.xLabels = trendGraphCategory;
      this.pwSOCChart.chartDataSet[0].data = graphSOCData;
      this.pwSOCChart.xLabels = trendGraphCategory;
      this.pwAmpChart.chartDataSet[0].data = graphAmpData;
      this.pwVoltageChart.xLabels = trendGraphCategory;
      this.pwVoltageChart.chartDataSet[0].data = graphVoltageData;
      //this.setGraphType();
    }
  }
  /*onGraphTypeSelect(e: any, chart) {
    this.selectedGraph = e.target.value;
    this.setGraphType();
  }
  setGraphType() {
    if (this.selectedGraph == "kW") {
      this.pwConsumptionChart.chartDataSet[0].data = this.graphKwData;
      this.pwConsumptionChart.options.scales.yAxes[0].scaleLabel.labelString = "kWh";
    } else {
      this.pwConsumptionChart.chartDataSet[0].data = this.graphSOCData;
      this.pwConsumptionChart.options.scales.yAxes[0].scaleLabel.labelString = "kWh";
    }
  }*/
  messageBox(title: string, message: string, btnClass: string) {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const modalRefOk = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRefOk.componentInstance.title = title;
    modalRefOk.componentInstance.message = message;
    modalRefOk.componentInstance.btnCLass = btnClass;
    return modalRefOk.result;
  }
  getNetworkLogsByCharger(){
  this.objNetworkService.getNetworkLogsByCharger(this._data.cp_identity).subscribe(data => {
    
    

    if (data["success"]) {
      const connectiondata:any = (data["result"])
      this.chargerNetworkConnection = connectiondata;
      console.log(connectiondata)
      if(connectiondata.state==="Connected"){
        this.iswificonnect=true
      }
      
      
      
    }
    
    
    
    
  });
}
/*getOcppStatus(){
  this.objNetworkService.getOcppStatus(this._data.status,1).subscribe(data => {
    if (data["success"]) {
      const connectiondata = (data["result"])
      this.chargerAvailableConnection = connectiondata;
      if(this.chargerAvailableConnection.state ==="disConnected"){
        this.isthunderconnect=true;
      }
      this.isthunderconnect=true;
    }
  });
}   */ 
}