import { Component, OnInit } from '@angular/core';
import { TopbarText } from "../topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import {FTransactionsService } from "src/app/services/ftransactions.service";
import { FTransactionsModel } from "../../models/ftransactions.model";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
@Component({
  selector: 'app-ocpiapis',
  templateUrl: './ocpiapis.component.html',
  styleUrls: ['./ocpiapis.component.css']
})
export class OcpiapisComponent implements OnInit {
  datalist: Array<FTransactionsModel>;
  paymentData:Array<FTransactionsModel>;
  isDetailScreen: boolean = false;
  _data:FTransactionsModel;
  _action: string;
  //data: EveryTransactionModel;
  page: GridPage;
  _menuId: string;
  dateNow: Date = new Date();
  topbartext: TopbarText[] = [{ key: "API", displayText: "API" }];
  //searchField: searchField;
  constructor(
    public ftransactionService: FTransactionsService,
    public tools: Tools,
    public objGlobal: global,
    public auth: AuthService,
  ) {
    this._data = new FTransactionsModel();
    //this.data = new EveryTransactionModel();
   /* this.searchField = new searchField();
   this.searchField.status="I";
    this.searchField.startdate=this.dateNow;
    this.searchField.enddate=this.dateNow;*/
    this.page = new GridPage();
    this._menuId = this.tools.getMenuName("main/ocpiapis");
    this.objGlobal.setActiveMenu(this._menuId);
    
    this.page.size = 10; //Default page size
    //this.getTransactionsData() ;
   }
  ngOnInit() {
  }

}
