import { Component, OnInit, ViewChild, Output, Input,EventEmitter, DoCheck } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { __values } from 'tslib';
//import { RfidDetailComponent } from "../rfid-detail/community.component";
import { OperatorModel } from "src/app/models/operator.model";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { GSTLocationService } from "../../services/gstlocation.service"
import {GridPage,gridColumns,GridtableComponent} from "../../util/components/gridtable/gridtable.component";
import { GSTLocationModel } from "src/app/models/gstlocation.model";
@Component({
  selector: 'app-gstlocation',
  templateUrl: './gstlocation.component.html',
  styleUrls: ['./gstlocation.component.css']
})
export class GSTLocationComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  //@ViewChild(RfidDetailComponent) operatorComp: RfidDetailComponent;
  
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "LocationID",
      columnFieldName: "locationId",
      columnClasses: "c-col-pc-10"
    }, {
      columnHeader: "GSTN#",
      columnFieldName: "gstNumber",
      columnClasses: "c-col-pc-10"
    },{
      columnHeader: "Location",
      columnFieldName: "locationName",
      columnClasses: "c-col-pc-10"
    },  
    {
      columnHeader: "GenDate",
      columnFieldName: "genTS",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
  ];
  searchField = {
    code:"",
    searchText: "",
    searchDiscount:"",
    type:"F",
    status:"V"
  };
  page: GridPage;
  dateNow: Date = new Date();
  data: GSTLocationModel;
  _data: GSTLocationModel;
  _isSaved: boolean = false;
  datalist: Array<GSTLocationModel>;
  operator: OperatorModel;
  _action: string;
  isDetailScreen: boolean = false;
  //_disableSaveCancel: boolean = true;
 // isScreen: boolean = true;
  
  _menuId: string;
  topbartext: TopbarText[] = [{ key: "gstlocation", displayText: "GSTN Location" }];
 
  constructor(
    private ngbModalService: NgbModal,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private gstlocationtservice: GSTLocationService) {  
      this.data = new GSTLocationModel();
      this.page = new GridPage();
      this.page.size = 10; 
      this._menuId = this.tools.getMenuName("main/gstlocation");
      this.getGSTLocationList();
      this.data.generationTime=this.dateNow;}

  ngOnInit() {
  }

  getGSTLocationList() {
    this.gstlocationtservice.getGSTLocationList(this.page,this.searchField).subscribe(data => {
        // console.log(this.searchField);
         if (data["success"]) {
           this.datalist = data["result"].content;
           this.page.first = data["result"].first;
           this.page.last = data["result"].last;
           this.page.number = data["result"].number;
           this.page.numberOfElements = data["result"].numberOfElements;
           this.page.offset = data["result"].offset;
           this.page.size = data["result"].size;
           this.page.totalElements = data["result"].totalElements;
           this.page.totalPages = data["result"].totalPages;
           if (this.page.number == 0) {
             this.gridComp.setPages();
           }
         } else {
           alert("Failed to get GSTLocationList!");
         }
       })
   }
   isAllowAddorEdit() {
     let isAllowSave = false;
     if (this._action == "new") {
       isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
     } else if (this._action == "update") {
       isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
     }
     console.log(isAllowSave)
     return isAllowSave;
   }
   disableSave(f: any) {
     let isDisableSave: boolean = true;
     console.log(this.isModified());
     if (f.valid) {
       if (this.isModified()) {
         console.log(this.isAllowAddorEdit())
         if (this.isAllowAddorEdit()) {
           isDisableSave = false;
         }
       }
     }
     return isDisableSave;
   }
   onRowDblClick(locationvalue:any) {
    this._action = "update";
    this.isDetailScreen = true;
    //this.isScreen = false;
    //this.router.navigate(['user']);
    this.getDetail(locationvalue.locationId);
    this._data = this.tools.copyFullObject(this.data);
  }


  onBackClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Details has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
    }
  }
  isModified() {
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.gstNumber != this._data.gstNumber) {
      return true;
    } else if (this.data.locationName!= this._data.locationName) {
      return true;
    } 
      return false;
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  async onAddNew() {
    this.data = new GSTLocationModel();
    this._data = this.tools.copyFullObject(this.data);
    this._action = "new";
    this.data.generationTime = this.dateNow;
    this.isDetailScreen = true;

  }
  async getDetail(locationId) {
   if (locationId) {
      this.gstlocationtservice.getGSTLocation(locationId).subscribe(data => {
        if (data["success"]) {
          this.data = data["result"];
          this.data.generationTime=new Date(this.data.generationTime);
          this._data = this.tools.copyFullObject(this.data);
        }
      }
      )}
  }

  saveData() {
    console.log(this.data)
    this.gstlocationtservice.saveGSTLocation(this.data,this._action).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "GSTLocation  saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
            if (result == "ok") {
              if (result == "ok") {
                this.getGSTLocationList();
                this.isDetailScreen = false;
              }
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } 
      
      else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save GSTLocation!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("station not saved");
      }
    });
  }

   

}
