import { Component, OnInit, ViewChild, Output, Input,EventEmitter, DoCheck } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { StationModel } from "src/app/models/station.model";
import { StationsService } from "src/app/services/Stations.Service";
import { __values } from 'tslib';
//import { RfidDetailComponent } from "../rfid-detail/community.component";
import { OperatorModel } from "src/app/models/operator.model";
import { HoursofdayModel } from "src/app/models/hoursofday.model";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { TariffRuleService } from "../../services/tariffrule.service"
import {GridPage,gridColumns,GridtableComponent} from "../../util/components/gridtable/gridtable.component";
import { TariffRuleModel } from "src/app/models/tariffrule.model";
import { SubscriptionModel } from "src/app/models/subscription.model";
import { containsElement } from "@angular/animations/browser/src/render/shared";
import { ThemeService } from "ng2-charts";
import { DiscountService } from "../../services/discount.service";
import { DiscountModel } from "src/app/models/discount.model";
@Component({
  selector: 'app-tariffpolicy',
  templateUrl: './tariffpolicy.component.html',
  styleUrls: ['./tariffpolicy.component.css']
})
export class TariffpolicyComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Schedule",
      columnFieldName: "schedule",
      columnClasses: "c-col-pc-10",
      
    },
    {
      columnHeader: "Valid From",
      columnFieldName: "validFrom",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Valid Till",
      columnFieldName: "validTill",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Modified By",
      columnFieldName: "",
      columnClasses: "c-col-pc-10",
      
    },
    {
      columnHeader: "Creation Date",
      columnFieldName: "gents",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Cancellation Policy",
      columnFieldName: "cancellationPolicy",
      columnClasses: "c-col-pc-10",
      
    },
  ];

  searchField = {
    searchText: "",
    searchSubscription:"",
    scope:"U",
    status:""
  };
  type:string;
  stationlist: Array<StationModel>;
  hoursOfDaylist:Array<HoursofdayModel>;
  discountList:Array<DiscountModel>;
  dateNow: Date = new Date();
  data: TariffRuleModel;
    _data: TariffRuleModel;
    chargingFormula:string;
    parkingFormula:string;
    othersFormula:string;
    subscriptionlist: Array<SubscriptionModel>;
    filterSubscriptionlist: Array<SubscriptionModel>;
    selectedSubscription:Array<SubscriptionModel>;
    selectedStations = [];
    subscription:SubscriptionModel;
    progress: { percentage: number } = { percentage: 0 };
    _isSaved: boolean = false;
    datalist: Array<TariffRuleModel>;
    operator: OperatorModel;
    page: GridPage;
    _action: string;
    dropdownSettings= {};
    hideP: boolean = false;
    hideO: boolean = false;
    isParking: boolean = true;
    isOthers: boolean = true;
    iscommercialAllowed = false;
    isDetailScreen: boolean = false;
    //_disableSaveCancel: boolean = true;
   // isScreen: boolean = true;
    commercialTypes:string;
    
    _menuId: string;
    topbartext: TopbarText[] = [{ key: "tariffrules", displayText: "Tariff Rules" }];
  constructor(private ngbModalService: NgbModal,
    public tools: Tools,
    private discountservice: DiscountService,
    private objGlobal: global,
    private auth: AuthService,
    private tariffruleservice: TariffRuleService,
    private stationservice: StationsService) {
      this.data = new TariffRuleModel();
      
      //this.rfidarrlist = new RFIDModel();
      this.page = new GridPage();
      this._menuId = this.tools.getMenuName("main/tariffrules");
     // this.getSubscriptionList();
     // this.searchField.searchText="";
      this.data.gents=this.dateNow;
      this.data.modts=this.dateNow;
      this.getSubscriptions();
      this.subscription = new SubscriptionModel();
      this.getStations();
      this.alltimeperiodlist();
      this.allDiscountlist();
     }

  ngOnInit() {
    this.dropdownSettings = { 
      singleSelection: false,
      idField: 'stationId',
      textField: 'stationname',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showCheckbox : true,
      enableCheckAll:true,
      enableFilterSelectAll:true,
      filterSelectAllText: "All roles selected",
      maxHeight : 200
      }       
  this.selectedStations=undefined;
  }
  


  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

   onRowDblClick(sublistvalue:any) {
    // console.log(sublistvalue);
    this._action = "update";
    this.isDetailScreen = true;
    this.getDetail(sublistvalue.ruleID);
  }
  disableSave(f: any) {
    let isDisableSave: boolean = true;
 //console.log(f);
    if (f.valid) {
    //  console.log(this.isModified())
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
   // console.log(isDisableSave)
    return isDisableSave;
  }
  getTariffRuleList() {
    this.tariffruleservice.getTariffRuleList(this.searchField).subscribe(data => {
        // console.log(this.searchField);
         if (data["success"]) {
           this.datalist = data["result"];
           //console.log(this.datalist);
          /* this.page.first = data["result"].first;
           this.page.last = data["result"].last;
           this.page.number = data["result"].number;
           this.page.numberOfElements = data["result"].numberOfElements;
           this.page.offset = data["result"].offset;
           this.page.size = data["result"].size;
           this.page.totalElements = data["result"].totalElements;
           this.page.totalPages = data["result"].totalPages;
           if (this.page.number == 0) {
             this.gridComp.setPages();
           }*/
         } else {
           alert("Failed to get Tariff Rule list!");
         }
       
       });
   }
   getDetail(Id) {
    if (Id) {
     // console.log(Id)
      
       this.tariffruleservice.getTariffRule(Id).subscribe(data => {
         if (data["success"]) {
          
           this.data = data["result"];
           this.data.gents=new Date(this.data.gents);
           this.data.modts=new Date(this.data.modts);
           this.data.validFrom=new Date(this.data.validFrom);
           this.data.validTill=new Date(this.data.validTill);
           this._data = this.tools.copyFullObject(this.data);
           let tariffPlanId = Number(this.data.tariffPlan)
           let selectedSubscriptions: Array<SubscriptionModel> = new Array<SubscriptionModel>();
            if(this.data.tariffPlan){
              this.tariffWithScope();
              const subsItemArr = this.subscriptionlist.filter(itm => itm.subId==tariffPlanId);
              selectedSubscriptions = subsItemArr;
              this.subscription = {subId : tariffPlanId};
            }
            console.log(this.subscription.subId);
            this.selectedSubscription = selectedSubscriptions;  
            let stationIds= this.data.stations.split(",");
            let stations : Array<StationModel> = new Array<StationModel>();
            for(let i=0; i<stationIds.length; i++) {  
              const station = this.stationlist.filter(obj => obj.stationId === Number(stationIds[i]))              
              stations.push(station[0])
            }
            this.selectedStations=stations;
         }
         console.log(this.selectedStations)
       }
       )}
   }
   onBackClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Details has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
    }
  }

  async getSubscriptions() {
    this.subscriptionlist = undefined;
    await this.tariffruleservice
      .getAllSubscription()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let subscriptions: Array<SubscriptionModel> = new Array<SubscriptionModel>();
          let subscription: SubscriptionModel = new SubscriptionModel();
          subscription.subId = undefined;
          subscription.name = "-";
          subscriptions.push(subscription);
          const datasubscription: Array<SubscriptionModel> = data["result"];
          for (let i: number = 0; i < datasubscription.length; i++) {
            subscriptions.push(datasubscription[i]);
          }
          this.subscriptionlist = subscriptions;
          console.log(this.subscriptionlist);
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }

   isModified() {
    if (!(this.data && this._data)) {
      return false;
    }
     else if (this.data.name != this._data.name) {
      return true;
    } else if (this.data.tariffPlan != this._data.tariffPlan) {
      return true;
    } else if (this.data.schedule != this._data.schedule) {
      return true;
    } 
    else if (this.data.discountpolicy != this._data.discountpolicy) {
      return true;
    }
    else if (this.data.validFrom != this._data.validFrom) {
      return true;
    } else if (this.data.validTill  != this._data.validTill) {
      return true;
    } else if (this.data.scope  != this._data.scope) {
      return true;
    } else if (this.data.cancellationPolicy  != this._data.cancellationPolicy) {
      return true;
    } 
      return false;
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  tariffWithScope(){
  //  console.log( this.data.scope)
    let scope = this.data.scope
   
    let filteredSubscriptions: Array<SubscriptionModel> = new Array<SubscriptionModel>();
    if(this.data.scope==="U"){
      const subsItemArr = this.subscriptionlist.filter(itm => itm.scope==scope);
      filteredSubscriptions = subsItemArr;
      console.log(subsItemArr);
    }
    else if(this.data.scope==="B"){
      const subsItemArr = this.subscriptionlist.filter(itm => itm.scope==scope);
      console.log(subsItemArr);
      filteredSubscriptions = subsItemArr;
    }
    else if(this.data.scope==="C"){
      const subsItemArr = this.subscriptionlist.filter(itm => itm.scope==scope);
      console.log(subsItemArr);
      filteredSubscriptions = subsItemArr;
    }
    this.filterSubscriptionlist = filteredSubscriptions;
      }
showSelectedSubscription(event){
  console.log(this.data.tariffPlan)
  let selectedtariffPlanId = event.target.value
  let tariffPlanId = Number(this.data.tariffPlan)
  let selectedSubscriptions: Array<SubscriptionModel> = new Array<SubscriptionModel>();
  if(tariffPlanId){
    const subsItemArr = this.subscriptionlist.filter(itm => itm.subId==tariffPlanId);
    selectedSubscriptions = subsItemArr;
  }
  if(tariffPlanId!==selectedtariffPlanId){
    const subsItemArr = this.subscriptionlist.filter(itm => itm.subId==selectedtariffPlanId);
    selectedSubscriptions = subsItemArr;
  }
  this.selectedSubscription = selectedSubscriptions;
  console.log(selectedSubscriptions)
}
  saveData() {
    this.data.tariffPlan = this.subscription.subId.toString();
    console.log(this.selectedStations);
    let stations = [];
    let stationsString ="";
    if(this.selectedStations){
    for(let i=0;i<this.selectedStations.length;i++){
      let station = this.selectedStations[i].stationId;
      stations.push(station);
    }
    stationsString = stations.join(",");
    }
    this.data.stations = stationsString
    //console.log(this.subscription)
   // console.log(this.data.tariffPlan)
    this.tariffruleservice.saveTariffRule(this.data,this._action).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Tariff Rule saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
            if (result == "ok") {
              if (result == "ok") {
                this.getTariffRuleList();
                this.isDetailScreen = false;
              }
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } 
      
      else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save Tariff Rule!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("station not saved");
      }
    });
  }

  isStationExistsInStations(station : string) {
    if(this.selectedStations) {
        for(let i=0 ; i<this.selectedStations.length;i++) {
            if(this.selectedStations[i]==station) {
                return true;
            }
        }
    }
    return false;
    }
      onSelectAllStations(items: any){
            for(let i=0;i<this.stationlist.length;i++) {
             if(!this.isStationExistsInStations(this.stationlist[i].stationId.toString())) {
                if(!this.selectedStations){
                  this.selectedStations=new Array<string>()
                }
                this.selectedStations.push(this.stationlist[i]); 
          }
        }
        this.data.stations=this.selectedStations.join(',');
        console.log(this.data.stations);
        
      }

      onDeSelectAllStations(items: any){
       this.stationSelectDeselect();
       this.data.stations=null;
      }
      stationSelectDeselect() {
        this.data.stations=this.selectedStations.join(',');
        //console.log(this.data.amenities);
      }
      onStationSelect(item:any){
        this.stationSelectDeselect();
       }
      onStationDeSelect(item:any){                  
        this.stationSelectDeselect();
    }

  async getStations() {
    this.stationlist = undefined;
    await this.stationservice
      .getAllStations()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let stations: Array<StationModel> = new Array<StationModel>();
          let station: StationModel = new StationModel();
          station.stationId = undefined;
          station.stationname = "-";
          stations.push(station);
          const datastations: Array<StationModel> = data["result"];
          for (let i: number = 0; i < datastations.length; i++) {
            stations.push(datastations[i]);
          }
          this.stationlist = stations;
         // console.log(this.data.config);
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }

  async onAddNew() {
  this.data = new TariffRuleModel();
    this._data = this.tools.copyFullObject(this.data);
    this.getSubscriptions();
    this._action = "new";
    this.data.tariffPlan = undefined;
    this.data.schedule = '24X7';
    this.data.cancellationPolicy = 'no';
    this.isDetailScreen = true;
    this.data.validFrom=this.dateNow;
    const aYearFromNow = new Date();
aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
console.log(aYearFromNow);
    this.data.validTill=aYearFromNow;
    

  }
  async alltimeperiodlist() {
    this.hoursOfDaylist = undefined;
    await this.stationservice
      .alltimeperiodlist("B")
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let stations: Array<HoursofdayModel> = new Array<HoursofdayModel>();
          let station: HoursofdayModel = new HoursofdayModel();
          station.id = undefined;
          station.name = "-";
          stations.push(station);
          const datastations: Array<HoursofdayModel> = data["result"];
          for (let i: number = 0; i < datastations.length; i++) {
            stations.push(datastations[i]);
          }
          this.hoursOfDaylist = stations;
         // console.log(this.data.config);
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }
  async allDiscountlist() {
    this.discountList = undefined;
    await this.discountservice
      .alldiscountlist(this.type)
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let discounts: Array<DiscountModel> = new Array<DiscountModel>();
          let discount: DiscountModel = new DiscountModel();
          discount.code = undefined;
          discount.name = "-";
          discounts.push(discount);
          const datadiscounts: Array<DiscountModel> = data["result"];
          for (let i: number = 0; i < datadiscounts.length; i++) {
            discounts.push(datadiscounts[i]);
          }
          this.discountList = discounts;
         // console.log(this.data.config);
        } else {
          console.error("Error in fetching alll Schemes");
        }
      });
  }
}
