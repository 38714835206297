import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppSettings } from '../config/AppSettings';
import {GridPage} from '../util/components/gridtable/gridtable.component';
import { AppUserModel } from '../models/appUser.Model';
import { SortClass } from "../modules/util.class";
@Injectable()
export class AppUserService {

  constructor(private http: HttpClient, private appsetting : AppSettings) { } 
  
    getUserList(pageinfo: GridPage, searchField 
      ) {
      //  alert("hii");
        let header = new HttpHeaders();
        let httpParam: HttpParams = new HttpParams();
        httpParam = httpParam.append("page", pageinfo.number.toString());
        httpParam = httpParam.append("size", pageinfo.size.toString());
        httpParam = httpParam.append("searchText", searchField.searchText);
        httpParam = httpParam.append("searchStatus", searchField.searchStatus);
       // alert("hii");
       
        return this.http
          .get(this.appsetting.get("server-url") + "/api/allAppuser", {
            headers: header,
            observe: "body",
            params: httpParam,
            responseType: "json"
          })
          .map(res => res);
      }
      getDetail(username: string) {
        let header = new HttpHeaders();
        let param: HttpParams = new HttpParams();
        param = param.append("username", username.toString());
        return this.http
          .get(this.appsetting.get("server-url") + "/api/appuser", {
            headers: header,
            observe: "body",
            params: param,
            responseType: "json"
          })
          .map(res => res);
      }
      save(users:AppUserModel,requestType:String) {
        if(requestType==="new"){
          let header = new HttpHeaders();
        console.log(users.balanceUpdates);
        return this.http
          .put(this.appsetting.get("server-url") + "/api/newappuser",users, {
            headers: header
          })
          .map(res => res);

        }
        else{
          let header = new HttpHeaders();
          console.log(users);
          return this.http
            .put(this.appsetting.get("server-url") + "/api/appuser",users, {
              headers: header
            })
            .map(res => res);
  
        }
       }
    deleteUser(username : string) {
      let header = new HttpHeaders();
      let param : HttpParams=new HttpParams();
      param=param.append("username", username);         
      return this.http.post( this.appsetting.get("server-url") + '/api/AppUserDeletion',
        {headers : header,observe : "body", params : param,responseType : "json"})
      .map(res=>res);
    }
    inactiveUser(username : string) {
      let header = new HttpHeaders();
      let param : HttpParams=new HttpParams();
      param=param.append("username", username);     
      let objUser : AppUserModel = new AppUserModel();
      objUser.username=username;
      return this.http.get(this.appsetting.get("server-url") + "/api/AppUserDeletionbyname", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res=>res);
    }

    approveRFIDRequest(username: string){
      let header = new HttpHeaders();
      let param: HttpParams = new HttpParams();
      param = param.append("username", username.toString());
      return this.http.get(this.appsetting.get("server-url") + "/api/approverfidrequest", {
          headers: header,
          observe: "body",
          params: param,
          responseType: "json"
        })
        .map(res => res);

    }
    getBalanceList(pageinfo: GridPage, appusername 
      ) {
      //  alert("hii");
        let header = new HttpHeaders();
        let httpParam: HttpParams = new HttpParams();
        httpParam = httpParam.append("page", pageinfo.number.toString());
        httpParam = httpParam.append("size", pageinfo.size.toString());
        httpParam = httpParam.append("appusername", appusername);
       // alert("hii");
       
        return this.http
          .get(this.appsetting.get("server-url") + "/api/appuserbalancedetail", {
            headers: header,
            observe: "body",
            params: httpParam,
            responseType: "json"
          })
          .map(res => res);
      }
      
      getUserSessions(pageinfo: GridPage, searchField
        ) {
        //  alert("hii");
          let header = new HttpHeaders();
          let httpParam: HttpParams = new HttpParams();
          httpParam = httpParam.append("page", pageinfo.number.toString());
          httpParam = httpParam.append("size", pageinfo.size.toString());
          httpParam = httpParam.append("searchText", searchField.searchText);
         // alert("hii");
         
          return this.http
            .get(this.appsetting.get("server-url") + "/api/getusersessions", {
              headers: header,
              observe: "body",
              params: httpParam,
              responseType: "json"
            })
            .map(res => res);
        }
        getuserevDetail( username:any) {
          //  alert("hii");
            let header = new HttpHeaders();
            let httpParam: HttpParams = new HttpParams();
           
            httpParam = httpParam.append("username", username.toString());
           // alert("hii");
           
            return this.http
              .get(this.appsetting.get("server-url") + "/api/userevs", {
                observe: "body",
                params: httpParam,
                responseType: "json"
              })
              .map(res => res);
          }
          UpdateUserevDetail(body:any ) {
            //  alert("hii");
              let header = new HttpHeaders();
              let httpParam: HttpParams = new HttpParams();
             
              
             
              return this.http
                .post(this.appsetting.get("server-url") + "/api/addupdateev",body, {
                  headers: header,
                  observe: "body",
                  params: httpParam,
                  responseType: "json"
                })
                .map(res => res);
            }
}
