
export class DiscountModel {
    name?:string;
    code?: string;
    type?: string;
    status?:string;
    formula?:string;
    userName?: number;
    validFrom?: Date;
    validTill?: Date;
    generationTime?:Date;
    value?: string;
}
