import { Injectable } from '@angular/core';
import { Subject } from "rxjs/Subject";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs/Observable";

export class global {
    activeMainMenu : string;
    activeMenu : string;
    currentMenuPath : string;
    Auth : {
        token : any;
        user :  string;
    }
    private isMobile = new Subject();
    public screenWidth: string;
    constructor() {
        this.Auth={token : "", user :""};
        this.checkWidth();
       // this.loadAuthToken();
    }
    setActiveMenu(menuname : string) {
        let menusdata=menuname.split("=>");
        this.activeMainMenu=menusdata[0];
        //console.log("set active menu " + menusdata);
        if(menusdata[1]) {
            this.activeMenu=menusdata[1];
        } else {
            this.activeMenu=menusdata[0];
        }
    }
    onMobileChange(status: boolean) {
        this.isMobile.next(status);
    }

    getMobileStatus(): Observable<any> {
        return this.isMobile.asObservable();
    }

    public checkWidth() {
        var width = window.innerWidth;
        if (width <= 768) {
            this.screenWidth = 'sm';
            this.onMobileChange(true);
        } else if (width > 768 && width <= 992) {
            this.screenWidth = 'md';
            this.onMobileChange(false);
        } else {
            this.screenWidth = 'lg';
            this.onMobileChange(false);
        }
    }

}


