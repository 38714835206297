import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  public data: any;

  constructor(private route: Router) {
    this.setData();
  }
  setRootMenus() {}

  ngOnInit() {}
  onLogoutClick() {}

  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler(event) {}

  setData() {
    let noOfChargers: number = 2;
    let chargingSession: number = 129;
    let unitDelivered: number = 2345;
    let carbonOffset: number = 456;

    let strnoOfChargers: string = noOfChargers.toLocaleString("en-IN");
    let strchargingSession: string = chargingSession.toLocaleString("en-IN");
    let strunitDelivered: string = unitDelivered.toLocaleString("en-IN");
    let strcarbonOffset: string = carbonOffset.toLocaleString("en-IN");

    this.data = {
      noOfChargers: strnoOfChargers,
      chargingSession: strchargingSession,
      unitDelivered: strunitDelivered,
      carbonOffset: strcarbonOffset
    };
  }
  onLoginClick() {
    this.route.navigate(["/login"]);
  }
}
