import { Component, OnInit,ViewChild } from '@angular/core';
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { Tools } from "../../modules/tools";
import { HoursofdayModel } from "src/app/models/hoursofday.model";
import { AuthService } from "../../services/auth.service";
import * as XLSX from "xlsx";
import { TopbarText } from "../../components/topbar/topbar.component";
import { CommunityService } from "../../services/Community.service";
import { global } from "../../modules/global";
import { StationModel } from "src/app/models/station.model";
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { ChargerService } from "src/app/services/charger.service";
import { StationsService } from "src/app/services/Stations.Service";
import { OperatorModel } from "src/app/models/operator.model";
import { OperatorService } from "src/app/services/operator.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { LogsService } from "../../services/logs.service";
import { Router,ActivatedRoute } from "@angular/router";
import { ChargerComponent } from "../charger/charger.component";


@Component({
  selector: 'app-hours-of-day',
  templateUrl: './hours-of-day.component.html',
  styleUrls: ['./hours-of-day.component.css']
})
export class HoursOfDayComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Type",
      columnFieldName: "type",
      columnClasses: "c-col-pc-15 text-center"
    },
    {
      columnHeader: "Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-20 text-center"
    },
    {
      columnHeader: "From",
      columnFieldName: "from",
      columnClasses: "c-col-pc-15 text-center"
    },
    {
      columnHeader: "To",
      columnFieldName: "to",
      columnClasses: "c-col-pc-15 text-center"
    },
    {
      columnHeader: "Description",
      columnFieldName: "desciption",
      columnClasses: "c-col-pc-25 text-center"
    },
  ];
  logs: Array<object>;
  page: GridPage;
  _action: string;
  data: HoursofdayModel;
  _data: HoursofdayModel;
  _menuId: string;
  searchField={
    searchText:""
    
  }
  error: any = { isError: false, errorMessage: "" };
  isValidDate: any;
  
  dateNow: Date = new Date();
  hoursOfDaydatalist:Array<HoursofdayModel>;
  stationlist: Array<StationModel>;
  _isSaved:boolean=false;
  station: StationModel;
  operator: OperatorModel;
  operatorlist: Array<OperatorModel>;
  chargerslist: Array<ManageChargerModel>;
  isDetailScreen: boolean = false;
  isOperatorScreen: boolean = false;
  isChargerScreen: boolean = false;
  topbartext: TopbarText[] = [{ key: "history", displayText: "Hours of Day" }];
  isAddClick: boolean=false;
  isDblClick:boolean=false;
  constructor(
    private communityService:CommunityService,
    private auth: AuthService,
    private ngbModalService: NgbModal,
    private router: Router,
    public tools: Tools,
    private objGlobal: global,
    private objLogService: LogsService,
    public chargerservice: ChargerService,
    private stationservice: StationsService,
    private operatorservice: OperatorService,
    private activatedRoute:ActivatedRoute,
    private managechargerservice: ManageChargerService,
  ) {
    this.data = new HoursofdayModel();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
   
    this._menuId = this.tools.getMenuName(
      "main/HoursOfDay"
    );
    this.objGlobal.setActiveMenu(this._menuId);
    //console.log(this._menuId);
    //this.objGlobal.setActiveMenu(this._menuId);
      }

  ngOnInit() {}

  async getHoursOfDayList() {
    console.log(this.searchField)
    this.communityService.getHoursOfDayList(this.page,this.searchField).subscribe(data => {
      console.log(this.data)
      if (data["success"]) {
        this.hoursOfDaydatalist = data["result"].content;
        this.page.first = data["result"].first;
        this.page.last = data["result"].last;
        this.page.number = data["result"].number;
        this.page.numberOfElements = data["result"].numberOfElements;
        this.page.offset = data["result"].offset;
        this.page.size = data["result"].size;
        this.page.totalElements = data["result"].totalElements;
        this.page.totalPages = data["result"].totalPages;
        if (this.page.number == 0) {
          this.gridComp.setPages();
        }
      } else {
        alert("Failed to get Logs!");
      }
    });
  }
  gridDeleteButton() {
    let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
    return isdelete;
  }
  userRemoveFromHoursofDay(userVal){
    let objTimePeriod: HoursofdayModel = {};
    objTimePeriod = userVal.obj
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to remove ?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          objTimePeriod=null;
         
        //  this.communityService.saveTimePeriod(this.data,this.searchField.searchText).subscribe
          (data => {
       let ngmodaloptions: NgbModalOptions = {};
       ngmodaloptions.backdrop = "static";
       ngmodaloptions.size = "sm";
       if (data["success"]) {
         this._isSaved = true;
         const modalRef = this.ngbModalService.open(
           NgInformationModalContent,
           ngmodaloptions
         );
         modalRef.componentInstance.title = "Information";
         modalRef.componentInstance.message = "User removed successfully!";
         modalRef.componentInstance.btnCLass = "btn-info";
         
         modalRef.result.then(
           result => {
            console.log(this.data);

            this.getHoursOfDayList();
            //  this.data = data["result"];
            //  this._data = this.tools.copyFullObject(this.data);
           },
           reason => {
             this.data = data["result"];
             this._data = this.tools.copyFullObject(this.data);
             
           }
         );
          
       } else {
         const modalRef = this.ngbModalService.open(
           NgInformationModalContent,
           ngmodaloptions
         );
         modalRef.componentInstance.title = "Error";
         modalRef.componentInstance.message = "Failed to remove !";
         modalRef.componentInstance.btnCLass = "btn-warning";
         modalRef.result.then(
           result => {},
           reason => {}
         );
         // alert("station not saved");
       }
     });
    }
    });
  }
  onAddNew(){
    
    this.data = new HoursofdayModel;
    this._data = this.tools.copyFullObject(this.data);
    this.isDetailScreen = true;
    this.isOperatorScreen = false;
    this.isChargerScreen = true;
    this._action = "new";
   this.isAddClick=true;
  }
  hideOperatorScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (!this.isChargerScreen && this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  hideChargerScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (this.isChargerScreen && !this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  
 
   isModified() {
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.name != this._data.name) {
      return true;
    } else if (this.data.desciption != this._data.desciption) {
      return true;
    } else if (this.data.from != this._data.from) {
      return true;
    } else if (this.data.to != this._data.to) {
      return true;
    } 
    else if (this.data.type != this._data.type) {
      return true;
    } 
      return false;
  }
  closeButton(){
    this.isDetailScreen = false;
    this.isAddClick=false; 
    this.isDblClick=false;
  }
  onBackClick(){
   
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Details has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            this.isAddClick=false; 
            this.isDblClick=false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
      this.isAddClick=false; 
      this.isDblClick=false;
    }
     
  }
  saveData(){
  
    this.communityService.saveTimePeriod(this.data,this._action).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            
            this._data = this.tools.copyFullObject(this.data);
            if (result == "ok") {
              //this.getList();
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save Hours of Day!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("station not saved");
      }
    });
  }
  getTpDetail(tp:string) {
    //   console.log(userid);
         this.communityService.getTpDetail(tp).subscribe(data =>{
         if(data["success"]) {
             
            this.getHoursOfDayList();
         } else {
         alert("Failed to get Hours of Day Data!")
         }    
     })
 
 }
 onRowDblClick(listval: any) {
  this.data = listval;
  this._data = this.tools.copyFullObject(this.data);
  this._action = "update";
  this.isDetailScreen = true;
  this.isOperatorScreen = false;
  this.isChargerScreen = true; 
  this.isAddClick=false;
  this.isDblClick=true;
    
}
}
