import { Component, OnInit, ViewChild } from "@angular/core";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { Tools } from "../../modules/tools";
import * as XLSX from "xlsx";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { StationModel } from "src/app/models/station.model";
import { ChargerService } from "src/app/services/charger.service";
import { StationsService } from "src/app/services/Stations.Service";
import { OperatorModel } from "src/app/models/operator.model";
import { OperatorService } from "src/app/services/operator.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { LogsService } from "../../services/logs.service";
import { Router,ActivatedRoute } from "@angular/router";
import { ChargerComponent } from "../charger/charger.component";
export class searchField {
  operatorId?: number;
  chargerId?: string;
  stationId?: number;
  startdate?: Date;
  enddate?: Date;
  api?:string;
}
@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.css"]
})
export class LogComponent implements OnInit {
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "TimeStamp",
      columnFieldName: "event_ts",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Charger",
      columnFieldName: "cpIdentity",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "API",
      columnFieldName: "eventName",
      columnClasses: "c-col-pc-8"
    },
   
    {
      columnHeader: "Payload",
      columnFieldName: "eventParameters",
      columnClasses: "c-col-pc-45"
    },
    
   
   
  ];
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  logs: Array<object>;
  page: GridPage;
  _menuId: string;
  error: any = { isError: false, errorMessage: "" };
  isValidDate: any;
  dateNow: Date = new Date();
  searchField: searchField;
  stationlist: Array<StationModel>;
  station: StationModel;
  operator: OperatorModel;
  operatorlist: Array<OperatorModel>;
  chargerslist: Array<ManageChargerModel>;
 

  topbartext: TopbarText[] = [{ key: "loghistory", displayText: "Logs" }];

  constructor(
    private router: Router,
    public tools: Tools,
    private objGlobal: global,
    private objLogService: LogsService,
    public chargerservice: ChargerService,
    private stationservice: StationsService,
    private operatorservice: OperatorService,
    private activatedRoute:ActivatedRoute,
    private managechargerservice: ManageChargerService,
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this.searchField = new searchField();
   if(this.activatedRoute.snapshot.paramMap.get('chargerId')!=null){
    this.searchField.chargerId=this.activatedRoute.snapshot.paramMap.get('chargerId');
   }else{
   this.searchField.chargerId="All";
   }
   this.searchField.api="All";
   this.searchField.startdate=this.dateNow;
   this.searchField.enddate=this.dateNow;
    this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
    );
    //console.log(this._menuId);
    //this.objGlobal.setActiveMenu(this._menuId);
    this.getLogs();
  
    this.getChargers();
    //this.getData();
  }

  ngOnInit() {}

  public getSerachResult() {
    
    if (this.validateDates(this.searchField)) {
      //this.getData();
      this.getLogs();
    }
  }
  

  async getChargers() {
    this.chargerslist = undefined;
    await this.managechargerservice
      .getChargersSelectionListWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          this.chargerslist = data["result"];
        } else {
          console.error("Error in fetching all chargers");
        }
      });
  }

  getChargersList() {
    return this.chargerslist;
    
  }

 
  getLogs() {
    this.objLogService.getLogs(this.page, this.searchField).subscribe(data => {
      if (data["success"]) {
        this.logs = data["result"].content;
        this.page.first = data["result"].first;
        this.page.last = data["result"].last;
        this.page.number = data["result"].number;
        this.page.numberOfElements = data["result"].numberOfElements;
        this.page.offset = data["result"].offset;
        this.page.size = data["result"].size;
        this.page.totalElements = data["result"].totalElements;
        this.page.totalPages = data["result"].totalPages;
        if (this.page.number == 0) {
          this.gridComp.setPages();
        }
      } else {
        alert("Failed to get Logs!");
      }
    });
  }

  getData() {
    this.objLogService.getData().subscribe(data => {
      this.logs = data["result"].content;
    });
  }
 
  validateDates(searchField) {
    this.isValidDate = true;
    if (searchField.startdate == null || searchField.enddate == null) {
      this.error = {
        isError: true,
        errorMessage: "Start date and end date are required."
      };
      this.isValidDate = false;
    } else if (
      searchField.startdate != null &&
      searchField.enddate != null &&
      searchField.enddate < searchField.startdate
    ) {
      this.error = {
        isError: true,
        errorMessage: "End date should be grater then start date."
      };
      this.isValidDate = false;
    } else {
      this.error = { isError: false, errorMessage: "" };
      this.isValidDate = true;
    }
    return this.isValidDate;
  }
  downloadExcel () {
    let curdate = new Date();
    let curdatestring = this.tools.formatDateToDDMMYY(curdate);
    const worksheet = XLSX.utils.json_to_sheet(this.logs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Logs_"+curdatestring+".xlsx");
  };

  export(){
    var csvData = this.ConvertToCSV( this.logs);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    var x:Date = new Date();
    var link:string ="Logs_"+ this.tools.formatDateToDDMMYY(x) +'.csv';
    a.download = link.toLocaleLowerCase();
    a.click();

  }

  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
        //Now convert each value to string and comma-separated
        row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }

}
