import { Injectable } from "@angular/core";
import { GridPage } from "../util/components/gridtable/gridtable.component";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AppSettings } from "../config/AppSettings";
import { Tools } from "../modules/tools";

@Injectable({
  providedIn: "root"
})
export class RevenueReportService {
  constructor(
    private http: HttpClient,
    private appsetting: AppSettings,
    private tool: Tools
  ) {}

  getRevenueReportList(pageinfo: GridPage, searchField, sortField) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    if (searchField.paymentId) {
      httpParam = httpParam.append("paymentId", searchField.paymentId);
    }
    if (searchField.operatorId) {
      httpParam = httpParam.append("operatorId", searchField.operatorId);
    }
    if (searchField.stationId) {
      httpParam = httpParam.append("stationId", searchField.stationId);
    }
    if (searchField.chargerId ) {
      httpParam = httpParam.append("chargerId", searchField.chargerId);
    }
    if (searchField.startTime) {
      httpParam = httpParam.append("startTime",this.tool.formatDateToDDMMMYY(searchField.startTime));
    }
    if (searchField.stopTime) {
      httpParam = httpParam.append("stopTime",this.tool.formatDateToDDMMMYY(searchField.stopTime));
    }
    if (sortField) {
      if (sortField.sortFieldName) { 
        httpParam = httpParam.append("sortFieldName", sortField.sortFieldName);
      }
      if (sortField.sortDirection) {
        httpParam = httpParam.append("sortDirection", sortField.sortDirection);
      }
    }
    //header.append('Content-Type','application/json');

    return this.http
      .get(this.appsetting.get("server-url") + "/api/revenuereport", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
}
