import { Component , OnInit, ViewChild, DoCheck } from "@angular/core";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { ProfileOcppService } from "../../services/ProfileOcpp.service";
import { Tools } from "../../modules/tools";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { ProfileOcppModel } from "../../models/ProfileOcppModel.model";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { from } from "rxjs";
import { Router,ActivatedRoute } from "@angular/router";
import { OperatorModel } from "src/app/models/operator.model";
import { OperatorService } from "src/app/services/operator.service";
import { OperatorDetailComponent } from "../../components/operator-detail/operator-detail.component";
import { ChargerComponent } from "../charger/charger.component";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ChargerModel } from "src/app/models/Charger.model";
export class searchField {
  operatorId?: number;
  chargerId?: string;
  stationId?: number;
  startdate?: Date;
  enddate?: Date;
  api?:string;
}
@Component({
  selector: 'app-ocpp-new-charger',
  templateUrl: './ocpp-new-charger.component.html',
  styleUrls: ['./ocpp-new-charger.component.css']
})
export class OcppNewChargerComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  @ViewChild(OperatorDetailComponent) operatorComp: OperatorDetailComponent;
  @ViewChild(ChargerComponent) chargerComp: ChargerComponent;
  topbartext: TopbarText[] = [{ key: "NewCharger", displayText: "Charger Digital DNA" }];

  data: ProfileOcppModel;
  _data: ProfileOcppModel;
  _isSaved: boolean = false;
  page: GridPage;
  _menuId: string;
  datalist: Array<ProfileOcppModel>;
  _action: string;
  searchField = {
    searchText: ""
  };

  isDetailScreen: boolean = false;
  _disableSaveCancel: boolean = true;
  isStationScreen: boolean = false;
  isOperatorScreen: boolean = false;
  isChargerScreen: boolean = false;
  _chargeraction: string = "";
  _stationParam: any;
  //For role list
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    
    {
      columnHeader: "OCPP ID",
      columnFieldName: "ocpp_Id",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Vendor",
      columnFieldName: "vendor",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Model",
      columnFieldName: "model",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "ChargerSerialNumber",
      columnFieldName: "chargepointrserialnumber",
      columnClasses: "c-col-pc-20"
    },
    
    {
      columnHeader: "Firmware",
      columnFieldName: "firmwareVersion",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Last Updated Time",
      columnFieldName: "updatetime",
      columnClasses: "c-col-pc-30"
    }
  ];
  isimportscreen:boolean=true;
  isimport:boolean = false;
  operator: OperatorModel;
  operatorlist: Array<OperatorModel>;
  selectedChargerId: number;
  selectedCharger: ManageChargerModel;
  chargers: Array<ManageChargerModel>;
  unmappedChargers: Array<ManageChargerModel>;

  constructor(
    private tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private route: ActivatedRoute,
    private stationservice: ProfileOcppService,
    private ngbModalService: NgbModal,
    private operatorservice: OperatorService,
    private managechargerservice: ManageChargerService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._stationParam = { source: "NewCharger" };
    this._menuId = this.tools.getMenuName("main/NewCharger");
    this.objGlobal.setActiveMenu(this._menuId);
    this.getList();
    this.data = new ProfileOcppModel();
    this.operator = new OperatorModel();
   // this.getOperators();
  }

  ngOnInit() {
    this.route.queryParams.filter(params => params.chargerId)
      .subscribe(params => {
        if (params.chargerId) {
          //this.paramStatus = true;
          this.isDetailScreen=true;
          this.getDetail(params.chargerId);
        }
      }
      );
  }
  onOperatorBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Operator detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.isOperatorScreen = false;
            this.topbartext = [{ key: "stations", displayText: "Stations" }];
          }
        },
        reason => {}
      );
    } else {
      this.isOperatorScreen = false;
      this.topbartext = [{ key: "stations", displayText: "Stations" }];
    }
    
  }
  onChargerBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.isChargerScreen = false;
            this.topbartext = [{ key: "stations", displayText: "Stations" }];
          }
        },
        reason => {}
      );
    } else {
      this.isChargerScreen = false;
      this.topbartext = [{ key: "stations", displayText: "Stations" }];
    }
  }
  onAddNewOperator() {
    this._action = "new";

    this.isOperatorScreen = true;

    this.operatorComp.onAddNew();
    this.topbartext = [
      {
        key: "manage_charge_point_operator",
        displayText: "Manage Charge Point Operator"
      }
    ];
  }

  onAddNewCharger() {
    console.log("Passing parameter " + this._stationParam);
    this._action = "new";

    this.isChargerScreen = true;
    this.chargerComp.onAddNew();

    this.topbartext = [{ key: "Add Charger", displayText: "Add Charger" }];
  }

  async onAddNew() {
    this._action = "new";
    this.data = new ProfileOcppModel();
    this.chargers = undefined;
    this._data = this.tools.copyFullObject(this.data);
    await this.getUnmappedChargers();
    this.isDetailScreen = true;
    this.isOperatorScreen = false;
    this.isChargerScreen = false;
  }

  async getUnmappedChargers() {
    this.unmappedChargers = await this.managechargerservice.getUnmappedStationChargers();
  }
  uploadfile(){
    this.isimport=true;
    this.isimportscreen=false;
  }
  ngDoCheck() {
    this._disableSaveCancel = true;
    if (this.isModified()) {
      if (this.isAllowAddorEdit()) {
        this._disableSaveCancel = false;
      }
    }
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }
  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      // console.log(this.isModified());
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }

 

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  getList() {
    this.stationservice
      .getProfileOcppList(this.page, this.searchField)
      .subscribe(data => {
        if (data["success"]) {
          this.datalist = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        } else {
          alert("Failed to get stations list!");
        }
      });
  }
  onBackbuttonClick() {
    this.isDetailScreen=false;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  saveOperatorEvent(obj) {
    if (obj.success == true) {
      this.getList();
      this.isOperatorScreen = false;
      this.topbartext = [{ key: "stations", displayText: "Stations" }];
    //  this.getOperators();
    } /*else {
      this.getList();
      this.isOperatorScreen = true;
    }*/
  }

  saveData() {
    
  }
  onRoleRowDblClick(rolelistval: any) {
    this._action = "update";
    this.isDetailScreen = true;
   // this.isOperatorScreen = false;
   // this.isChargerScreen = false;
    //this.router.navigate(['user']);
    this.getDetail(rolelistval.ocpp_Id);
  }

  async getDetail(ocpp_Id) {
    if (ocpp_Id) {
      this.stationservice.getProfileOcppDetail(ocpp_Id).subscribe(data => {
        if (data["success"]) {
          this.data = data["result"];
       
         
          this._data = this.tools.copyFullObject(this.data);
          //this.getUnmappedChargers();
        } else {
          this.data = new ProfileOcppModel();
          alert("This charger is not connected to cms yet!");
        }
      });
    }
  }

  isModified() {
    return true;
  }
  isChargerModified() {
 
  }
  
  removeCharger(i: number) {
    this.unmappedChargers.push(this.chargers[i]);
    this.chargers.splice(i, 1);
  }
  getUnmappedChargerTextId(index: number) {
    return (
      this.unmappedChargers[index].chargerName +
      " - " +
      this.unmappedChargers[index].chargerType +
      " - " +
      this.unmappedChargers[index].category
    );
  }

  changeSelectCharger() {
    let notfound: boolean = true;
    this.selectedCharger = undefined;
    if (this.selectedChargerId != undefined) {
      for (
        let i: number = 0;
        i < this.unmappedChargers.length && notfound;
        i++
      ) {
        if (this.selectedChargerId == this.unmappedChargers[i].chargerId) {
          this.selectedCharger = this.unmappedChargers[i];
          notfound = false;
        }
      }
    }
  }
  addChagers() {
    let notfound: boolean = true;
    for (let i: number = 0; i < this.unmappedChargers.length && notfound; i++) {
      if (this.selectedChargerId == this.unmappedChargers[i].chargerId) {
        if (this.chargers == undefined) {
          this.chargers = new Array<ManageChargerModel>();
        }
        this.chargers.push(this.unmappedChargers[i]);
        this.unmappedChargers.splice(i, 1);
        this.selectedChargerId = undefined;
        notfound = false;
        this.changeSelectCharger();
      }
    }
  }

  hideOperatorScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (!this.isChargerScreen && this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  hideChargerScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (this.isChargerScreen && !this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  hideStationDetailScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (!this.isChargerScreen && !this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  saveChargerEvent(objchargersaveresult: any) {
    if (objchargersaveresult) {
      if (objchargersaveresult.success) {
        this.unmappedChargers.push(objchargersaveresult.data);
        this.selectedChargerId = objchargersaveresult.data.chargerId;
        this.topbartext = [{ key: "stations", displayText: "Stations" }];
      }
      this.isChargerScreen = false;
    }
  }}