import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  DoCheck
} from "@angular/core";
import { Tools } from "src/app/modules/tools";
import { OperatorModel } from "src/app/models/operator.model";
import { AuthService } from "src/app/services/auth.service";
import { global } from "src/app/modules/global";
import { Router } from "@angular/router";

import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "src/app/util/components/gridtable/gridtable.component";
import { OperatorService } from "../../services/operator.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/util/components/modalcomponent/modal-content";
import { DateObject } from "ngx-bootstrap/chronos/types";
import { StationModel } from "src/app/models/station.model";
import { CityService } from "src/app/services/City.Service";
import { NgModel } from "@angular/forms";
import { CityModel } from "src/app/models/City.model";

@Component({
  selector: "app-operator-detail",
  templateUrl: "./operator-detail.component.html",
  styleUrls: ["./operator-detail.component.css"]
})
export class OperatorDetailComponent implements OnInit {
  @Input()
  _param: any;
  @Input()
  _menuId: string;
  @Input()
  _action: string;
  @Output()
  saveEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  backClickEvent: EventEmitter<object> = new EventEmitter<object>();

  data: OperatorModel;
  _data: OperatorModel;
  _isSaved: boolean = false;
  page: GridPage;
  obj: StationModel;
  datalist: Array<OperatorModel>;

  searchField = {
    searchText: ""
  };

  _disableSaveCancel: boolean = true;
  isScreen: boolean = false;
  isDetailScreen: boolean = true;

  lastModificationDate;
  creationDate;
  operatorlist: Array<OperatorModel>;
  dataParentOperator: OperatorModel;
  constructor(
    private tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private operatorservice: OperatorService,
    private ngbModalService: NgbModal,
    private cityservice: CityService
  ) {
    this.getOperators();
    this.data = new OperatorModel();
    this.dataParentOperator = new OperatorModel();
  }

  ngOnInit() {}
  onAddNew() {
    this._action = "new";
    this.data = new OperatorModel();
    this._data = this.tools.copyFullObject(this.data);
    this.isScreen = true;
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
    return isdelete;
  }

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }
  /*ngDoCheck() {
    this._disableSaveCancel=false;
    if(this.isModified()) {        
        if(this.isAllowAddorEdit()) {            
            this._disableSaveCancel=true;        
        }
    }  
}*/

  disableSave(f: any) {
    let isDisableSave: boolean = true;
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }

  onBackbuttonClick() {
    this.backClickEvent.emit({
      operatorId: this.data.operatorId,
      isModified: this.isModified()
    });

    //this.cancelClickEvent.emit({userId: this.user.userId,isModified : this.isUserModified()});
  }

  saveData() {
    console.log(this.dataParentOperator);
    if (this.dataParentOperator && this.dataParentOperator.operatorId) {
      this.data.parentOperator = {operatorId: this.dataParentOperator.operatorId};
    } else {
      this.data.parentOperator = undefined;
    }
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    this.operatorservice.saveOperator(this.data).subscribe(
      data => {
        if (data["success"]) {
          this._isSaved = true;
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Information";
          modalRef.componentInstance.message = "Operator saved successfully!";
          modalRef.componentInstance.btnCLass = "btn-info";
          modalRef.result.then(
            result => {
              this.data = data["result"];
              this._data = this.tools.copyFullObject(this.data);
              if (result == "ok") {
                this.saveEvent.emit({ success: true });
                this.isDetailScreen = false;
              }
            },
            reason => {
              this.data = data["result"];
              this._data = this.tools.copyFullObject(this.data);
            }
          );
        } else {
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Warning";
          modalRef.componentInstance.btnCLass = "btn-warning";

          switch (data["message"]) {
            case "InvalidIdToUpdate":
              modalRef.componentInstance.message = "Not a valid id to update!";
              break;
            case "CIRCULAR":
              modalRef.componentInstance.message =
                "Invalid parent operator, makes circular!";
              break;
          }
          modalRef.result.then(
            result => {},
            reason => {}
          );
          // alert("Operator not saved");
        }
      },
      err => {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save Operator!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
      }
    );
  }
  getDetail(_id) {
    if (_id) {
      this.operatorservice.getOperatorDetail(_id).subscribe(data => {
        if (data["success"]) {
          this.data = data["result"];
          this._data = this.tools.copyFullObject(this.data);
          if (this.data.parentOperator) {
            this.dataParentOperator = this.data.parentOperator;
          } else {
            this.dataParentOperator = new OperatorModel();
          }
        } else {
          alert("Failed to get operator list!");
        }
      });
    }
  }

  isModified() {
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.operatorName != this._data.operatorName) {
      return true;
    } else if (this.data.addressline1 != this._data.addressline1) {
      return true;
    } else if (this.data.addressline2 != this._data.addressline2) {
      return true;
    } else if (this.data.operatorPincode != this._data.operatorPincode) {
      return true;
    } else if (this.data.operatorCity != this._data.operatorCity) {
      return true;
    } else if (this.data.operatorState != this._data.operatorState) {
      return true;
    } else if (this.data.operatorCountry != this._data.operatorCountry) {
      return true;
    } else if (this.data.taxDescription != this._data.taxDescription) {
      return true;
    }/* else if (this.data.config != this._data.config) {
      return true;
    } */else if (this.data.taxrate1 != this._data.taxrate1) {
      return true;
    } else if (this.isModifiedParent()) {
      return true;
    }

    return false;
  }

  isModifiedParent() {
    const newParentId =
      this.dataParentOperator && this.dataParentOperator.operatorId
        ? this.dataParentOperator.operatorId
        : undefined;
    const oldParentId =
      this._data &&
      this._data.parentOperator &&
      this._data.parentOperator.operatorId
        ? this._data.parentOperator.operatorId
        : undefined;
    let result = false;
    if (newParentId != oldParentId) {
      return true;
    }
    return result;
  }

  async getOperatorPinCode() {
    const citydata: CityModel = await this.cityservice.getPincodeData(
      this.data.operatorPincode
    );

    if (citydata) {
      this.data.operatorCity = citydata.cityName;
      this.data.operatorState = citydata.state;
      this.data.operatorCountry = citydata.country;
    } else {
      this.data.operatorCity = undefined;
      this.data.operatorState = undefined;
      this.data.operatorCountry = undefined;
    }
  }

  async getOperators() {
    this.operatorlist = undefined;
    await this.operatorservice
      .getAllOperators()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          this.operatorlist = data["result"];
        } else {
          console.error("Error in fetching alll operators");
        }
      });
  }
  getParentOperatorToSelect() {
    let oplist: Array<OperatorModel> = new Array<OperatorModel>();
    oplist.push({ operatorId: undefined, operatorName: "-" });
    if (this.operatorlist) {
      const parentOperators = this.operatorlist.filter(
        obj => obj.operatorId != this.data.operatorId
      );
      parentOperators.forEach(obj => oplist.push(obj));
    }
    return oplist;
  }

  onFileUpload(event){
    const uploadImageData = new FormData();
    let selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      let base64SelectedFile = <string>reader.result;
      var itemImagerawarray=base64SelectedFile.split(";");
       let  itemImagearray = itemImagerawarray[1].split(",");
        this.data.fileData = itemImagearray[1];
        console.log(itemImagearray[1]);
  };
}
}
