import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "../config/AppSettings";
import { Tools } from '../modules/tools';
import {GridPage} from '../util/components/gridtable/gridtable.component';
export class stopTransaction {
  fromStatus : string;
  chargingpoint : string;
  deviceId : string;
  requestStatus :string;
  bookingid : number;
}
@Injectable({
  providedIn: "root"
})
export class ChargerActionService {
  constructor(private http: HttpClient, private appsetting: AppSettings,private tools: Tools) {
    
  }

  getChargersLiveStatus() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/chargerslivestatus", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
  getChargingDetails(pageinfo : GridPage, searchField) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("page", pageinfo.number.toString());
    httpParam=httpParam.append("size",pageinfo.size.toString());
   
    httpParam=httpParam.append("status",searchField.status);
    httpParam=httpParam.append("startTime", this.tools.formatStartDateToDDMMYY(searchField.startdate));
    httpParam=httpParam.append("stopTime",this.tools.formatEndDateToDDMMYY(searchField.enddate));    
    if (searchField.chargerId && searchField.chargerId!=="All") {
      httpParam = httpParam.append("chargerId", searchField.chargerId);
    }  
    if (searchField.tranId) {
      httpParam=httpParam.append("tranId",searchField.tranId);
    } 
    if (searchField.username) {
      httpParam=httpParam.append("username",searchField.username);
    }
    return this.http.get( this.appsetting.get("server-url") + '/api/charging-data',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }

  closeCharging(stoptransaction:stopTransaction) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/changestatus",stoptransaction, {
        headers: header,
       
        responseType: "json"
      })
      .map(res => res);
  }
  changeChargingStatus(tran_id: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("tran_id", tran_id.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/changechargingstatus", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  getChargingDetail(tran_id: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("tran_id", tran_id.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/getcharging", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  getProgressDetail(tran_id: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("tranId", tran_id.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/charging-status", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }

  getChargerTrends(tranId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("chargingId", tranId.toString());

    return this.http
      .get(this.appsetting.get("server-url") + "/api/getchargingtrend", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
}
