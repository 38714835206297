import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injectable, APP_INITIALIZER } from "@angular/core";
import { AuthGuard } from "./guards/auth.guard";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { UserComponent } from "./components/user/user.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomFormsModule } from "ng5-validation";
//import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UserlistComponent } from "./components/userlist/userlist.component";
import { GridtableComponent } from "./util/components/gridtable/gridtable.component";
//import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { AuthService } from "./services/auth.service";
import { UserService } from "./services/user.service";
import { XhrInterceptorService } from "./services/xhr-interceptor.service";
import { global } from "./modules/global";
import { ImageconfigService } from "./services/imageconfig.service";
import { AppSettings } from "./config/AppSettings";
import { MenuService } from "./services/menu.service";
import { Tools } from "./modules/tools";
import { PageheaderComponent } from "./util/components/pageheader/pageheader.component";
import { UserroleComponent } from "./components/userrole/userrole.component";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "./util/components/modalcomponent/modal-content";
import { JwtModule } from "@auth0/angular-jwt";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import {
  ConfirmService,
  ConfirmModalComponent,
  ConfirmState,
  ConfirmTemplateDirective
} from "./util/confirm-modal-and-service";
import { MainComponent } from "./components/main/main.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { TopbarComponent } from "./components/topbar/topbar.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ObjectArraySort } from "./util/pipe/pipetransform.pipe";
import { ChartsModule } from "ng2-charts";
import { ChartModule } from "angular-highcharts";
import { StationsService } from "./services/Stations.Service";
import { ProfileOcppService } from "./services/ProfileOcpp.service";
import { KnobComponentDirective } from "./lib/knob.directive";
import { UserprofileComponent } from "./components/userprofile/userprofile.component";
import { LogComponent } from "./components/log/log.component";
import { ResetpasswordComponent } from "./components/resetpassword/resetpassword.component";

import { StationsComponent } from "./components/stations/stations.component";
import { OperatorsComponent } from "./components/operators/operators.component";
import { OperatorService } from "./services/operator.service";

import { OperatorDetailComponent } from "./components/operator-detail/operator-detail.component";
import { CityComponent } from "./components/city/city.component";
import { CityService } from "./services/City.Service";
import { ManageChargerComponent } from "./components/manage-charger/manage-charger.component";
import { ChargerComponent } from "./components/charger/charger.component";

import { ManageChargerService } from "./services/ManageCharger.service";
import { ChargerService } from "./services/charger.service";
import { ChangepasswordComponent } from "./components/changepassword/changepassword.component";
import { ForgotpasswordComponent } from "./components/forgotpassword/forgotpassword.component";
import { AgmCoreModule } from "@agm/core";
import { ConnectivityStatusReportComponent } from "./components/connectivity-status-report/connectivity-status-report.component";
import { RevenueReportComponent } from "./components/revenue-report/revenue-report.component";
import { ChargeractionComponent } from "./components/chargeraction/chargeraction.component";
import { ActivebookingComponent }  from "./components/activebooking/activebooking.component";
import { ActivebookingService }   from "./services/activebooking.service";
import { FTransactionsService }   from "./services/ftransactions.service";
import { ChargingConfigService }   from "./services/chargingconfig.service";
import { FTransactionsComponent } from './components/ftransactions/ftransactions.component';
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { AppUserService } from "./services/appUser.service";
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OCPPStatusMasterService } from "./services/ocpp.status.master.service";
import { ChargerActionQueueService } from "./services/charger.action.queue.service";
import { CnComponent } from './components/cn/cn.component';
import { TopTextComponent } from './components/top-text/top-text.component';
import { from } from 'rxjs';
import { AppUserComponent } from './components/app-user/app-user.component';
import { OcppNewChargerComponent } from './components/ocpp-new-charger/ocpp-new-charger.component';
import { OcppServerStatusComponent } from './components/ocpp-server-status/ocpp-server-status.component';
import { RfidComponent } from './components/rfid/rfid.component';
import { CommunityComponent } from './components/community/community.component';
import { ChargingSlotsComponent } from './components/charging-slots/charging-slots.component';
import { AlarmsComponent } from './components/alarms/alarms.component';
import { GendiscountComponent } from './components/gendiscount/gendiscount.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { TariffpolicyComponent } from './components/tariffpolicy/tariffpolicy.component';
import { CorporatedetailComponent } from './components/chargingdashboard/chargingdashboard.component';
import { CommunityService } from "./services/Community.service";
import { EmspService } from "./services/emsp.service";
import { EmspComponent } from './components/emsp/emsp.component';
import { OcpiapisComponent } from './components/ocpiapis/ocpiapis.component';
import { GSTLocationComponent } from './components/gstlocation/gstlocation.component';
import { GSTReportComponent } from './components/gstreport/gstreport.component';
import { StationviewComponent } from './components/stationview/stationview.component';
import { ChargerconfigComponent } from './components/chargerconfig/chargerconfig.component';
import { NetworkComponent } from "./components/network/network.component";
import { AddScreenComponent } from './components/add-screen/add-screen.component';
import { HousingSocietyComponent } from './components/housing-society/housing-society.component';
import { NetworkbreakdownComponent } from './components/network-breakdown/network-breakdown.component';
import { HoursOfDayComponent } from './components/hours-of-day/hours-of-day.component';
import { SmartchargingComponent } from './components/smartcharging/smartcharging.component';
import { UserSessionsComponent } from './components/user-sessions/user-sessions.component';
import { CommunityComponentComponent } from './components/community-component/community-component.component';
import { ManageAdvertisingComponent } from './components/manage-advertising/manage-advertising.component';
import { AdGroupComponent } from './components/ad-group/ad-group.component';
import { AdProgramComponent } from './components/ad-program/ad-program.component';


const appRoutes: Routes = [
  //{path: '', component: HomeComponent, canActivate: [AuthGuard]},
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "login", component: LoginComponent },
  { path: "cn", component: CnComponent },
  { path: "forgotpassword", component: ForgotpasswordComponent },
  { path: "reset-password", component: ResetpasswordComponent },
  
  {
    path: "main",
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: DashboardComponent, canActivate: [AuthGuard] },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      { path: "user", component: UserlistComponent, canActivate: [AuthGuard] },
      { path: "role", component: UserroleComponent, canActivate: [AuthGuard] },
      {
        path: "changepassword",
        component: ChangepasswordComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "profile",
        component: UserprofileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "stations",
        component: StationsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "operators",
        component: OperatorsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "managecharger",
        component: ManageChargerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "chargerconfig",
        component: ChargerconfigComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "managerfid",
        component: RfidComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "managediscount",
        component: GendiscountComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "managetariff",
        component: SubscriptionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "tariffrules",
        component: TariffpolicyComponent,
        canActivate: [AuthGuard]
      },

      {
        path: "managefleet",
        component: CommunityComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "chargingslots",
        component: ChargingSlotsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "transdetails",
        component: FTransactionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "ocpiapis",
        component: OcpiapisComponent,
        canActivate: [AuthGuard]
      },
     { path: "gstlocation",
      component: GSTLocationComponent,
      canActivate: [AuthGuard]
    },
      {
        path: "emsp",
        component: EmspComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "connectivitystatus",
        component: ConnectivityStatusReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "revenuereport",
        component: RevenueReportComponent,
        canActivate: [AuthGuard]
      },
      { path: "networkbreakdown",
       component: NetworkbreakdownComponent,
       canActivate: [AuthGuard]},
      {
        path: "gstreport",
        component: GSTReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "stationview",
        component: StationviewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "chargeraction",
        component: ChargeractionComponent,
        canActivate: [AuthGuard]
      },
      {
       path: "activebooking",
       component: ActivebookingComponent,
        canActivate: [AuthGuard]
      },
      { path: "user", component: UserlistComponent, canActivate: [AuthGuard] },
      { path: "role", component: UserroleComponent, canActivate: [AuthGuard] },
      { path: "AppUser", component: AppUserComponent, canActivate: [AuthGuard] },
      { path: "logs", component: LogComponent, canActivate: [AuthGuard] },
      { path: "network", component: NetworkComponent,canActivate: [AuthGuard]},
      { path: "HousingSociety", component: HousingSocietyComponent,canActivate: [AuthGuard]},
      { path: "userSessions", component: UserSessionsComponent,canActivate: [AuthGuard]},
      { path: "HoursOfDay", component: HoursOfDayComponent,canActivate: [AuthGuard]},
      { path: "community", component: CommunityComponentComponent,canActivate: [AuthGuard]},
      { path: "AddScreen", component: AddScreenComponent,canActivate: [AuthGuard]},
      { path: "smartCharging", component: SmartchargingComponent,canActivate: [AuthGuard]},
      { path: "alarms", component: AlarmsComponent, canActivate: [AuthGuard] },
      { path: "NewCharger", component: OcppNewChargerComponent, canActivate: [AuthGuard] },
      { path: "serverStatus", component:OcppServerStatusComponent, canActivate: [AuthGuard] },
      { path: "ManageAdvertising", component: ManageAdvertisingComponent,canActivate: [AuthGuard]},
      { path: "AdProgram", component: AdProgramComponent,canActivate: [AuthGuard]},
      { path: "AdGroup", component: AdGroupComponent,canActivate: [AuthGuard]}
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    UserlistComponent,
    GridtableComponent,
    HomeComponent,
    PageheaderComponent,
    UserroleComponent,
    NgYesNoModalContent,
    NgInformationModalContent,
    ConfirmModalComponent,
    ConfirmTemplateDirective,
    MainComponent,
    DashboardComponent,
    TopbarComponent,
    ObjectArraySort,
    KnobComponentDirective,
    UserprofileComponent,
    LogComponent,
    ResetpasswordComponent,
    StationsComponent,
    OperatorsComponent,
    OperatorDetailComponent,
    CityComponent,
    ManageChargerComponent,
    ChargerComponent,
    ChangepasswordComponent,
    ForgotpasswordComponent,
    ConnectivityStatusReportComponent,
    RevenueReportComponent,
    ChargeractionComponent,
    CnComponent,
    ActivebookingComponent,
    TopTextComponent,
    AppUserComponent,
    OcppNewChargerComponent,
    OcppServerStatusComponent,
    RfidComponent,
    CommunityComponent,
    FTransactionsComponent,
    ChargingSlotsComponent,
    AlarmsComponent,
    GendiscountComponent,
    SubscriptionComponent,
    TariffpolicyComponent,
    CorporatedetailComponent,
    EmspComponent,
    OcpiapisComponent,
    GSTLocationComponent,
    GSTReportComponent,
    StationviewComponent,
    ChargerconfigComponent,
    NetworkComponent,
    AddScreenComponent,
    HousingSocietyComponent,
    NetworkbreakdownComponent,
    HoursOfDayComponent,
    SmartchargingComponent,
    UserSessionsComponent,
    CommunityComponentComponent,
    ManageAdvertisingComponent,
    AdGroupComponent,
    AdProgramComponent
  ],
  imports: [
    NgbModalModule,
    BrowserModule,
    ChartsModule,
    ChartModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    CustomFormsModule,
    ReactiveFormsModule,
    //HttpModule,
    HttpClientModule,
    JwtModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    CdkTableModule,
    CdkTreeModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBA9AHukZCsxCapvUMUU2gwXXSUYZJbGUI"
    }),
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      preloadingStrategy: AppSettings
    })
  ],
  entryComponents: [
    NgYesNoModalContent,
    NgInformationModalContent,
    UserlistComponent,
    ConfirmModalComponent
  ],

  providers: [
    AuthGuard,
    AuthService,
    UserService,
    ProfileOcppService,
    global,
    StationsService,
    StationsService,
    Tools,
    MenuService,
    ConfirmService,
    ConfirmState,
    OperatorService,
    CityService,
    ManageChargerService,
    ChargerService,
    XhrInterceptorService,
    ImageconfigService,
    OCPPStatusMasterService,
    ChargerActionQueueService,
    ActivebookingService,
    FTransactionsService,
    ChargingConfigService,
    AppUserService,
    CommunityService,
    EmspService,
    AppSettings, //JwtHelper,
    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XhrInterceptorService,
      multi: true
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appSettingFactory,
      deps: [AppSettings],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
export function appSettingFactory(provider: AppSettings) {
  return () => provider.load();
}
