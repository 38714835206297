import { Component, OnInit } from '@angular/core';
import { TopbarText } from "../../components/topbar/topbar.component";
import { Tools } from "../../modules/tools";
export class searchField {
  startdate?: Date;
  enddate?: Date;
  interval?:number;
}

@Component({
  selector: 'app-charging-slots',
  templateUrl: './charging-slots.component.html',
  styleUrls: ['./charging-slots.component.css']
})
export class ChargingSlotsComponent implements OnInit {
  _menuId: string;
  dateNow: Date = new Date();
  topbartext: TopbarText[] = [{ key: "chargingslots", displayText: "Generate Slots" }];
  searchField: searchField;
  slotsArray = [];
  constructor(
    public tools: Tools,
  ) {
    this.searchField = new searchField();
    this._menuId = this.tools.getMenuName("main/chargingslots");
    this.searchField.interval=30;
     this.searchField.startdate=this.dateNow;
     this.searchField.enddate=this.dateNow;
   }

  ngOnInit() {
  }

  generateSlots(){

    console.log(this.searchField.startdate);
    console.log(this.searchField.enddate);

  }

}
