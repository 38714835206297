export class ProfileOcppModel {
    model?:string;
    vendor?:string ;
    chargepointrserialnumber?:string;
    chargeboxserialnumber?:string;
    firmwareVersion?:string;
    iccId?:string;
    imsi?:string;
    metertype?:string;
 meterSerialNumber:string;
 updatetime:string;
	
}