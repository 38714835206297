import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ImageconfigService } from "../../services/imageconfig.service";
import { MenuService } from "../../services/menu.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { AppSettings } from "src/app/config/AppSettings";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  username: { type: String };
  password: { type: String };
  userId: String = "";
  hideinvlidloginmsg: boolean = true;
  loginerrormsg: string = "";
  forgeterrormsg: string = "";
  closeResult: string;
  returntest: string = "";
  private loginfooter: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    public imagePath: ImageconfigService,
    private modalService: NgbModal,
    private menuservice: MenuService,
    private route: ActivatedRoute,
    private appsettings: AppSettings
  ) {
    //sessionStorage.clear();
    this.hideinvlidloginmsg = true;
    this.loginerrormsg = "";
    this.forgeterrormsg = "";
    this.loginfooter = appsettings.get("copyright-text");
  }
  onLoginSubmit() {
    const user = {
      username: this.username,
      password: this.password
    };
    //console.log("start");
    this.authService.authenticateUser(user).subscribe(
      data => {
        // console.log("success");
        if (data.success) {
          //this.authService.storeUserData(data.result.token,data.result.name);
          this.authService.loadPrivileges().then(() => {
            this.menuservice.loadMenus().then(() => {
              this.hideinvlidloginmsg = true;
              this.loginerrormsg = "";
              if (this.returntest == "/main/logs") {
                this.router.navigate(["main/logs"]);
              } else {
                this.router.navigate(["main"]);
              }
            });
          });
          //this.flashmessage.show("You are logged in",{cssClass : "alert-success", timeout : 3000});
        } else {
          //this.flashmessage.show(data.msg,{cssClass : "alert-danger", timeout : 3000});
          this.hideinvlidloginmsg = false;
          if (data.message == "INVALIDUSER") {
            this.loginerrormsg = "User name is not correct!";
          } else if (data.message == "AUTHFAILED") {
            this.loginerrormsg = "Authentication failed!";
          } else {
            this.loginerrormsg = "Error in login!";
          }
          this.router.navigate(["login"]);
        }
      },
      err => {
        this.hideinvlidloginmsg = true;
        this.loginerrormsg = "Error in login!";
      }
    );
  }
  ngOnInit() {
    this.route.queryParams.subscribe(
      params => (this.returntest = params["returnUrl"] || "/forums")
    );
  }

  forgotPasswordClick(content) {
    this.userId = "";
    this.forgeterrormsg = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
          console.log(result);
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onForgotPassword() {
    if (this.userId == "" || this.userId == null) {
      this.hideinvlidloginmsg = false;
      this.forgeterrormsg = "UserId Required";
      return false;
    } else {
      this.authService.forgotpassword(this.userId).subscribe(data => {
        alert("Mail Sent! Check Your Mail");
        this.modalService.dismissAll("Mail Sent");
      });
      this.hideinvlidloginmsg = true;
      this.forgeterrormsg = "";
    }
  }
  isLoginFooter() {
    if (
      this.loginfooter != undefined &&
      this.loginfooter["show-copyright-footer"] != undefined
    ) {
      return this.loginfooter["show-copyright-footer"];
    } else {
      return false;
    }
  }
  getfooterlink(type: string) {
    let link: string = undefined;
    if (this.loginfooter) {
      const loginfootertype = this.loginfooter[type];
      if (loginfootertype) {
        link = loginfootertype["link"];
      }
    }
    return link;
  }

  getfooterlinktext(type: string) {
    let linktext: string = undefined;
    if (this.loginfooter) {
      const loginfootertype = this.loginfooter[type];
      if (loginfootertype) {
        linktext = loginfootertype["text"];
      }
    }
    return linktext;
  }
}
