import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppSettings } from '../config/AppSettings';
import { Tools } from '../modules/tools';
import {GridPage} from '../util/components/gridtable/gridtable.component';
import { AppUserModel } from '../models/appUser.Model';
import { SortClass } from "../modules/util.class";
import { CommunityModel } from '../models/community.model';
import { HoursofdayModel } from '../models/hoursofday.model';
import { Community2Model } from '../models/community2.model';
@Injectable()
export class CommunityService {
  

  constructor(private http: HttpClient, private appsetting : AppSettings,private tools: Tools) { } 
  
  getCommunityList(pageinfo: GridPage, searchField) {
      //  alert("hii");
        let header = new HttpHeaders();
        let httpParam: HttpParams = new HttpParams();
        httpParam = httpParam.append("page", pageinfo.number.toString());
        httpParam = httpParam.append("size", pageinfo.size.toString());
        httpParam = httpParam.append("searchText", searchField.searchText);
        httpParam = httpParam.append("searchStatus", searchField.searchStatus);
        httpParam = httpParam.append("communityType",searchField.communityType.toString())
       // alert("hii");
       
        return this.http
          .get(this.appsetting.get("server-url") + "/api/communitylist", {
            headers: header,
            observe: "body",
            params: httpParam,
            responseType: "json"
          })
          .map(res => res);
      }
      getCommunityLists(pageinfo: GridPage, searchField) {
        //  alert("hii");
          let header = new HttpHeaders();
          let httpParam: HttpParams = new HttpParams();
          httpParam = httpParam.append("page", pageinfo.number.toString());
          httpParam = httpParam.append("size", pageinfo.size.toString());
          httpParam = httpParam.append("searchText", searchField.searchText);
          httpParam = httpParam.append("searchStatus", searchField.searchStatus);
        // httpParam = httpParam.append("communityType",searchField.communityType)
         // alert("hii");
         
          return this.http
            .get(this.appsetting.get("server-url") + "/api/communitylists", {
              headers: header,
              observe: "body",
              params: httpParam,
              responseType: "json"
            })
            .map(res => res);
        }
      getDetail(community: string) {
        let header = new HttpHeaders();
        let param: HttpParams = new HttpParams();
        param = param.append("community", community.toString());
        return this.http
          .get(this.appsetting.get("server-url") + "/api/communitydetail", {
            headers: header,
            observe: "body",
            params: param,
            responseType: "json"
          })
          .map(res => res);
      }
      getFleetuserList(pageinfo: GridPage,corpId:string) {
        //  alert("hii");

          let header = new HttpHeaders();
          let httpParam: HttpParams = new HttpParams();
          httpParam = httpParam.append("page", pageinfo.number.toString());
          httpParam = httpParam.append("size", pageinfo.size.toString());
          httpParam = httpParam.append("corpId", corpId.toString());
          
         // alert("hii");
         
          return this.http
            .get(this.appsetting.get("server-url") + "/api/fleetusers", {
              headers: header,
              observe: "body",
              params: httpParam,
              responseType: "json"
            })
            .map(res => res);
        }
      save(community:CommunityModel,requestType:String) {
        if(requestType==="new"){
          let header = new HttpHeaders();
         
        console.log(community);
        return this.http
          .put(this.appsetting.get("server-url") + "/api/newcommunity",community, {
            headers: header
          })
          .map(res => res);

        }
        else{
          let header = new HttpHeaders();
          console.log(community);
          return this.http
            .put(this.appsetting.get("server-url") + "/api/community",community, {
              headers: header
            })
            .map(res => res);
  
        }
       }
       allFleetlist(type:string) {
        let header = new HttpHeaders();
        let httpParam : HttpParams=new HttpParams();
       httpParam=httpParam.append("CommunityType", type.toString());
      
          return this.http.get( this.appsetting.get("server-url") + '/api/allFleetlist',{headers : header, observe : "body", params : httpParam,responseType : "json"})
        .map(res=>res);
      }
       saveCommunity(community:Community2Model,requestType:String) {
        console.log(requestType);
        if(requestType==="new"){
          let header = new HttpHeaders();
         
        console.log(community);
        return this.http
          .put(this.appsetting.get("server-url") + "/api/newcommunity",community, {
            headers: header
          })
          .map(res => res);

        }
        else{
          let header = new HttpHeaders();
          console.log(community);
          return this.http
            .put(this.appsetting.get("server-url") + "/api/community",community, {
              headers: header
            })
            .map(res => res);
  
        }
       }
      
  /* deleteUser(username : string) {
      let header = new HttpHeaders();
      let param : HttpParams=new HttpParams();
      param=param.append("username", username);         
      return this.http.post( this.appsetting.get("server-url") + '/api/AppUserDeletion',
        {headers : header,observe : "body", params : param,responseType : "json"})
      .map(res=>res);
    }
    inactiveUser(username : string) {
      let header = new HttpHeaders();
      let param : HttpParams=new HttpParams();
      param=param.append("username", username);     
      let objUser : AppUserModel = new AppUserModel();
      objUser.username=username;
      return this.http.get(this.appsetting.get("server-url") + "/api/AppUserDeletionbyname", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res=>res);
    }

    approveRFIDRequest(username: string){
      let header = new HttpHeaders();
      let param: HttpParams = new HttpParams();
      param = param.append("username", username.toString());
      return this.http.get(this.appsetting.get("server-url") + "/api/approverfidrequest", {
          headers: header,
          observe: "body",
          params: param,
          responseType: "json"
        })
        .map(res => res);

    }*/

    getAllCommunity() {
      let header = new HttpHeaders();
      let httpParam : HttpParams=new HttpParams();
    //  httpParam=httpParam.append("size",pageinfo.size.toString());
     //httpParam=httpParam.append("status",searchField.status);
    // httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    //  httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));    
      //  httpParam = httpParam.append("username", searchField.searchText);
        return this.http.get( this.appsetting.get("server-url") + '/api/allcommunity',{headers : header, observe : "body", params : httpParam,responseType : "json"})
      .map(res=>res);
    }

    getAllTariffs(scope:String) {
      let header = new HttpHeaders();
      let httpParam : HttpParams=new HttpParams();
     httpParam=httpParam.append("scope", scope.toString());
    //  httpParam=httpParam.append("size",pageinfo.size.toString());
     //httpParam=httpParam.append("status",searchField.status);
    // httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    //  httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));    
      //  httpParam = httpParam.append("username", searchField.searchText);
        return this.http.get( this.appsetting.get("server-url") + '/api/alltariffrules',{headers : header, observe : "body", params : httpParam,responseType : "json"})
      .map(res=>res);
    }

    verifyFleetUser(username:number){
      let header = new HttpHeaders();
      let param: HttpParams = new HttpParams();
      param = param.append("username", username.toString());
      return this.http
        .get(this.appsetting.get("server-url") + "/api/verifyfleetyuser", {
          headers: header,
          observe: "body",
          params: param,
          responseType: "json"
        })
        .map(res => res);
  
    }
  
    fleetStats(searchField:any,communityId:number){
      let header = new HttpHeaders();
      let httpParam: HttpParams = new HttpParams();
      console.log(searchField)
      httpParam = httpParam.append("community",communityId.toString());
      httpParam = httpParam.append("startTime",this.tools.formatDateToDDMMMYY(searchField.startdate) );
      httpParam = httpParam.append("stopTime",this.tools.formatDateToDDMMMYY (searchField.enddate));
      
     // alert("hii");
     
      return this.http
        .get(this.appsetting.get("server-url") + "/api/fleetstats", {
          headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json"
        })
        .map(res => res);
    }
    get() {
      let header = new HttpHeaders();
      let httpParam : HttpParams=new HttpParams();
    //  httpParam=httpParam.append("size",pageinfo.size.toString());
     //httpParam=httpParam.append("status",searchField.status);
    // httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    //  httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));    
      //  httpParam = httpParam.append("username", searchField.searchText);
        return this.http.get( this.appsetting.get("server-url") + '/api/allcommunity',{headers : header, observe : "body", params : httpParam,responseType : "json"})
      .map(res=>res);
    }
   saveTimePeriod(tp:HoursofdayModel,requestType:String) {
      if(requestType==="new"){
        let header = new HttpHeaders();
      console.log(tp);
      return this.http
        .put(this.appsetting.get("server-url") + "/api/addtp",tp, {
          headers: header
        })
        .map(res => res);

      }
    }
      getHoursOfDayList(pageinfo: GridPage,searchField) {
        //  alert("hii");

          let header = new HttpHeaders();
          let httpParam: HttpParams = new HttpParams();
          httpParam = httpParam.append("page", pageinfo.number.toString());
          httpParam = httpParam.append("size", pageinfo.size.toString());
          httpParam = httpParam.append("searchText", searchField.searchText);
          
         // alert("hii");
         
          return this.http
            .get(this.appsetting.get("server-url") + "/api/tplist", {
              headers: header,
              observe: "body",
              params: httpParam,
              responseType: "json"
            })
            .map(res => res);
        }
        getTpDetail(tp: string) {
          let header = new HttpHeaders();
          let param: HttpParams = new HttpParams();
          param = param.append("tp", tp.toString());
          return this.http
            .get(this.appsetting.get("server-url") + "/api/tpdetail", {
              headers: header,
              observe: "body",
              params: param,
              responseType: "json"
            })
            .map(res => res);
        }
        deleteCommunity(communityId: number) {
          let header = new HttpHeaders();
          let param: HttpParams = new HttpParams();
          param = param.append("communityId", communityId.toString());
          return this.http
            .delete(this.appsetting.get("server-url") + "/api/newcommunity", {
              headers: header,
              observe: "body",
              params: param,
              responseType: "json"
            })
            .map(res => res);
        }
}
