
import { Component, OnInit, ViewChild, Output, Input,EventEmitter, DoCheck } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { __values } from 'tslib';
//import { RfidDetailComponent } from "../rfid-detail/community.component";
import { OperatorModel } from "src/app/models/operator.model";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { DiscountService } from "../../services/discount.service"
import {GridPage,gridColumns,GridtableComponent} from "../../util/components/gridtable/gridtable.component";
import { DiscountModel } from "src/app/models/discount.model";
@Component({
  selector: 'app-gendiscount',
  templateUrl: './gendiscount.component.html',
  styleUrls: ['./gendiscount.component.css']
})
export class GendiscountComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  //@ViewChild(RfidDetailComponent) operatorComp: RfidDetailComponent;
  
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-10"
    },{
      columnHeader: "Code#",
      columnFieldName: "code",
      columnClasses: "c-col-pc-10"
    },
    
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.statusValue
    },
    {
      columnHeader: "Formula",
      columnFieldName: "formula",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.formulaValue
    },
   /* {
      columnHeader: "Type",
      columnFieldName: "type",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.typeValue
    },*/
    {
      columnHeader: "Value",
      columnFieldName: "value",
      columnClasses: "c-col-pc-10",
      
    },
    {
      columnHeader: "Valid From",
      columnFieldName: "validFrom",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Valid Till",
      columnFieldName: "validTill",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "GenDate",
      columnFieldName: "generationTime",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
  ];
  searchField = {
    code:"",
    searchText: "",
    searchDiscount:"",
    type:"F",
    status:"V"
  };
  dateNow: Date = new Date();
  data: DiscountModel;
    _data: DiscountModel;
 
  selectedFiles: FileList;
  currentFileUpload: File;
  selectedFile = null;
  rfidarrlist: Array<any>;
  Value:string;
  progress: { percentage: number } = { percentage: 0 };
  _isSaved: boolean = false;
  datalist: Array<DiscountModel>;
  operator: OperatorModel;
  page: GridPage;
  _action: string;
  isDetailScreen: boolean = false;
  //_disableSaveCancel: boolean = true;
 // isScreen: boolean = true;
  
  _menuId: string;
  topbartext: TopbarText[] = [{ key: "discounts", displayText: "Schemes" }];
 
  constructor( private ngbModalService: NgbModal,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private discountservice: DiscountService,) {  
      this.data = new DiscountModel();
      //this.rfidarrlist = new RFIDModel();
      this.page = new GridPage();
      this._menuId = this.tools.getMenuName("main/managediscount");
      this.getDiscountList();
     // this.searchField.searchText="";
      this.data.validFrom=this.dateNow;
      this.data.validTill=this.dateNow;
    this.Value="Fixed Amount"}

  ngOnInit() {
  }
  getDiscountList() {
   this.discountservice.getDiscountList(this.searchField).subscribe(data => {
       // console.log(this.searchField);
        if (data["success"]) {
          this.datalist = data["result"];
          //console.log(this.datalist);
         /* this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        } else {
          alert("Failed to get rfid list!");
        }*/
      }
      });
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }
  disableSave(f: any) {
    let isDisableSave: boolean = true;
    console.log(f);
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }
  typeValue(type){
    if (type==="G")
   return "Group"
   else if(type==="G")
   return "Individual"
   else return type 
  }

  formulaValue(value){
    if (value==="F")
   return "Fixed"
   else if(value==="P")
   return "Percentage"
   else return value 
  }

  statusValue(status){
    if (status==="V")
   return "Valid"
   else if(status==="I")
   return "InValid"
   else return status 
  }
  onRowDblClick(rfidlistvalue:any) {
    this._action = "update";
    this.isDetailScreen = true;
    //this.isScreen = false;
    //this.router.navigate(['user']);
    this.getDetail(rfidlistvalue.code);
  }


  onBackClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Details has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
    }
  }
  onValueChange(){
    console.log(this.Value)
    if(this.data.formula==="F"){
     this.Value ="Fixed Amount"
    }
    
    else if(this.data.formula==="P"){
      this.Value="Percentage"
    }}
  isModified() {
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.userName != this._data.userName) {
      return true;
    } else if (this.data.value != this._data.value) {
      return true;
    } else if (this.data.type != this._data.type) {
      return true;
    } else if (this.data.validFrom != this._data.validFrom) {
      return true;
    } else if (this.data.validTill != this._data.validTill) {
      return true;
    } 
      return false;
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  async onAddNew() {
    this.data = new DiscountModel();
    this._data = this.tools.copyFullObject(this.data);
    this._action = "new";
    this.data.type = 'I';
    this.data.status = 'V';
    this.data.formula="F";
    this.data.validFrom = this.dateNow;
    this.data.validTill = this.dateNow;
    this.isDetailScreen = true;

  }
  async getDetail(code) {
   if (code) {
      this.discountservice.getDiscount(code).subscribe(data => {
        if (data["success"]) {
          this.data = data["result"];
          this.data.validFrom=new Date(this.data.validFrom);
          this.data.validTill=new Date(this.data.validTill);
          this._data = this.tools.copyFullObject(this.data);
        }
      }
      )}
  }
  
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  saveData() {
    /*if(!this.data.userName){
        this.data.status = "A";
      }
    else {
        this.data.status = "I";
      }
      if(this.data.balanceAmt===null){
        this.data.balanceAmt=0.00;
      }*/
    this.discountservice.saveDiscount(this.data,this._action).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Discount Code saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
            if (result == "ok") {
              if (result == "ok") {
                this.getDiscountList();
                this.isDetailScreen = false;
              }
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } 
      
      else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save Discount Code!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("station not saved");
      }
    });
  }

}

