import { Component, OnInit, ViewChild, Output, Input,EventEmitter, DoCheck } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { AppUserService } from "../../services/appUser.service";
import { Tools } from "../../modules/tools";
import { RfidService } from "../../services/rfid.service";
import { __values } from 'tslib';
//import { RfidDetailComponent } from "../rfid-detail/community.component";
import { OperatorModel } from "src/app/models/operator.model";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { SubscriptionService } from "../../services/subscription.service"
import {GridPage,gridColumns,GridtableComponent} from "../../util/components/gridtable/gridtable.component";
import { CommunityModel } from "src/app/models/community.model";
import { ColdObservable } from "rxjs/internal/testing/ColdObservable";
import { TariffRuleModel } from "src/app/models/tariffrule.model";
import { CommunityService } from "../../services/Community.service";
import { AppUserModel } from "src/app/models/appUser.Model";
import { ThemeService } from "ng2-charts";

export class searchField {
  searchSubscription:"";
  scope:"U";
  status:""
  searchStatus:"";
  searchText: "";
  startdate?: Date;
  enddate?: Date;
 communityType?:"F"
}
export class searchFieldFleetStat {
 
  startdate?: Date;
  enddate?: Date;
}

export class FleetData {
  total_booking?:number;
    completed_booking?:number;
    total_amount?:number;
}
@Component({
  selector: 'app-subscription',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcolss: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4 text-center" },
    
    {
      columnHeader: "User",
      columnFieldName: "username",
      columnClasses: "c-col-pc-20 text-center"
    },
    {
      columnHeader: "First Name",
      columnFieldName: "firstName",
      columnClasses: "c-col-pc-18 text-center"
    },
    {
      columnHeader: "last Name",
      columnFieldName: "lastName",
      columnClasses: "c-col-pc-14 text-center"
    },
    {
      columnHeader: "Email",
      columnFieldName: "email",
      columnClasses: "c-col-pc-24 text-center"
    },
    {
      columnHeader: "Balance",
      columnFieldName: "balanceamount",
      columnClasses: "c-col-pc-16 text-center "
    },
  ];

  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "CorporateId",
      columnFieldName: "communityId",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Corporate Name",
      columnFieldName: "communityName",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Email",
      columnFieldName: "email",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Website",
      columnFieldName: "website",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Mobile",
      columnFieldName: "mobile",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      
    },
    {
      columnHeader: "GSTIN",
      columnFieldName: "gstin",
      columnClasses: "c-col-pc-18",
    },
    {
      columnHeader: "Creation Date",
      columnFieldName: "generationTime",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
  ];
  searchField:searchField = { 
    searchStatus:"",
    searchText: "",
    searchSubscription:"",
    scope:"U",
    status:"",
    communityType:"F"
   
  };

  searchFieldFleetStat:searchFieldFleetStat =new searchFieldFleetStat();
  
  NOB="";
  NOC="";
  TKC="";
  TBA="";
  LRD="";
  community:number;
  startdate:String;
  enddate:string;
  username:number;
  fleetdata:FleetData;
  dateNow: Date = new Date();
  tariffrulelist: Array<TariffRuleModel>;
  tarrifrule:TariffRuleModel;
  data: CommunityModel;
  appUser:AppUserModel
    _data: CommunityModel;
    corporateActive:string;
    bankActive:string;
    financialActive:string;
    userActive:string;
    statsActive:string;
    progress: { percentage: number } = { percentage: 0 };
    _isSaved: boolean = false;
    datalist: Array<CommunityModel>;
    appuserlist: Array<AppUserModel>;
    operator: OperatorModel;
    page: GridPage;
    userpage: GridPage;
    _action: string;
    iscommercialAllowed = false;
    isDetailScreen: boolean = false;
    detailScreenTab = {
      companyprofiletab: true,
      banktab: false,
      financialstab: false
    };
    detailScreenTabHide= {
      companyprofiletab: false,
      banktab: true,
      financialstab: true,
      rfidtab: true,
      buttontab:false,
      statustab:true
    };
    //_disableSaveCancel: boolean = true;
   // isScreen: boolean = true;
    commercialTypes:string;
    _menuId: string;
    topbartext: TopbarText[] = [{ key: "corporates", displayText: "Fleet" }];
   
  constructor(private ngbModalService: NgbModal,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private communityService:CommunityService,
    private rfidservice: RfidService,
    private subscriptionservice: SubscriptionService,
    private appuserService:AppUserService) {
     this.fleetdata = new FleetData();
      this.data = new CommunityModel();
      this.tarrifrule = new TariffRuleModel();
      this.corporateActive = "";
      this.bankActive="";
      this.financialActive="";
      this.searchField.startdate=this.dateNow;
      //this.searchField.enddate=this.dateNow;
      this.searchField.enddate=this.dateNow;
      this.searchFieldFleetStat.startdate=this.dateNow;
      this.searchFieldFleetStat.enddate=this.dateNow;
      this.userActive="";
      this.statsActive="";
      //this.rfidarrlist = new RFIDModel();
      this.page = new GridPage();
      this.page.size = 10; //Default page size
      this.userpage = new GridPage();
      this.userpage.size = 10; //Default page size
      this._menuId = this.tools.getMenuName("main/managefleet");
     // this.searchField.searchText="";
     this.getTariffs();
     this.getCorporateList();
     
     }

  
   isAllowAdd() {
     console.log(this.auth.isPrivExists(this._menuId + "=>add"))
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    
    return isAdd;
  }

  onRowDblClick(listval: any) {
    this.data = listval;
    this._data = this.tools.copyFullObject(this.data);
    this._action = "update";
    this.isDetailScreen = true;
    this.detailScreenTabHide={companyprofiletab: false,
      banktab: true,
      financialstab: true,
      rfidtab:true, 
      buttontab:true,
      statustab:true
    }
      this.corporateActive="tab-active";
      this.bankActive="";
      this.financialActive="";
      this.userActive="";
      this.statsActive="";
      this.getComunityDetail(listval.communityId);
      
  }
  ngOnInit() {
  }

  dataTarrifPlan() {
    if (!this.data.tariffPlan) {
      return undefined;
    } else {
      return this.data.tariffPlan;
    }
   
  }
  activeInactive(event){
    console.log(event.target.value);
    this.searchField.searchStatus=event.target.value;
    this.getCorporateList();
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  };
  
  disableSave(f: any) {
    let isDisableSave: boolean = true;
  //  if(f.valid){
     // console.log(this.isModified());
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
   // }
    return isDisableSave;
  }
  isModified() {
     if (!(this.data && this._data)) {
       return false;
     } else if (this.username != this.username) {
      return true;
    }
     
     else if (this.data.communityName != this._data.communityName) {
       return true;
     } else if (this.data.gstin != this._data.gstin) {
       return true;
     } else if (this.data.address != this._data.address) {
       return true;
     } else if (this.data.email != this._data.email) {
       return true;
     }else if (this.data.status != this._data.status) {
       return true;
     }else if (this.data.mobile != this._data.mobile) {
       return true;
     }else if (this.data.bankName != this._data.bankName) {
       return true;
     }
     else if (this.data.accountNumber != this._data.accountNumber) {
       return true;
     }else if (this.data.category != this._data.category) {
      return true;
    }
    else if (this.tarrifrule.ruleID != this.dataTarrifPlan()) {
       return true;
     }
     
     //else if 
    /// (this.subscription.planId !=this.dataSubscriptionId() ) {
     
    //   return true;
   //  }
     return false;
   }
 gridDeleteButton() {
      let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
      return isdelete;
    }
    
    userRemoveFromFleet(userVal) {
      let objUser: AppUserModel = {};
      objUser = userVal.obj
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to remove user from Fleet?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            objUser.corpId=null;
       this.appuserService.save(objUser,"update").subscribe(data => {
         let ngmodaloptions: NgbModalOptions = {};
         ngmodaloptions.backdrop = "static";
         ngmodaloptions.size = "sm";
         if (data["success"]) {
           this._isSaved = true;
           const modalRef = this.ngbModalService.open(
             NgInformationModalContent,
             ngmodaloptions
           );
           modalRef.componentInstance.title = "Information";
           modalRef.componentInstance.message = "User removed successfully!";
           modalRef.componentInstance.btnCLass = "btn-info";
           
           modalRef.result.then(
             result => {
              console.log(this.data);

              this.getFleetUserList();
              //  this.data = data["result"];
              //  this._data = this.tools.copyFullObject(this.data);
             },
             reason => {
               this.data = data["result"];
               this._data = this.tools.copyFullObject(this.data);
               
             }
           );
            
         } else {
           const modalRef = this.ngbModalService.open(
             NgInformationModalContent,
             ngmodaloptions
           );
           modalRef.componentInstance.title = "Error";
           modalRef.componentInstance.message = "Failed to remove User from Fleet!";
           modalRef.componentInstance.btnCLass = "btn-warning";
           modalRef.result.then(
             result => {},
             reason => {}
           );
           // alert("station not saved");
         }
       });
      }
      });
    }
    saveData() {
    
      console.log (this.tarrifrule);
      if (this.tarrifrule) {
        this.data.tariffPlan= this.tarrifrule.ruleID;
      }else{
        this.data.tariffPlan=null;
      }
      this.data.communityType = "F";
      this.communityService.save(this.data,this._action).subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        if (data["success"]) {
          this._isSaved = true;
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Information";
          modalRef.componentInstance.message = "Saved successfully!";
          modalRef.componentInstance.btnCLass = "btn-info";
          modalRef.result.then(
            result => {
              this.data = data["result"];
              
              this._data = this.tools.copyFullObject(this.data);
              if (result == "ok") {
                //this.getList();
                this.isDetailScreen = false;
              }
            },
            reason => {
              this.data = data["result"];
              this._data = this.tools.copyFullObject(this.data);
            }
          );
        } else {
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message = "Failed to save Fleet!";
          modalRef.componentInstance.btnCLass = "btn-warning";
          modalRef.result.then(
            result => {},
            reason => {}
          );
          // alert("station not saved");
        }
      });
    }

    ontabClick(tabname:string){

     if(tabname==="corporateprofile"){
       console.log(tabname)
      this.detailScreenTabHide={companyprofiletab: false,
        banktab: true,
        financialstab: true,
        rfidtab:true,
        buttontab:true,
        statustab:true }
        this.corporateActive="tab-active"
        this.bankActive=""
        this.financialActive=""
        this.userActive=""
        this.statsActive=""
     } else if(tabname==="corporatebank"){
      console.log(tabname)
      this.detailScreenTabHide={companyprofiletab: true,
        banktab: false,
        financialstab: true,
        rfidtab:true,
        buttontab:true,
        statustab:true
      }
        this.bankActive="tab-active"
        this.corporateActive=""
        this.financialActive=""
        this.userActive=""
        this.statsActive=""
    } else if(tabname==="corporatefinancial"){
      console.log(tabname)
      this.detailScreenTabHide={companyprofiletab: true,
        banktab: true,
        financialstab: false,
        rfidtab:true,
        buttontab:true,
        statustab:true
      }
        this.financialActive="tab-active"
        this.corporateActive=""
        this.bankActive=""
        this.userActive=""
        this.statsActive=""
        
    }
    else if(tabname==="corpusers"){
      console.log(tabname)
      this.detailScreenTabHide={companyprofiletab: true,
        banktab: true,
        financialstab: true,
        rfidtab:false,
        buttontab:true,
        statustab:true
      }
        this.financialActive=""
        this.corporateActive=""
        this.bankActive=""
        this.userActive="tab-active"
        this.statsActive=""
    }
    else if(tabname==="stats"){
     
      console.log(tabname)
      this.detailScreenTabHide={companyprofiletab: true,
        banktab: true,
        financialstab: true,
        rfidtab:true,
        buttontab:true,
        statustab:false
        
      }
        this.financialActive=""
        this.corporateActive=""
        this.bankActive=""
        this.statsActive="tab-active"
        this.userActive=""
    }
   }

   onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Corporate detail has been modified. Are you sure to exit without save?";
       
      ngmodalref.result.then(
        result => {
          this.corporateActive="tab-active";
          this.bankActive=""
          this.financialActive=""
          this.userActive=""
          this.statsActive=""
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;

            if (this._isSaved) {
              this.getCorporateList();
            }
            this._isSaved = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";

      this.isDetailScreen = false;
      if (this._isSaved) {
        this.getCorporateList();
      }
      this._isSaved = false;
    }
  }
    onCloseDetailScreen(isChange: boolean) {
      if (isChange == true) {
       // this.getDatalist();
      }
      this.isDetailScreen = false;
    }

    async onAddNew() {
      this._action = "new";
      this.detailScreenTabHide={companyprofiletab: false,
        banktab: true,
        financialstab: true,
        rfidtab:true,
        buttontab:true,
        statustab:true
      }
        this.corporateActive="tab-active"
        this.bankActive=""
        this.financialActive=""
        this.userActive=""
        this.statsActive=""
      this.data = new CommunityModel;
      this.data.status="A";
      this._data = this.tools.copyFullObject(this.data);
      this.isDetailScreen = true;
    }
    getCorporateList(){
      console.log(this.searchField.searchStatus)
      console.log(this.searchField.communityType)
         this.communityService
           .getCommunityList(this.page, this.searchField)
           .subscribe(data => {
             if (data["success"]) {
               this.datalist = data["result"].content;
               this.page.first = data["result"].first;
               this.page.last = data["result"].last;
               this.page.number = data["result"].number;
               this.page.numberOfElements = data["result"].numberOfElements;
               this.page.offset = data["result"].offset;
               this.page.size = data["result"].size;
               this.page.totalElements = data["result"].totalElements;
               this.page.totalPages = data["result"].totalPages;
               if (this.page.number == 0) {
                 this.gridComp.setPages();
               }
             } else {
               alert("Failed to get CommunityList !");
             }
           });
       }
       getFleetUserList(){
        console.log(this.data)
           this.communityService
             .getFleetuserList(this.userpage, this.data.communityId.toString())
             .subscribe(data => {
               if (data["success"]) {
                 this.appuserlist = data["result"].content;
                 this.userpage.first = data["result"].first;
                 this.userpage.last = data["result"].last;
                 this.userpage.number = data["result"].number;
                 this.userpage.numberOfElements = data["result"].numberOfElements;
                 this.userpage.offset = data["result"].offset;
                 this.userpage.size = data["result"].size;
                 this.userpage.totalElements = data["result"].totalElements;
                 this.userpage.totalPages = data["result"].totalPages;
                 if (this.userpage.number == 0) {
                   this.gridComp.setPages();
                 }
               } else {
                 alert("Failed to get FleetUserList !");
               }
             });
         }
     
    async getTariffs() {

      this.tariffrulelist = undefined;
      await this.communityService
        .getAllTariffs("B")
        .toPromise()
        .then(data => {
          if (data["success"]) {
            let tariffrules: Array<TariffRuleModel> = new Array<TariffRuleModel>();
            let tariffrule: TariffRuleModel = new TariffRuleModel();
            tariffrule.ruleID = undefined;
            tariffrule.name = "-";
            tariffrules.push(tariffrule);
            const datatariffrule: Array<TariffRuleModel> = data["result"];
            console.log(datatariffrule);
            for (let i: number = 0; i < datatariffrule.length; i++) {
              tariffrules.push(datatariffrule[i]);
            }
            this.tariffrulelist = tariffrules;
            console.log(this.tariffrulelist );
            this._data = this.tools.copyFullObject(this.data);
          } else {
            console.error("Error in fetching alll tariffs");
          }
        });
    }
  
    getComunityDetail(communityId:string) {
   //   console.log(userid);
        this.communityService.getDetail(communityId).subscribe(data =>{
        if(data["success"]) {
            const community= data["result"];
           if(community.tariffPlan){
            const subsItemArr = this.tariffrulelist.filter(itm => itm.ruleID==community.tariffPlan);
           this.tarrifrule = subsItemArr[0];
            console.log(subsItemArr)
          }
           this.data = community;
           this.getFleetUserList();
        } else {
        alert("Failed to get community Data!")
        }    
    })

}

verifyUser(){
  this.communityService.verifyFleetUser(this.username).subscribe(data => {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
      if (data["success"]) {

        if(data["message"]==="USERALREADYASSIGNEDTOFLEET"){
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          let rfid:string=data["result"];
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "User aready assigned to fleet";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {

      });
      }     
       else  if(data["message"]==="USERFOUND"){
         this.appUser = data["result"];
        this.detailScreenTabHide.buttontab = false;
        console.log(this.appUser)
       
            }
          }
      else {
        this.detailScreenTabHide.buttontab = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Please enter a Valid user ";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            
    });
      }

    });
}
resetAmount(){
  console.log(this.NOB,this.NOC,this.TKC,this.TBA,this.LRD)
}
onAddFleetButton(){
  console.log (this.tarrifrule);
     this.appUser.corpId=this.data.communityId;
      this.appuserService.save(this.appUser,"update").subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        if (data["success"]) {
          this._isSaved = true;
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Information";
          modalRef.componentInstance.message = "User saved successfully!";
          modalRef.componentInstance.btnCLass = "btn-info";
          this.detailScreenTabHide.buttontab = true;
          modalRef.result.then(
            result => {
              this.getFleetUserList();
            },
            reason => {
              this._data = this.tools.copyFullObject(this.data);
              
            }
          );
        } else {
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message = "Failed to save User!";
          modalRef.componentInstance.btnCLass = "btn-warning";
          modalRef.result.then(
            result => {},
            reason => {}
          );
          // alert("station not saved");
        }
      });
    
}

async getFleetStatsData(){
 
  console.log(this.community)
  this.communityService.fleetStats(this.searchFieldFleetStat,this.data.communityId).subscribe(data => {
    if (data["success"]) {
     this.fleetdata = data["result"];
    } else {
      alert("Failed!");}
  });
  console.log(this.fleetdata)
}

}
