import { Component, OnInit,ViewChild } from '@angular/core';
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { Tools } from "../../modules/tools";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { StationModel } from "src/app/models/station.model";
import { ChargerService } from "src/app/services/charger.service";
import { StationsService } from "src/app/services/Stations.Service";
import { OperatorModel } from "src/app/models/operator.model";
import { OperatorService } from "src/app/services/operator.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { LogsService } from "../../services/logs.service";
import { Router,ActivatedRoute } from "@angular/router";
export class searchField {
  bookingId?: number;
  chargerId?: string;
  stationId?: number;
  startdate?: Date;
  enddate?: Date;
  api?:string;
}
@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.css']
})

export class AlarmsComponent implements OnInit {
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "TimeStamp",
      columnFieldName: "event_ts",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Charger",
      columnFieldName: "cpIdentity",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "API",
      columnFieldName: "eventName",
      columnClasses: "c-col-pc-8"
    },
   
    {
      columnHeader: "Payload",
      columnFieldName: "eventParameters",
      columnClasses: "c-col-pc-45"
    },
  ];
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  logs: Array<object>;
  page: GridPage;
  _menuId: string;
  error: any = { isError: false, errorMessage: "" };
  isValidDate: any;
  dateNow: Date = new Date();
  searchField: searchField;
  stationlist: Array<StationModel>;
  station: StationModel;
  operator: OperatorModel;
  operatorlist: Array<OperatorModel>;
  chargerslist: Array<ManageChargerModel>;
 

  topbartext: TopbarText[] = [{ key: "alarms", displayText: "Alarms" }];
  constructor(  private router: Router,
    public tools: Tools,
    private objGlobal: global,
    private objLogService: LogsService,
    public chargerservice: ChargerService,
    private stationservice: StationsService,
    private operatorservice: OperatorService,
    private activatedRoute:ActivatedRoute,
    private managechargerservice: ManageChargerService,) { 
      this.page = new GridPage();
      this.page.size = 10; //Default page size
      this.searchField = new searchField();
     if(this.activatedRoute.snapshot.paramMap.get('chargerId')!=null){
      this.searchField.chargerId=this.activatedRoute.snapshot.paramMap.get('chargerId');
     }else{
     this.searchField.chargerId="All";
     }
     this.searchField.api="All";
     this.searchField.startdate=this.dateNow;
     this.searchField.enddate=this.dateNow;
     this._menuId = this.tools.getMenuName(
      this.router.url.substring(1, this.router.url.length)
     );
     // this._menuId = this.tools.getMenuName("main/alarms");
     // console.log(this._menuId);
      //this.objGlobal.setActiveMenu(this._menuId);
      //this.getLogs();
    
     // this.getChargers();
      //this.getData();
    }

  ngOnInit() {
  }
  getChargersList() {
    return this.chargerslist;
    
  }
  getAlarms() {
    
  }
  export(){
    
  }
}
