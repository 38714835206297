import { Component, OnInit,ViewChild } from '@angular/core';
import { CommunityService } from "../../services/Community.service";
import { Community2Model } from "src/app/models/community2.model";
import { TopbarText } from "../topbar/topbar.component";
import { global } from "../../modules/global";
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import {FTransactionsService } from "src/app/services/ftransactions.service";
import { FTransactionsModel } from "../../models/ftransactions.model";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
export class searchField {
  searchSubscription:"";
  scope:"U";
  status:""
  searchStatus:"";
  searchText: "";
  startdate?: Date;
  enddate?: Date;
 
}
@Component({
  selector: 'app-community-component',
  templateUrl: './community-component.component.html',
  styleUrls: ['./community-component.component.css']
})
export class CommunityComponentComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-4" },
    {
      columnHeader: "Community Type",
      columnFieldName: "communityType",
      columnClasses: "c-col-pc-30"
    },
    {
      columnHeader: "Community Name",
      columnFieldName: "communityName",
      columnClasses: "c-col-pc-30"
    },
    {
      columnHeader: "Description",
      columnFieldName: "description",
      columnClasses: "c-col-pc-30 "
    }
    
  ];
  searchField:searchField = { 
    searchStatus:"",
    searchText: "",
    searchSubscription:"",
    scope:"U",
    status:"",
    
   
  };
  datalist: Array<FTransactionsModel>;
  paymentData:Array<FTransactionsModel>;
  _isSaved: boolean = false;
  isDetailScreen: boolean = false;
  isChargerScreen:boolean =false;
  isOperatorScreen:boolean =false;
  _data: Community2Model;
  data: Community2Model;
  _action: string;
  //data: EveryTransactionModel;
  page: GridPage;
  _menuId: string;
  dateNow: Date = new Date();
  topbartext: TopbarText[] = [{ key: "API", displayText: "Community" }];
  //searchField: searchField;
  constructor(
    private ngbModalService: NgbModal,
    private communityService:CommunityService,
    public ftransactionService: FTransactionsService,
    public tools: Tools,
    public objGlobal: global,
    public auth: AuthService,
  ) {
    this.data = new Community2Model();
    
    //this.data = new EveryTransactionModel();
   /* this.searchField = new searchField();
   this.searchField.status="I";
    this.searchField.startdate=this.dateNow;
    this.searchField.enddate=this.dateNow;*/
    this.page = new GridPage();
    this._menuId = this.tools.getMenuName("main/community");
    this.objGlobal.setActiveMenu(this._menuId);
    
    this.page.size = 10; //Default page size
    //this.getTransactionsData() ;
   }
  
  ngOnInit() {
  }
  showAddCommunity(){
    this._action = "new";
    this.isDetailScreen = true;
    this.isOperatorScreen = false;
    this.isChargerScreen = true; 
    this.data = new Community2Model;
    this._data = this.tools.copyFullObject(this.data);
  }
  hideOperatorScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (this.isChargerScreen && !this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  hideCommunityScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (this.isChargerScreen && !this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  gridDeleteButton() {
    let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
    return isdelete;
  }
  deleteCommunity(recObj: any) {
    const objCommunity: Community2Model = recObj.obj;
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to delete Community?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          //console.log("test delete user");
          this.communityService
            .deleteCommunity(objCommunity.communityId)
            .subscribe(data => {
              if (data["success"]) {
                const modalRefOk = this.ngbModalService.open(
                  NgInformationModalContent,
                  ngmodaloptions
                );
                modalRefOk.componentInstance.title = "Information";
                modalRefOk.componentInstance.message =
                  "Community delete successfully!";
                modalRefOk.componentInstance.btnCLass = "btn-info";
                modalRefOk.result.then(
                  result => {
                    this.getCorporateList();
                  },
                  reason => {
                    this.getCorporateList();
                  }
                );
              } else {
                alert("Failed to delete Community!");
              }
            });
        }
      },
      reason => {}
    );
  }
  onBackClick(){
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Corporate detail has been modified. Are you sure to exit without save?";
       
      ngmodalref.result.then(
        result => {
          
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
            if (this._isSaved) {
              this.getCorporateList();
            }
            this._isSaved = false;
           
            
          }
        },
        reason => {}
      );
    } else {
      this._action = "";

      this.isDetailScreen = false;
      if (this._isSaved) {
        this.getCorporateList();
      }
      this._isSaved = false;
     
    }
    
  }
  saveData(){
    console.log(this.data)
    this.communityService.saveCommunity(this.data,this._action).subscribe (data => {
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    if (data["success"]) {
      this._isSaved = true;
      const modalRef = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRef.componentInstance.title = "Information";
      modalRef.componentInstance.message = "Saved successfully!";
      modalRef.componentInstance.btnCLass = "btn-info";
      modalRef.result.then(
        result => {
          this.data = data["result"];
          
          this._data = this.tools.copyFullObject(this.data);
          if (result == "ok") {
            //this.getList();
            this.isDetailScreen = false;
          }
        },
        reason => {
          this.data = data["result"];
          this._data = this.tools.copyFullObject(this.data);
        }
      );
    } else {
      const modalRef = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRef.componentInstance.title = "Error";
      modalRef.componentInstance.message = "Failed to save Community!";
      modalRef.componentInstance.btnCLass = "btn-warning";
      modalRef.result.then(
        result => {},
        reason => {}
      );
      // alert("station not saved");
    }})
  }
  getCorporateList(){
    console.log(this.searchField.searchStatus)
   
       this.communityService
         .getCommunityLists(this.page, this.searchField)
         .subscribe(data => {
           if (data["success"]) {
             this.datalist = data["result"].content;
             this.page.first = data["result"].first;
             this.page.last = data["result"].last;
             this.page.number = data["result"].number;
             this.page.numberOfElements = data["result"].numberOfElements;
             this.page.offset = data["result"].offset;
             this.page.size = data["result"].size;
             this.page.totalElements = data["result"].totalElements;
             this.page.totalPages = data["result"].totalPages;
             if (this.page.number == 0) {
               this.gridComp.setPages();
             }
           } else {
             alert("Failed to get CommunityList !");
           }
         });
     }
     isModified() {
      if (!(this.data && this._data)) {
        return false;
      } 
      
      else if (this.data.communityName != this._data.communityName) {
        return true;
      }
      else if (this.data.communityType != this._data.communityType) {
        return true;
      }
      else if (this.data.description != this._data.description) {
        return true;
      }
      return false;
    }
    onRowDblClick(listval: any) {
      this.data = listval;
      this._data = this.tools.copyFullObject(this.data);
      this._action = "update";
      this.isDetailScreen = true;
      this.isOperatorScreen = false;
      this.isChargerScreen = true; 
      this.getComunityDetail(listval.communityId);
        
        
    }
    activeInactive(event){
      console.log(event.target.value);
      this.searchField.searchStatus=event.target.value;
      this.getCorporateList();
    }
    isAllowAddorEdit() {
      let isAllowSave = false;
      if (this._action == "new") {
        isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
      } else if (this._action == "update") {
        isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
      }
      return isAllowSave;
    };
    
    disableSave(f: any) {
      let isDisableSave: boolean = true;
    //  if(f.valid){
       // console.log(this.isModified());
        if (this.isModified()) {
          if (this.isAllowAddorEdit()) {
            isDisableSave = false;
          }
        }
     // }
      return isDisableSave;
    }
   
 
     
      getComunityDetail(communityId:string) {
     //   console.log(userid);
          this.communityService.getDetail(communityId).subscribe(data =>{
          if(data["success"]) {
             
          } else {
          alert("Failed to get community Data!")
          }    
      })
  
  }
  
 
 
        
 
}
