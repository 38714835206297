import { Component, OnInit ,ViewChild} from '@angular/core';
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { HttpClient } from "@angular/common/http";
import { AppUserService } from "../../services/appUser.service";
import { Tools } from "../../modules/tools";
import {Router} from '@angular/router';
import { UserComponent } from "../user/user.component";
import { from } from "rxjs";
import { GroupAddingModel } from "src/app/models/groupadding.model";
import { GSTLocationService } from "src/app/services/gstlocation.service";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { StationsService } from "src/app/services/Stations.Service";
import { StationModel } from "src/app/models/station.model";
import {UserModel} from '../../models/user.model';
import {UserService} from '../../services/user.service';
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { AppUserModel } from "src/app/models/appUser.Model";
import { OperatorModel } from "src/app/models/operator.model";
import { TariffRuleService } from "src/app/services/tariffrule.service";
import { OperatorService } from "src/app/services/operator.service";
import { TariffRuleModel } from "src/app/models/tariffrule.model";
import { CommunityModel } from "src/app/models/community.model";
import { CommunityService } from "src/app/services/Community.service";
import { __values } from 'tslib';

export class BalanceDetails {
  updatedAmt?: string;
  updatedBy?: string;
  updatedFrom?: string;
  updatesTS?: string;
  
}

@Component({
  selector: 'app-app-user',
  templateUrl: './app-user.component.html',
  styleUrls: ['./app-user.component.css']
})
export class AppUserComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  topbartext: TopbarText[] = [
    { key: "AppUser", displayText: " App User" }
  ];
  groupList = [];
  GroupAddinglist=[];
  selectedGroups = [];
  dropdownSettings= {};
  dropdownSettingss= {};
   showstats:boolean=false;
    data: AppUserModel;
    _data: AppUserModel;
    tariffrulelist: Array<TariffRuleModel>;
    communitylist: Array<CommunityModel>;
    community: CommunityModel;
    tarrifrule:TariffRuleModel;
    multiBooking:string;
    multiBookingEvent:boolean = false;
    _isSaved: boolean = false;
    userOperator:number=undefined;
    userStation:number=undefined;
    page: GridPage;
    _menuId: string;
    datalist: Array<AppUserModel>;
    balanceData:BalanceDetails;
    balancedatalist:Array<BalanceDetails>;
    _action: string;
    searchField = {
      searchText: "",
      searchStatus:"A"
    };
    showBalanceModal:boolean=false;
    balanceUpdate :number; 
    isDetailScreen: boolean = false;
    isRFIDRequested: boolean = false;
    _disableSaveCancel: boolean = true;
    isStationScreen: boolean = false;
    isOperatorScreen: boolean = false;
    isChargerScreen: boolean = false;
    _chargeraction: string = "";
    _stationParam: any;
    
    //For role list
    gridcols: gridColumns[] = [
      { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3"},
      {
        columnHeader: "UserName",
        columnFieldName: "username",
        columnClasses: "c-col-pc-11"
      },
      {
        columnHeader: "Name ",
        columnFieldName: "firstName",
        columnClasses: "c-col-pc-10"
      },
     
      {
        columnHeader: "Email",
        columnFieldName: "email",
        columnClasses: "c-col-pc-18"
      },
      {
        columnHeader: "Status",
        columnFieldName: "status",
        columnClasses: "c-col-pc-7",
        callbackFunction: this.statusValue
      },
      {
        columnHeader: "GST",
        columnFieldName: "pGST",
        columnClasses: "c-col-pc-8"
      },
      {
        columnHeader: "Date",
        columnFieldName: "updatedTS",
        columnClasses: "c-col-pc-12",
        
      },
    
      {
        columnHeader: "RFID",
        columnFieldName: "rfid",
        columnClasses: "c-col-pc-14"
      },
      {
        columnHeader: "Subscription",
        columnFieldName: "subscription.planName",
        columnClasses: "c-col-pc-14"
      },
     
    ];
    //For balance updated list
    balancegridcols: gridColumns[] = [
      { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3"},
      {
        columnHeader: "Amount",
        columnFieldName: "updatedAmt",
        columnClasses: "c-col-pc-15",
        callbackFunction: this.formatAmount
      },
      {
        columnHeader: "UpdatedBy",
        columnFieldName: "updatedBy",
        columnClasses: "c-col-pc-10"
      },
      {
        columnHeader: "Source",
        columnFieldName: "updatedFrom",
        columnClasses: "c-col-pc-10"
      },
      {
        columnHeader: "UpdateTime",
        columnFieldName: "updatedTS",
        columnClasses: "c-col-pc-20",
        callbackFunction: this.tools.defaultdateTimeFormat
      }
    ];
    stationlist: Array<StationModel>;
    station: StationModel;
    operator: OperatorModel;
    operatorlist: Array<OperatorModel>;
    constructor(
      private objuserservice : UserService,
      private tools: Tools,
      private objGlobal: global,
      private auth: AuthService,
      private appuserService:AppUserService,
      private communityService:CommunityService,
      private tariffruleService:TariffRuleService,
      private ngbModalService: NgbModal,
      private operatorservice: OperatorService,
      private httpClient: HttpClient,
      private stationservice: StationsService,
      private gstlocationservice: GSTLocationService,
    ) {
      this.page = new GridPage();
      this.page.size = 10; //Default page size
      this._stationParam = { source: "sAppUsertation" };
      this._menuId = this.tools.getMenuName("main/AppUser");
      this.httpClient.get("assets/config/groups.json").subscribe(conf =>{
      // console.log(conf["Groups"]);
       this.groupList=conf["Groups"];
        })
        this.searchField.searchStatus="A";
       // 
        this.station = new StationModel();
        this.tarrifrule = new TariffRuleModel();
        this.community = new CommunityModel();
        this.balanceUpdate=0;
      this.objGlobal.setActiveMenu(this._menuId);
      this.getList();
      this.data = new AppUserModel();
      this.operator = new OperatorModel();
      this.getOperators();
      
      this.getTariffs();
      this.getStations();
      this.getUserDetail();
      this.getGroupAdding();
    }
  
    ngOnInit() {
      this.dropdownSettings = { 
        singleSelection: false,
        idField: 'roleId',
        textField: 'roleName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        showCheckbox : true,
        enableCheckAll:true,
        enableFilterSelectAll:true,
        filterSelectAllText: "All roles selected",
        maxHeight : 200
        }       
    this.selectedGroups=undefined;
    this.dropdownSettingss = { 
      singleSelection: false,
      idField: undefined,
      textField: undefined,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showCheckbox : true,
      enableCheckAll:true,
      enableFilterSelectAll:true,
      filterSelectAllText: "All roles selected",
      maxHeight : 200
      }       
    }

    onBalanceUpdate(){
      if(this.balanceUpdate!==0 && this.data.balanceamount){
        this.data.balanceamount = Number(this.data.balanceamount)+Number(this.balanceUpdate); 
      }
      if(!this.data.balanceamount){
        this.data.balanceamount = Number(this.balanceUpdate);
      }
    }

    onChargerBackClick(obj) {
      if (obj.isModified == true) {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        const ngmodalref = this.ngbModalService.open(
          NgYesNoModalContent,
          ngmodaloptions
        );
        ngmodalref.componentInstance.title = "Confirmation";
        ngmodalref.componentInstance.message =
          "Are you sure to exit without save?";
        ngmodalref.result.then(
          result => {
            if (result == "yes") {
              this.isChargerScreen = false;
              this.topbartext = [{ key: "stations", displayText: "Stations" }];
            }
          },
          reason => {}
        );
      } else {
        this.isChargerScreen = false;
        this.topbartext = [{ key: "stations", displayText: "Stations" }];
      }
    }
statusValue(status){
if(status==="A"){
  return "Active"
}
  else if(status==="I"){
    return "Inactive"
  }
}
    allowPasswordSelect() {
      let result: boolean = true;
      //console.log("Parameter " + this._param);
      if (this._action  != "new") {
        result = false;
      }
      //console.log("Allow station select " + result);
      return result;
    }

    isAllowAddorEdit() {
      let isAllowSave = false;
      if (this._action == "new") {
        isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
      } else if (this._action == "update") {
        isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
      }
      return isAllowSave;
    };
    disableSave(f: any) {
      let isDisableSave: boolean = true;
    //  if(f.valid){
       // console.log(this.isModified());
        if (this.isModified()) {
          if (this.isAllowAddorEdit()) {
            isDisableSave = false;
          }
        }
     // }
      return isDisableSave;
    }
  activeInactive(event){
    //console.log(event.target.value);
    this.searchField.searchStatus=event.target.value;
    this.getList();
  }

  multiBookingFun(event:any){
   // console.log(event)
    this.multiBookingEvent = !this.multiBookingEvent;
    if(this.multiBookingEvent){
      this.multiBooking = "Y";
    }else{
      this.multiBooking = "N";
    }
   
    this.data.multiBooking = this.multiBooking;
  }
    deleteUser(recObj: any) {
      const objStation: AppUserModel = recObj.obj;
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to inactive User?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            //console.log("test delete user");
            this.appuserService
              .inactiveUser(objStation.username)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "User marked inactive successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      this.getList();
                    },
                    reason => {
                      this.getList();
                    }
                  );
                } else {
                  alert("Failed to delete station!");
                }
              });
          }
        },
        reason => {}
      );
    }
  
    isAllowAdd() {
      let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
      return isAdd;
    }
  
    getList() {
   //   console.log(this.searchField.searchStatus)
      this.appuserService.getUserList(this.page, this.searchField).subscribe(data => {
          if (data["success"]) {
            this.datalist = data["result"].content;
           // console.log(this.datalist)
            this.page.first = data["result"].first;
            this.page.last = data["result"].last;
            this.page.number = data["result"].number;
            this.page.numberOfElements = data["result"].numberOfElements;
            this.page.offset = data["result"].offset;
            this.page.size = data["result"].size;
            this.page.totalElements = data["result"].totalElements;
            this.page.totalPages = data["result"].totalPages;
            if (this.page.number == 0) {
              this.gridComp.setPages();
            }
          } else {
            alert("Failed to get UserList !");
          }
        });
    }
    getBalanceDetailsList() {
      //   console.log(this.searchField.searchStatus)
         this.appuserService.getBalanceList(this.page, this.data.username).subscribe(data => {
             if (data["success"]) {
               this.balancedatalist = data["result"].content;
               this.page.first = data["result"].first;
               this.page.last = data["result"].last;
               this.page.number = data["result"].number;
               this.page.numberOfElements = data["result"].numberOfElements;
               this.page.offset = data["result"].offset;
               this.page.size = data["result"].size;
               this.page.totalElements = data["result"].totalElements;
               this.page.totalPages = data["result"].totalPages;
               if (this.page.number == 0) {
                 this.gridComp.setPages();
               }
             } else {
               alert("Failed to get UserBalanceDetailList !");
             }
           });
       }
   
    async getTariffs() {

      this.tariffrulelist = undefined;
      await this.tariffruleService.getAllTariffs("U").toPromise().then(data => {
          if (data["success"]) {
            let tariffrules: Array<TariffRuleModel> = new Array<TariffRuleModel>();
            let tariffrule: TariffRuleModel = new TariffRuleModel();
            tariffrule.ruleID = undefined;
            tariffrule.name = "-";
            tariffrules.push(tariffrule);
            const datatariffrule: Array<TariffRuleModel> = data["result"];
            //console.log(datatariffrule);
            for (let i: number = 0; i < datatariffrule.length; i++) {
              tariffrules.push(datatariffrule[i]);
            }
            this.tariffrulelist = tariffrules;
           // console.log(this.tariffrulelist );
            this._data = this.tools.copyFullObject(this.data);
          } else {
            console.error("Error in fetching alll tariffs");
          }
        });
    }
  async getCoprorate() {
      
      this.communitylist = undefined;
      await this.communityService.getAllCommunity().toPromise().then(data => {
          if (data["success"]) {
            
            let communities: Array<CommunityModel> = new Array<CommunityModel>();
            let community: CommunityModel = new CommunityModel();
            community.communityId = undefined;
            community.communityName = "-";
            communities.push(community);
            const datacommunities: Array<CommunityModel> = data["result"];
          //  console.log(datacommunities);
            for (let i: number = 0; i < datacommunities.length; i++) {
              communities.push(datacommunities[i]);
             
            }
           
            this.communitylist = communities;
            
            this._data = this.tools.copyFullObject(this.data);
          } else {
            console.error("Error in fetching alll tariffs");
          }
        });
    }
    formatAmount(val) {
      let value = 0.00
      if(val){
        value =  val.toFixed(2);
      }
     return value
    }
    showBalanceHistory(){
      let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.size = 'lg';
      this.showBalanceModal=true;
      this.getBalanceDetailsList();
    //  console.log(this.keytoUpdate);
    }


    hideBalanceHistory()
    {
      this.showBalanceModal = false;
    }
    

    onBackbuttonClick() {
      if (this.isModified() == true) {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        const ngmodalref = this.ngbModalService.open(
          NgYesNoModalContent,
          ngmodaloptions
        );
        ngmodalref.componentInstance.title = "Confirmation";
        ngmodalref.componentInstance.message =
          "User detail has been modified. Are you sure to exit without save?";
        ngmodalref.result.then(
          result => {
            if (result == "yes") {
              this._action = "";
              this.isDetailScreen = false;
  
              if (this._isSaved) {
                this.getList();
              }
              this._isSaved = false;
            }
          },
          reason => {}
        );
      } else {
        this._action = "";
  
        this.isDetailScreen = false;
        if (this._isSaved) {
          this.getList();
        }
        this._isSaved = false;
      }
    }
    gridDeleteButton() {
      let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
      return isdelete;
    }
    saveOperatorEvent(obj) {
      if (obj.success == true) {
        this.getList();
        this.isOperatorScreen = false;
    
        this.getOperators();
      } /*else {
        this.getList();
        this.isOperatorScreen = true;
      }*/
    }

    async onAddNew() {
      this._action = "new";
      this.data = new AppUserModel;
      this.data.status="A";
      this.data.userGroup = "Public";
      this.data.billing = "Z";
      this.multiBooking = "Y";
      this.selectedGroups=['Public'];
     // console.log(this.data.userGroup);
      this._data = this.tools.copyFullObject(this.data);
      this.isDetailScreen = true;
      this.isOperatorScreen = false;
      this.isChargerScreen = false;
    }

    getUserDetail() {
      let userOperator:number = undefined;
      const userdata = JSON.parse(sessionStorage.getItem("currentUser"));
      let userid:number=userdata.userId;
   //   console.log(userid);
        this.objuserservice.getUserDetail(userid).subscribe(data =>{
        if(data["success"]) {
            const user= data["result"];
           this.userOperator = user.operatorId;
           if(user.tariffPlan){
            const subsItemArr = this.tariffrulelist.filter(itm => itm.ruleID==user.tariffPlan);
           this.tarrifrule = subsItemArr[0];
        //    console.log(subsItemArr)
          }
           
        } else {
        alert("Failed to get user list!")
        }    
    })
    return userOperator;
}
    allowOperatorSelect() {
      let result: boolean = true;
      //const operatorId=12;
      if(this.userOperator!==0 && this.userOperator!==null){
        return false;
      }
      return result;
}     
    
  
    saveData() {
     // console.log(this.data.balanceamount);
      if(this.multiBooking){
      this.data.multiBooking = this.multiBooking;
      }
      if (this.operator && this.operator.operatorId) {
        this.data.operatorId = this.operator.operatorId;
      }else{
        this.data.operatorId=null;
      }
     // console.log (this.tarrifrule);
      if (this.tarrifrule) {
        this.data.tariffPlan= this.tarrifrule.ruleID;
      }else{
        this.data.tariffPlan=null;
      }


      if (this.station && this.station.stationId) {
        this.data.preferredPvtStation = this.station.stationId.toString();
      }else{
        this.data.preferredPvtStation=null;
      }
      this.data.updatedBy = this.objGlobal.Auth.user;
      //console.log(this.data.preferredPvtStation);
     // console.log(this.data.balanceamount);
      this.appuserService.save(this.data,this._action).subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        if (data["success"]) {
          this._isSaved = true;
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Information";
          modalRef.componentInstance.message = "User saved successfully!";
          modalRef.componentInstance.btnCLass = "btn-info";
          modalRef.result.then(
            result => {
              this.data = data["result"];
              if (this.data && this.data.operatorId) {
                this.operator.operatorId = this.data.operatorId;
              } else {
                this.operator = new OperatorModel();
              }
              if (this.data && this.data.preferredPvtStation) {
                this.station.stationId=Number(this.data.preferredPvtStation);
              } else {
                this.station = new StationModel();
              }
              
              this._data = this.tools.copyFullObject(this.data);
              if (result == "ok") {
                this.getList();
                this.balanceUpdate=0;
                this.isDetailScreen = false;
              }
            },
            reason => {
              this.data = data["result"];
              this._data = this.tools.copyFullObject(this.data);
            }
          );
        } else {
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message = "Failed to save User!";
          modalRef.componentInstance.btnCLass = "btn-warning";
          modalRef.result.then(
            result => {},
            reason => {}
          );
          // alert("station not saved");
        }
      });
    }
    onRoleRowDblClick(rolelistval: any) {
      this._action = "update";
      this.isDetailScreen = true;
      //this.router.navigate(['user']);
      this.getDetail(rolelistval.username);
      //console.log(this.isRFIDRequested);
    }
  
      async getDetail(username) {
        if (username) {
        this.appuserService.getDetail(username).subscribe(data => {
          if (data["success"]) {
            this.data = data["result"];
            this.data.balanceamount = data["result"].Balanceamount;
          console.log(this.data);
            //console.log(this.data.preferredPvtStation);
            if (this.data && this.data.operatorId) {
              
              this.operator.operatorId = this.data.operatorId;   
            } else {
              this.operator = new OperatorModel();
            }
            if (this.data && this.data.tariffPlan) {
             // console.log(this.data.tariffPlan);
              this.tarrifrule.ruleID = this.data.tariffPlan;   
            } else {
              this.tarrifrule = new TariffRuleModel();
            }
             if (this.data && this.data.preferredPvtStation) {
          //    console.log(this.data.preferredPvtStation);
              this.station.stationId=Number(this.data.preferredPvtStation);
            } else {
              this.station = new StationModel();
            }
            if (this.data.multiBooking==="Y") {
              this.multiBookingEvent=true;
            } if (this.data.multiBooking==="N") {
              this.multiBookingEvent=false;
            }
            if (this.data.userGroup ) {
              this.selectedGroups = this.data.userGroup.split(',');
            } else {
              this.selectedGroups = undefined;
            }
           

            if(this.data.rfid==="Requested"){
              this.isRFIDRequested = true;
            }

            this._data = this.tools.copyFullObject(this.data);    
            this.getCoprorate();      
          } else {
            this.data = new AppUserModel();
            alert("Failed to get user data!");
          }
        });
      }
    }
  
    isModified() {
      if (!(this.data && this._data)) {
        return false;
      } else if (this.data.userType != this._data.userType) {
        return true;
      } else if (this.data.firstName != this._data.firstName) {
        return true;
      } else if (this.data.lastName != this._data.lastName) {
        return true;
      } else if (this.data.email != this._data.email) {
        return true;
      }else if (this.data.status != this._data.status) {
        return true;
      }else if (this.data.billing != this._data.billing) {
        return true;
      }else if (this.data.balanceamount != this._data.balanceamount) {
        return true;
      }
      else if (this.data.address != this._data.address) {
        return true;
      }
      else if (this.data.userGroup != this._data.userGroup) {
        return true;
      }
      else if (this.operator.operatorId != this.dataOperatorId()) {
        return true;
      } else if (this.station.stationname != this.dataStationId()) {
        return true;
      }else if (this.tarrifrule.ruleID != this.dataTarrifPlan()) {
        return true;
      }else if (this.data.multiBooking != this._data.multiBooking) {
        return true;
      }
      //else if 
     /// (this.subscription.planId !=this.dataSubscriptionId() ) {
      
     //   return true;
    //  }
      return false;
    }
    dataSubscriptionId() {
      //if (!this.data.subscription) {
     //   return undefined;
     // } else if (!this.data.subscription.planId) {
     //   return undefined;
     /// } else {
//return this._data.subscription.planId;
  //    }
    }
    dataOperatorId() {
      if (!this.data.operatorId) {
        return undefined;
      } else {
        return this.data.operatorId;
      }
     
    }

    dataTarrifPlan() {
      if (!this.data.tariffPlan) {
        return undefined;
      } else {
        return this.data.tariffPlan;
      }
     
    }

    dataStationId() {
      if (!this.data.preferredPvtStation) {
        return undefined;
      } else {
        return this.data.preferredPvtStation;
      }
     
    }
  
    async getOperators() {
      this.operatorlist = undefined;
      await this.operatorservice
        .getAllOperators()
        .toPromise()
        .then(data => {
          if (data["success"]) {
            this.operatorlist = data["result"];
          } else {
            console.error("Error in fetching alll operators");
          }
        });
    }

    
  
    isGroupExistsInGroups(group : string) {
      if(this.selectedGroups) {
          for(let i=0 ; i<this.selectedGroups.length;i++) {
              if(this.selectedGroups[i]==group) {
                  return true;
              }
          }
      }
      return false;
      }
        onSelectAllGroup(items: any){
              for(let i=0;i<this.groupList.length;i++) {
               if(!this.isGroupExistsInGroups(this.groupList[i])) {
                  if(!this.selectedGroups){
                    this.selectedGroups=new Array<string>()
                  }
                  this.selectedGroups.push(this.selectedGroups[i]); 
            }
          }
          this.data.userGroup=this.selectedGroups.join(',');
         // console.log(this.data.userGroup);
          
        }
  
        onDeSelectAllGroup(items: any){
         this.groupSelectDeselect();
         this.data.userGroup=null;
        }
        groupSelectDeselect() {
          this.data.userGroup=this.selectedGroups.join(',');
          //console.log(this.data.amenities);
        }
        onGroupSelect(item:any){
          this.groupSelectDeselect();
         }
        onGroupDeSelect(item:any){
          this.groupSelectDeselect();
      }
      aproveRFIDRequest(){
        this.appuserService.approveRFIDRequest(this.data.username).subscribe(data => {
          let ngmodaloptions: NgbModalOptions = {};
          ngmodaloptions.backdrop = "static";
          ngmodaloptions.size = "sm";
          if (data["success"]) {
            this._isSaved = true;
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
            modalRef.componentInstance.title = "Information";
            modalRef.componentInstance.message = "RFID Request Approved successfully!";
            modalRef.componentInstance.btnCLass = "btn-info";
            modalRef.result.then(
              result => {
                this.data = data["result"];
                this._data = this.tools.copyFullObject(this.data);
                if (result == "ok") {
                  this.getList();
                  this.isDetailScreen = false;
                }
              },
              reason => {
                this.data = data["result"];
                this._data = this.tools.copyFullObject(this.data);
              }
            );
          } else {
            const modalRef = this.ngbModalService.open(
              NgInformationModalContent,
              ngmodaloptions
            );
            modalRef.componentInstance.title = "Error";
            modalRef.componentInstance.message = "Failed to approve Request!";
            modalRef.componentInstance.btnCLass = "btn-warning";
            modalRef.result.then(
              result => {},
              reason => {}
            );
          }
        });
      }

      async getStations() {
        this.stationlist = undefined;
        await this.stationservice
          .getAllStations()
          .toPromise()
          .then(data => {
            if (data["success"]) {
              let stations: Array<StationModel> = new Array<StationModel>();
              let station: StationModel = new StationModel();
              station.stationId = undefined;
              station.stationname = "-";
              stations.push(station);
              const datastations: Array<StationModel> = data["result"];
              for (let i: number = 0; i < datastations.length; i++) {
                stations.push(datastations[i]);
              }
              this.stationlist = stations;
             // console.log(this.data.config);
            } else {
              console.error("Error in fetching alll stations");
            }
          });
      }
      closestats(){
        this.showstats=false;
      }
      saveStats(){
        this.showstats=true;
      }
      async getGroupAdding() {
        this.GroupAddinglist = undefined;
        await this.gstlocationservice
          .getAllGroupAdding()
          .toPromise()
          .then(data => {
            if (data["success"]) {
             this.GroupAddinglist = data["result"];
           /* let Groups: Array<GroupAddingModel> = new Array<GroupAddingModel>();
              let group: GroupAddingModel = new GroupAddingModel();
             
              group.id = undefined;
              group.groupName = "-";
              Groups.push(group);
              const datastations: Array<GroupAddingModel> = data["result"];
              for (let i: number = 0; i < datastations.length; i++) {
                Groups.push(datastations[i]);
              }
              this.GroupAddinglist = Groups;*/
             // console.log(this.data.config);
            } else {
              console.error("Error in fetching all GroupAdding");
            }
          });
      }
      list(){
        this.isPopupOpen=true;
        this.getuserevDetail();
      }
      
      isPopupOpen:boolean=false

      
closePopup(){
  this.isPopupOpen=false;
}
evdatalist:any=false
blankMessage:any
 getuserevDetail() {
  
  this.appuserService
    .getuserevDetail(this.data.username)
    .toPromise()
    .then(data => {
      if (data["success"]) {
       this.evdatalist = data["result"];
    console.log(this.evdatalist)
    if( this.evdatalist.length===0){
      this.blankMessage=true
    }else{
      this.blankMessage=false
    }
      } else {
        console.error("Error in fetching all getuserEvDetail");
      }
    });
}


UpdateEvData(event:any) {
  
   this.appuserService.UpdateUserevDetail(event).subscribe(data => {
     let ngmodaloptions: NgbModalOptions = {};
     ngmodaloptions.backdrop = "static";
     ngmodaloptions.size = "sm";
     if (data["success"]===true) {
       
       const modalRef = this.ngbModalService.open(
         NgInformationModalContent,
         ngmodaloptions
       );
       modalRef.componentInstance.title = "Information";
       modalRef.componentInstance.message = "Vin Change successfully!";
       modalRef.componentInstance.btnCLass = "btn-info";
       modalRef.result.then(
         result => {
          
           if (result == "ok") {
           
           }
         },
         reason => {
          
         }
       );
     } else {
       const modalRef = this.ngbModalService.open(
         NgInformationModalContent,
         ngmodaloptions
       );
       modalRef.componentInstance.title = "Error";
       modalRef.componentInstance.message = "Failed to Change Ev!";
       modalRef.componentInstance.btnCLass = "btn-warning";
       modalRef.result.then(
         result => {},
         reason => {}
       );
       // alert("station not saved");
     }
   });
 }
  }
  