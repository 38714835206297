
export class RFIDModel {
    rfid?: string;
    status?: string;
    userName?: number;
    issuedTime?: Date;
    validTime?: Date;
    generationTime?:Date;
    balanceAmt?: number;
    cardNo?:string;
    otp?:string;
}
