import { Component, OnInit,ViewChild } from '@angular/core';
import { TopbarText } from "../topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { userDetailsModel } from "src/app/models/userSession.Model";
import { AppUserService } from "../../services/appUser.service";
import { Tools } from "../../modules/tools";
import {FTransactionsService } from "src/app/services/ftransactions.service";
import { FTransactionsModel } from "../../models/ftransactions.model";
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
/*export class userDetails {
  username?:string;
  logints?:string;
  ipaddr?:string;
  ua?:string;
  
}*/


@Component({
  selector: 'app-user-sessions',
  templateUrl: './user-sessions.component.html',
  styleUrls: ['./user-sessions.component.css']
})
export class UserSessionsComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "User Name",
      columnFieldName: "username",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Session Start Time",
      columnFieldName: "logints",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "IP",
      columnFieldName: "ipaddr",
      columnClasses: "c-col-pc-8"
    },
    {
      columnHeader: "Device",
      columnFieldName: "ua",
      columnClasses: "c-col-pc-8"
    },
    
  ];
  searchField={
    searchText:""
    
  }
    
    

  datalist: Array<FTransactionsModel>;
  userdatalist:Array<userDetailsModel>;
  paymentData:Array<FTransactionsModel>;
  isDetailScreen: boolean = false;
  isChargerScreen:boolean =false;
  isOperatorScreen:boolean =false;
  _action: string;
  //data: EveryTransactionModel;
  page: GridPage;
  data: userDetailsModel;
  _data: userDetailsModel;
  _menuId: string;
  dateNow: Date = new Date();
  topbartext: TopbarText[] = [{ key: "userSessions", displayText: "User Sessions" }];
  //searchField: searchField;
  constructor(
    private ngbModalService: NgbModal,
    private appuserService:AppUserService,
    public ftransactionService: FTransactionsService,
    public tools: Tools,
    public objGlobal: global,
    public auth: AuthService,
  ) {
    this.data = new userDetailsModel();
    //this._data = new FTransactionsModel();
    //this.data = new EveryTransactionModel();
   /* this.searchField = new searchField();
   this.searchField.status="I";
    this.searchField.startdate=this.dateNow;
    this.searchField.enddate=this.dateNow;*/
    this.page = new GridPage();
    this._menuId = this.tools.getMenuName("main/userSessions");
    this.objGlobal.setActiveMenu(this._menuId);
    
    this.page.size = 10; //Default page size
    //this.getTransactionsData() ;
   }
  ngOnInit() {
  }
  onRoleRowDblClick(rolelistval: any) {
    console.log(rolelistval)
    this.data = rolelistval;
    this._data = this.tools.copyFullObject(this.data);
    this._action = "update";
    this.isDetailScreen = true;
    this.isOperatorScreen = false;
    this.isChargerScreen = true;
    
    //this.router.navigate(['user']);
    
    //console.log(this.isRFIDRequested);
  }
  hideOperatorScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (this.isChargerScreen && this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  isModified() {
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.username != this._data.username) {
      return true;
    } else if (this.data.logints != this._data.logints) {
      return true;
    } else if (this.data.ipaddr != this._data.ipaddr) {
      return true;
    } 
    else if (this.data.ua != this._data.ipaddr) {
      return true;
    } 
      return false;
  }
  Kickoff(){
    
  }
  closeButton(){
    this.isDetailScreen = false;
  }
   hideChargerScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (this.isChargerScreen && !this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  
  async getUserSessions(){
    
     //   console.log(this.searchField.searchStatus)
     console.log(this.searchField)
     this.appuserService.getUserSessions(this.page, this.searchField).subscribe(data => {
      if (data["success"]) {
        this.userdatalist = data["result"].content;
        this.page.first = data["result"].first;
        this.page.last = data["result"].last;
        this.page.number = data["result"].number;
        this.page.numberOfElements = data["result"].numberOfElements;
        this.page.offset = data["result"].offset;
        this.page.size = data["result"].size;
        this.page.totalElements = data["result"].totalElements;
        this.page.totalPages = data["result"].totalPages;
        if (this.page.number == 0) {
         this.gridComp.setPages();
        }
      } else {
        alert("Failed to get UserBalanceDetailList !");
      }
    });
  }
}
