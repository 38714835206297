import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { UserService } from "../../services/user.service";
import { Tools } from "../../modules/tools";
//import {Router} from '@angular/router';
import { UserComponent } from "../user/user.component";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { UserModel } from "src/app/models/user.model";
@Component({
  selector: "app-userlist",
  templateUrl: "./userlist.component.html",
  styleUrls: ["./userlist.component.css"]
})
export class UserlistComponent implements OnInit, AfterViewInit {
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Emp Id",
      columnFieldName: "username",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "Email",
      columnFieldName: "userEmail",
      columnClasses: "c-col-pc-25"
    },
    {
      columnHeader: "Role",
      columnFieldName: "roleName",
      columnClasses: "c-col-pc-25"
    }
  ];
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  @ViewChild(UserComponent) userComp: UserComponent;
  users: Array<object>;
  page: GridPage;

  _menuId: string;
  _action: string;
  _userParam: any;
  isUserDetailScreen = true;
  searchField = {
    searchText: "",
    distributorId: 0,
    roleId: 0
  };
  topbartext: TopbarText[] = [
    { key: "usermanagement", displayText: "User Management" }
  ];
  _username: string = "";
  constructor(
    private objuserservice: UserService,
    private tools: Tools,
    private ngbModalService: NgbModal,
    private objGlobal: global,
    private auth: AuthService
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/user");
    //console.log(this._menuId);
    this.objGlobal.setActiveMenu(this._menuId);
    this.getUserList();
  }

  ngOnInit() {
    this.isUserDetailScreen = false;
  }
  ngAfterViewInit() {}
  getUser(event: any) {
    this.getUserList();
  }
  selectUser(event: any) {}

  getUserList() {
    this.objuserservice
      .getUserList(this.page, this.searchField)
      .subscribe(data => {
        if (data["success"]) {
          this.users = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        } else {
          alert("Failed to get user list!");
        }
      });
  }
  onUserRowDblClick(userlistval: any) {
    this._action = "update";
    this._username = userlistval.userId;
    this._userParam = { id: userlistval.userId };
    this.isUserDetailScreen = true;
    //this.router.navigate(['user']);
    this.userComp.getUserDetail(this._userParam, this._action);
  }
  onAddNew() {
    this._action = "new";
    this.isUserDetailScreen = true;
    this.userComp.addNewUser();
  }
  onCancel(obj: any) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";

      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to discard changes?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isUserDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
    }
  }
  /*onCancel(obj) {
  if(obj.isModified) {
    this.confirmService.confirm({title : 'Confirmation', message : 'User has been modified. Are you sure to exit without save?'})
    .then(
      ()=> {
        this._action="";
        this.isUserDetailScreen=false;
      }
      )
  } else {
    this._action="";
    this.isUserDetailScreen=false;
  }
}*/
  onBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "User detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isUserDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isUserDetailScreen = false;
    }
  }

  saveEvent(obj) {
    if (obj.success == true) {
      this.getUserList();
      this.isUserDetailScreen = false;
    } else {
      this.getUserList();
      this.isUserDetailScreen = false;
    }
  }
  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    //console.log("is priv " + this._menuId + "=>add");
    return isAdd;
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  deleteUser(recObj: any) {
    const objUser: UserModel = recObj.obj;
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to inactive user?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          //console.log("test delete user");
          this.objuserservice.deleteUser(objUser.userId).subscribe(data => {
            if (data["success"]) {
              const modalRefOk = this.ngbModalService.open(
                NgInformationModalContent,
                ngmodaloptions
              );
              modalRefOk.componentInstance.title = "Information";
              modalRefOk.componentInstance.message =
                "User marked inactive successfully!";
              modalRefOk.componentInstance.btnCLass = "btn-info";
              modalRefOk.result.then(
                result => {
                  this.getUserList();
                },
                reason => {
                  this.getUserList();
                }
              );
            } else {
              alert("Failed to delete user!");
            }
          });
        }
      },
      reason => {}
    );
  }
}
