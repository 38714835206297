
import { Component, OnInit, ViewChild, Output, Input,EventEmitter, DoCheck } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { __values } from 'tslib';
//import { RfidDetailComponent } from "../rfid-detail/community.component";
import { OperatorModel } from "src/app/models/operator.model";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { SubscriptionService } from "../../services/subscription.service"
import {GridPage,gridColumns,GridtableComponent} from "../../util/components/gridtable/gridtable.component";
import { SubscriptionModel } from "src/app/models/subscription.model";

export class ChargingValue {
  byCUnit?:string;
  byCMinute?:string;
  byCHour?:string;
  bySOC?:string;
  powerSource?:string;
}
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-10"
    },{
      columnHeader: "Scope",
      columnFieldName: "scope",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.scopeValue
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.statusValue
      
    },
    {
      columnHeader: "Last Modified",
      columnFieldName: "modts",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Modified By",
      columnFieldName: "",
      columnClasses: "c-col-pc-10",
      
    },
    {
      columnHeader: "Creation Date",
      columnFieldName: "gents",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
  ];
  searchField = {
    searchText: "",
    searchSubscription:"",
    scope:"U",
    status:""
  };
  
    
  parkingValue = {
    byPUnit:"",
    byPMinute:"",
    byPHour:"",
  };
  cancellationValue = {
    byCAUnit:"",
    byCAMinute:"",
    byCAHour:"",
  };
  otherValue = {
    byOUnit:"",
    byOMinute:"",
    byOHour:"",
  };
  dateNow: Date = new Date();
  data: SubscriptionModel;
    _data: SubscriptionModel;
    chargingValueList:Array<ChargingValue>;
    chargingValue:ChargingValue;
    chargingFormula:string;
    parkingFormula:string;
    othersFormula:string;
    progress: { percentage: number } = { percentage: 0 };
    _isSaved: boolean = false;
    datalist: Array<SubscriptionModel>;
    operator: OperatorModel;
    page: GridPage;
    _action: string;
    ischargingdelete: boolean = false;
    hideP: boolean = false;
    hideO: boolean = false;
    isParking: boolean = true;
    isOthers: boolean = true;
    iscommercialAllowed = false;
    isDetailScreen: boolean = false;
    //_disableSaveCancel: boolean = true;
   // isScreen: boolean = true;
    commercialTypes:string;
    _menuId: string;
    topbartext: TopbarText[] = [{ key: "tariff", displayText: "Tariff Plans" }];
   
  constructor(private ngbModalService: NgbModal,
      public tools: Tools,
      private objGlobal: global,
      private auth: AuthService,
      private subscriptionservice: SubscriptionService) {
      this.data = new SubscriptionModel();
      
      //this.rfidarrlist = new RFIDModel();
      this.page = new GridPage();
      this._menuId = this.tools.getMenuName("main/managetariff");
      this.getSubscriptionList();
      this.commercialTypes="C";
     // this.searchField.searchText="";
      this.data.gents=this.dateNow;
      this.data.modts=this.dateNow;
     
     }

  ngOnInit() {
  }

  showAddCommercials(){
    if (this.commercialTypes==='P'){
      this.isParking= false;
      this.hideP= true;
      this.parkingValue.byPHour="NA"
      this.parkingValue.byPMinute="NA"
      this.parkingValue.byPUnit="NA"
      this.commercialTypes="-";
    }
    if (this.commercialTypes==='O'){
    this.isOthers = false;
    this.hideO = true;
    this.otherValue.byOHour="NA";
    this.otherValue.byOMinute="NA"
    this.otherValue.byOUnit="NA"
    this.commercialTypes="-";
    }
    if(this.commercialTypes==="C"){
      let chargingValue: ChargingValue = new ChargingValue();
      if(!this.chargingValueList){
        this.chargingValueList = new Array<ChargingValue>();
        this.chargingValueList[0].byCHour="NA";
        this.chargingValueList[0].byCMinute="NA";
        this.chargingValueList[0].byCUnit="NA";
        this.chargingValueList[0].bySOC="NA";
        }
      chargingValue.byCUnit="NA";
      chargingValue.byCMinute="NA";
      chargingValue.byCHour="NA";
      chargingValue.bySOC="NA";
      chargingValue.powerSource="G"
      console.log(chargingValue);
      this.chargingValueList.push(chargingValue);
      //this.chargingValueList = chargingList; 
      this.ischargingdelete = true;
    }
   
  }

  removeOthers(){
    this.isOthers = true;
    this.iscommercialAllowed=false;
    this.hideO = false;
    this.otherValue.byOHour="-1"
    this.otherValue.byOMinute="-1"
    this.otherValue.byOUnit="-1"
    
  }

  removeParking(){
    this.isParking = true;
    this.iscommercialAllowed=false;
    this.hideP= false;
    this.parkingValue.byPHour="-1"
    this.parkingValue.byPMinute="-1"
    this.parkingValue.byPUnit="-1"
  }
  removeCharging(chargingvalueid:number){
    this.iscommercialAllowed=false;
    if(this.chargingValueList && this.chargingValueList.length>1){
      console.log(this.chargingValueList[chargingvalueid])
      this.chargingValueList.splice(chargingvalueid,1)
    }
  }
  

  getSubscriptionList() {
    this.subscriptionservice.getSubscriptionList(this.searchField).subscribe(data => {
        // console.log(this.searchField);
         if (data["success"]) {
           this.datalist = data["result"];
           //console.log(this.datalist);
          /* this.page.first = data["result"].first;
           this.page.last = data["result"].last;
           this.page.number = data["result"].number;
           this.page.numberOfElements = data["result"].numberOfElements;
           this.page.offset = data["result"].offset;
           this.page.size = data["result"].size;
           this.page.totalElements = data["result"].totalElements;
           this.page.totalPages = data["result"].totalPages;
           if (this.page.number == 0) {
             this.gridComp.setPages();
           }
         } else {
           alert("Failed to get rfid list!");
         }*/
       }
       });
   }
   disableSave(f: any) {
    let isDisableSave: boolean = true;
  //  console.log(f);
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

   onRowDblClick(sublistvalue:any) {
    this._action = "update";
    this.isDetailScreen = true;
    //this.isScreen = false;
    //this.router.navigate(['user']);
    this.getDetail(sublistvalue.subId);
  }
   getDetail(Id) {
    if (Id) {
       this.subscriptionservice.getSubscription(Id).subscribe(data => {
         if (data["success"]) {
           this.data = data["result"];
           this.data.gents=new Date(this.data.gents);
           this.data.modts=new Date(this.data.modts);
           this._data = this.tools.copyFullObject(this.data);
           this.breakFormulaValue(this.data.formula);
         }
       }
       )}
   }
   breakFormulaValue(formulaValue:string){
     let value = formulaValue;
     let valueArray = value.split("#");
     let rawCharging = valueArray[0];
     console.log(rawCharging);
     let rawChargingList = rawCharging.split("%");
     console.log(rawChargingList);
     let chargingList: Array<ChargingValue> = new Array<ChargingValue>();
     //rawChargingList.length
     for (let i=0;i<rawChargingList.length;i++){
      let chargingValue: ChargingValue = new ChargingValue();
      let chargingArray=[];
        chargingArray = rawChargingList[i].split("$");
        console.log(chargingArray);
        chargingValue.byCHour="NA"
        chargingValue.byCMinute="NA"
        chargingValue.bySOC="NA"
        chargingValue.byCUnit="NA"
        if(chargingArray[0] && chargingArray[0]!=="U-1"){
        chargingValue.byCUnit=chargingArray[0].replace("U","")
        }
        if(chargingArray[1] && chargingArray[1]!=="M-1"){
        chargingValue.byCMinute=chargingArray[1].replace("M","")
        }
        if(chargingArray[2] && chargingArray[2]!=="H-1"){
        chargingValue.byCHour=chargingArray[2].replace("H","")
        }
        if(chargingArray[3] && chargingArray[3]!=="S-1"){
        chargingValue.bySOC=chargingArray[3].replace("S","")
        }
        if(chargingArray[4]){
          chargingValue.powerSource=chargingArray[4].replace("E","")
          }
          
        console.log(chargingValue);
        chargingList.push(chargingValue);
        }

     this.chargingValueList = chargingList;
     if(this.chargingValueList.length>1){
      this.ischargingdelete = true;
     }
     
     console.log(this.chargingValueList);
     let rawParking = valueArray[1];
     let parkingArray = rawParking.split("$");
     this.parkingValue.byPUnit=parkingArray[0].replace("U","")
     this.parkingValue.byPMinute=parkingArray[1].replace("M","")
     this.parkingValue.byPHour=parkingArray[2].replace("H","")
     let rawOther = valueArray[2];
     let otherArray = rawOther.split("$");
     this.otherValue.byOUnit=otherArray[0].replace("U","")
     this.otherValue.byOMinute=otherArray[1].replace("M","")
     this.otherValue.byOHour=otherArray[2].replace("H","")

    if(this.parkingValue.byPUnit==="-1"){
      this.parkingValue.byPUnit="NA"
    }
    if(this.parkingValue.byPMinute==="-1"){
      this.parkingValue.byPMinute="NA"
    }
    if(this.parkingValue.byPHour==="-1"){
      this.parkingValue.byPHour="NA"
    }
    if(this.otherValue.byOUnit==="-1"){
      this.otherValue.byOUnit="NA"
    }
    if(this.otherValue.byOMinute==="-1"){
      this.otherValue.byOMinute="NA"
    }
    if(this.otherValue.byOHour==="-1"){
      this.otherValue.byOHour="NA"
    }
    if(this.parkingValue.byPUnit!=="NA"||this.parkingValue.byPMinute!=="NA"||this.parkingValue.byPHour!=="NA"){
      this.isParking = false;
      this.hideP = true;
      this.commercialTypes="C";
    } else{
      this.isParking = true;
    }
    if(this.otherValue.byOUnit!=="NA"||this.otherValue.byOMinute!=="NA"||this.otherValue.byOHour!=="NA"){
      this.isOthers = false;
      this.hideO = true;
    }
    else{
      this.hideO = false;
      this.isOthers = true;
    }

   if(!this.isParking && !this.isOthers ){
       this.hideO = true;
       this.hideP = true;
    }
    
    

  }
  statusValue(value){
    if(value==="A"){
      return "Active"
    }else if(value==="I"){
      return "Inactive"
    }
    else{
      return value
    }

  }
  scopeValue(value){
    if(value==="U"){
      return "User"
    }else if(value==="C"){
      return "Charger"
    }else if(value==="B"){
      return "Business"
    }
    else{
      return value
    }
  }

  onBackClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Details has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;

    }
  }
  isModified() {
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.name != this._data.name) {
      return true;
    } else if (this.data.scope != this._data.scope) {
      return true;
    } else if (this.data.formula != this._data.formula) {
      return true;
    } else if (this.data.gents != this._data.gents) {
      return true;
    } else if (this.data.modts != this._data.modts) {
      return true;
    } 
      return false;
  }


  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  createChargingValue(){
    const chargingValueList = this.chargingValueList;
    let chargingStrings= [];
    let  chargingstring=""   
    for(let i=0;i<chargingValueList.length;i++){
      if(this.chargingValueList[i].byCUnit===""||this.chargingValueList[i].byCUnit==="NA"){
        this.chargingValueList[i].byCUnit="-1"
      }
      if(this.chargingValueList[i].byCMinute===""||this.chargingValueList[i].byCMinute==="NA"){
        this.chargingValueList[i].byCMinute="-1"
      }
      if(this.chargingValueList[i].byCHour===""||this.chargingValueList[i].byCHour==="NA"){
        this.chargingValueList[i].byCHour="-1"
      }
      if(this.chargingValueList[i].bySOC===""||this.chargingValueList[i].bySOC==="NA"){
        this.chargingValueList[i].bySOC="-1"
      }
      if(this.chargingValueList[i].powerSource==="-"){
        this.chargingValueList[i].powerSource="G"
      }
      chargingStrings.push("U"+this.chargingValueList[i].byCUnit+"$M"+this.chargingValueList[i].byCMinute+"$H"+this.chargingValueList[i].byCHour+"$S"+this.chargingValueList[i].bySOC+"$E"+this.chargingValueList[i].powerSource);
     
      console.log(chargingStrings);
    }
    chargingstring = chargingStrings.join("%")
    return chargingstring
  }
  createformulaValue(){
    let value="";
    console.log(this.createChargingValue());
   let chargingvalue = this.createChargingValue();
    if(this.parkingValue.byPUnit===""||this.parkingValue.byPUnit==="NA"){
      this.parkingValue.byPUnit="-1"
    }
    if(this.parkingValue.byPMinute===""||this.parkingValue.byPMinute==="NA"){
      this.parkingValue.byPMinute="-1"
    }
    if(this.parkingValue.byPHour===""||this.parkingValue.byPHour==="NA"){
      this.parkingValue.byPHour="-1"
    }
    if(this.otherValue.byOUnit===""||this.otherValue.byOUnit==="NA"){
      this.otherValue.byOUnit="-1"
    }
    if(this.otherValue.byOMinute===""||this.otherValue.byOMinute==="NA"){
      this.otherValue.byOMinute="-1"
    }
    if(this.otherValue.byOHour===""||this.otherValue.byOHour==="NA"){
      this.otherValue.byOHour="-1"
    }
    value = chargingvalue+"#U"+this.parkingValue.byPUnit+"$M"+this.parkingValue.byPMinute+"$H"+this.parkingValue.byPHour+"#U"+this.otherValue.byOUnit+"$M"+this.otherValue.byOMinute+"$H"+this.otherValue.byOHour;
    return value;
  }
  
  saveData() {
    console.log(this.data.scope)
    this.data.formula = this.createformulaValue();
    /*if(!this.data.userName){
        this.data.status = "A";
      }
    else {
        this.data.status = "I";
      }
      if(this.data.balanceAmt===null){
        this.data.balanceAmt=0.00;
      }*/
    this.subscriptionservice.saveSubscription(this.data,this._action).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Tariff Plan saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
            if (result == "ok") {
              if (result == "ok") {
                this.getSubscriptionList();
                this.isDetailScreen = false;
              }
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } 
      
      else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save Discount Code!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("station not saved");
      }
    });
  }
  async onAddNew() {
    this.data = new SubscriptionModel();
    this._data = this.tools.copyFullObject(this.data);
    let chargingValue: ChargingValue = new ChargingValue();
     
        this.chargingValueList = new Array<ChargingValue>();
        chargingValue.byCHour="NA";
        chargingValue.byCMinute="NA";
        chargingValue.byCUnit="NA";
        chargingValue.bySOC="NA";
        chargingValue.powerSource="G";
        this.chargingValueList.push(chargingValue);
        this.isParking = true;
        this.commercialTypes="P";
        this.isOthers=true;
        this.hideP= false;
        this.hideO= false;
        this._action = "new";
        this.data.scope = "U";
        this.data.status = 'A';
        this.isDetailScreen = true;

  }
}
