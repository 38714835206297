import { Component, OnInit, ViewChild, DoCheck } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { StationsService } from "../../services/Stations.Service";
import { Tools } from "../../modules/tools";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
import { StationModel } from "../../models/station.model";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { from } from "rxjs";
import * as XLSX from "xlsx";
import { OperatorModel } from "src/app/models/operator.model";
import { OperatorService } from "src/app/services/operator.service";
import { GSTLocationModel } from "src/app/models/gstlocation.model";
import { GSTLocationService } from "src/app/services/gstlocation.service";
import { OperatorDetailComponent } from "../../components/operator-detail/operator-detail.component";
import { ChargerComponent } from "../charger/charger.component";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ChargerModel } from "src/app/models/Charger.model";
import { CommunityModel } from "src/app/models/community.model";
import { CommunityService } from "src/app/services/Community.service";
import { GroupAddingModel } from "src/app/models/groupadding.model";
import { Content } from "@angular/compiler/src/render3/r3_ast";
export class StationData {
  StationName?: String;
  Contact?: String;
  lattitude?: String;
  longitude?: String;
  OperatorName?: String;
  Email?:String;
  OperationalHours?:String;
  Address?:String;
}
@Component({
  selector: "app-station",
  templateUrl: "./stations.component.html",
  styleUrls: ["./stations.component.css"]
})

export class StationsComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  @ViewChild(OperatorDetailComponent) operatorComp: OperatorDetailComponent;
  @ViewChild(ChargerComponent) chargerComp: ChargerComponent;
  topbartext: TopbarText[] = [{ key: "stations", displayText: "Stations" }];
  groupList = [];
  isimport:boolean = false;
  isimportscreen:boolean=true;
  selectedGroup: string;
  amenitiesList = [];
  selectedAmenities = [];
  dropdownSettings= {};
  communitylist: Array<CommunityModel>;
  community: CommunityModel;
  stationData:Array<StationData>;
  data: StationModel;
  _data: StationModel;
  _isSaved: boolean = false;
  page: GridPage;
  _menuId: string;
  datalist: Array<StationModel>;
  _action: string;
  searchField = {
    searchText: ""
  };
  GroupAddinglist: Array<GroupAddingModel>;
  isDetailScreen: boolean = false;
  _disableSaveCancel: boolean = true;
  isStationScreen: boolean = false;
  isOperatorScreen: boolean = false;
  isChargerScreen: boolean = false;
  _chargeraction: string = "";
  _stationParam: any;
  //For role list
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Station Name",
      columnFieldName: "stationname",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Contact",
      columnFieldName: "contact",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Address",
      columnFieldName: "address",
      columnClasses: "c-col-pc-20"
    },
    {
      columnHeader: "City",
      columnFieldName: "city",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Operator",
      columnFieldName: "operator.operatorName",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Email",
      columnFieldName: "email",
      columnClasses: "c-col-pc-18"
    },
    {
      columnHeader: "Operational Hours",
      columnFieldName: "operationalHours",
      columnClasses: "c-col-pc-12"
    }
  ];

  operator: OperatorModel;
  operatorlist: Array<OperatorModel>;
  gstlocation: GSTLocationModel;
  gstlocationlist: Array<GSTLocationModel>;
  selectedChargerId: number;
  selectedCharger: ManageChargerModel;
  chargers: Array<ManageChargerModel>;
  unmappedChargers: Array<ManageChargerModel>;

  constructor(
    private tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private stationservice: StationsService,
    private ngbModalService: NgbModal,
    private operatorservice: OperatorService,
    private gstlocationservice: GSTLocationService,
    private managechargerservice: ManageChargerService,
    private communityService:CommunityService,
    private httpClient: HttpClient
    
  ) {
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._stationParam = { source: "station" };
    this._menuId = this.tools.getMenuName("main/stations");
    this.httpClient.get("assets/config/amenities.json").subscribe(conf =>{
    console.log(conf["Amenities"]);
    this.groupList=[""];
    this.amenitiesList=conf["Amenities"];
    })
    this.httpClient.get("assets/config/groups.json").subscribe(conf =>{
      console.log(conf["Groups"]);
      this.groupList=conf["Groups"];
      })
    this.objGlobal.setActiveMenu(this._menuId);
    this.getList();
    this.data = new StationModel();
    this.getOperators();
    this.getGSTLocations();
    this.operator = new OperatorModel();
    this.gstlocation = new GSTLocationModel();
    this.getGroupAdding();
  }

  ngOnInit() {

    this.dropdownSettings = { 
      singleSelection: false,
      idField: 'roleId',
      textField: 'roleName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showCheckbox : true,
      enableCheckAll:true,
      enableFilterSelectAll:true,
      filterSelectAllText: "All roles selected",
      maxHeight : 200
      }       
  this.selectedAmenities=undefined;
  this.selectedGroup=undefined;
  }
  onOperatorBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Operator detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.isOperatorScreen = false;
            this.topbartext = [{ key: "stations", displayText: "Stations" }];
          }
        },
        reason => {}
      );
    } else {
      this.isOperatorScreen = false;
      this.topbartext = [{ key: "stations", displayText: "Stations" }];
    }
  }
  onChargerBackClick(obj) {
    if (obj.isModified == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.isChargerScreen = false;
            this.topbartext = [{ key: "stations", displayText: "Stations" }];
          }
        },
        reason => {}
      );
    } else {
      this.isChargerScreen = false;
      this.topbartext = [{ key: "stations", displayText: "Stations" }];
    }
  }
  onAddNewOperator() {
    this._action = "new";

    this.isOperatorScreen = true;

    this.operatorComp.onAddNew();
    this.topbartext = [
      {
        key: "manage_charge_point_operator",
        displayText: "Manage Charge Point Operator"
      }
    ];
  }

  onAddNewCharger() {
    console.log("Passing parameter " + this._stationParam);
    this._action = "new";

    this.isChargerScreen = true;
    this.chargerComp.onAddNew();

    this.topbartext = [{ key: "Add Charger", displayText: "Add Charger" }];
  }

  async onAddNew() {
    this._action = "new";
    this.data = new StationModel();
    this.chargers = undefined;
    this.data.operationalHours="24by7";
    this.data.stationGroup = "Public";
    this.selectedGroup="Public";
    this._data = this.tools.copyFullObject(this.data);
    await this.getUnmappedChargers();
    this.isDetailScreen = true;
    this.isOperatorScreen = false;
    this.isChargerScreen = false;
    this.getGroupAdding();
  }

  async getUnmappedChargers() {
    this.unmappedChargers = await this.managechargerservice.getUnmappedStationChargers();
  }

 /* ngDoCheck() {
    this._disableSaveCancel = true;
    if (this.isModified()) {
      if (this.isAllowAddorEdit()) {
        this._disableSaveCancel = false;
      }
    }
  }*/

  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }
  disableSave(f: any) {
    let isDisableSave: boolean = true;
  // console.log(f);
    if (f.valid) {
    ///  console.log(this.isModified());
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }

  deleteStation(recObj: any) {
    const objStation: StationModel = recObj.obj;
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "sm";
    const ngmodalref = this.ngbModalService.open(
      NgYesNoModalContent,
      ngmodaloptions
    );
    ngmodalref.componentInstance.title = "Confirmation";
    ngmodalref.componentInstance.message = "Are you sure to inactive station?";
    ngmodalref.result.then(
      result => {
        if (result == "yes") {
          //console.log("test delete user");
          this.stationservice
            .deleteStation(objStation.stationId)
            .subscribe(data => {
              if (data["success"]) {
                const modalRefOk = this.ngbModalService.open(
                  NgInformationModalContent,
                  ngmodaloptions
                );
                modalRefOk.componentInstance.title = "Information";
                modalRefOk.componentInstance.message =
                  "Station marked inactive successfully!";
                modalRefOk.componentInstance.btnCLass = "btn-info";
                modalRefOk.result.then(
                  result => {
                    this.getList();
                  },
                  reason => {
                    this.getList();
                  }
                );
              } else {
                alert("Failed to delete station!");
              }
            });
        }
      },
      reason => {}
    );
  }

  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  getList() {
    console.log(this.amenitiesList);
    this.stationservice
      .getStationList(this.page, this.searchField)
      .subscribe(data => {
        if (data["success"]) {
          console.log(data["result"].content);
          this.datalist = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
            this.createStationData(this.datalist);
          }
        } else {
          alert("Failed to get stations list!");
        }
      });
  }
  onFileUpload(event){
    const uploadImageData = new FormData();
    let selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      let base64SelectedFile = <string>reader.result;
      var itemImagerawarray=base64SelectedFile.split(";");
       let  itemImagearray = itemImagerawarray[1].split(",");
        this.data.fileData = itemImagearray[1];
        console.log(itemImagearray[1]);
  };
}
  onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Station detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;

            if (this._isSaved) {
              this.getList();
            }
            this._isSaved = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";

      this.isDetailScreen = false;
      if (this._isSaved) {
        this.getList();
      }
      this._isSaved = false;
    }
  }
  gridDeleteButton() {
    return this.auth.isPrivExists(this._menuId + "=>delete");
  }
  saveOperatorEvent(obj) {
    if (obj.success == true) {
      this.getList();
      this.isOperatorScreen = false;
      this.topbartext = [{ key: "stations", displayText: "Stations" }];
      this.getOperators();
    } /*else {
      this.getList();
      this.isOperatorScreen = true;
    }*/
  }
  uploadfile(){
    this.isimport=true;
    this.isimportscreen=false;
  }
  saveData() {
    if (this.operator && this.operator.operatorId) {
      this.data.operator = this.operator;
    }
    if (this.gstlocation && this.gstlocation.locationId) {
      this.data.gstLocation = this.gstlocation.locationId;
    }
    if (this.selectedGroup) {
      this.data.stationGroup = this.selectedGroup;
    }
    if (this.chargers && this.chargers.length > 0) {
      this.data.managechargers = this.chargers;
    } else {
      this.data.managechargers = undefined;
    }
    this.stationservice.saveStations(this.data).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Station saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            if (this.data && this.data.operator) {
              this.operator = this.data.operator;
            } else {
              this.operator = new OperatorModel();
            }
            if (this.data.managechargers) {
              this.chargers = this.tools.copyFullObject(
                this.data.managechargers
              );
            } else {
              this.chargers = undefined;
            }
            this._data = this.tools.copyFullObject(this.data);
            if (result == "ok") {
              this.getList();
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save Station!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("station not saved");
      }
    });
  }
  onRoleRowDblClick(rolelistval: any) {
    this._action = "update";
    this.isDetailScreen = true;
    this.isOperatorScreen = false;
    this.isChargerScreen = false;
    //this.router.navigate(['user']);
    this.getDetail(rolelistval.stationId);
  }

  async getDetail(_id) {
    if (_id) {
      this.stationservice.getStationDetail(_id).subscribe(data => {
        if (data["success"]) {
          this.data = data["result"];
          if (this.data && this.data.operator) {
            //this.operator = this.data.operator;
            console.log(this.data);
            this.operator = { operatorId: this.data.operator.operatorId };
          } else {
            this.operator = new OperatorModel();
          }
          if (this.data && this.data.gstLocation) {
            //this.operator = this.data.operator;
            console.log(this.data);
            this.gstlocation = { locationId: this.data.gstLocation };
          } else {
            this.gstlocation = new GSTLocationModel();
          }
          if (this.data.managechargers) {
            this.chargers = this.tools.copyFullObject(this.data.managechargers);
          } else {
            this.chargers = undefined;
          }
          if (this.data.amenities ) {
            this.selectedAmenities = this.data.amenities.split(',');
          } else {
            this.selectedAmenities = undefined;
          }
          if (this.data.stationGroup) {
            this.selectedGroup = this.data.stationGroup;
          } else {
            this.selectedGroup = undefined;
          }          
          this._data = this.tools.copyFullObject(this.data);
          this.getUnmappedChargers();
          this.getCoprorate();
        } else {
          this.data = new StationModel();
          alert("Failed to get station list!");
        }
      });
    }
  }

  isModified() {
   console.log(this.selectedGroup);
  console.log(this.data.stationGroup);
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.stationname != this._data.stationname) {
      return true;
    } else if (this.data.contact != this._data.contact) {
      return true;
    } else if (this.data.address != this._data.address) {
      return true;
    } else if (this.data.city != this._data.city) {
      return true;
    }/*else if (this.data.config != this._data.config) {
      return true;
    } */
    else if (this.operator.operatorId != this.dataOperatorId()) {
      return true;
    } else if (this.gstlocation.locationId != this.dataGSTLocation()) {
      return true;
    } else if (this.data.operationalHours != this._data.operationalHours) {
      return true;
    }else if (this.data.longitude != this._data.longitude) {
      return true;
    } else if (this.data.lattitude != this._data.lattitude) {
      return true;
    } else if (this.data.email != this._data.email) {
      return true;
    } else if (this.data.amenities != this._data.amenities) {
      return true;
    } else if (this.data.meterNumber != this._data.meterNumber) {
      return true;
    } 
    else if (this.selectedGroup != this.dataStationGroup()) {
      return true;
    } 
    else if (this.isChargerModified()) {
      return true;
    }
    return false;
  }
  isChargerModified() {
    if (!this.data.managechargers && !this.chargers) {
      return false;
    } else if (
      !this.data.managechargers &&
      this.chargers &&
      this.chargers.length > 0
    ) {
      return true;
    } else if (
      this.data.managechargers &&
      this.data.managechargers.length > 0 &&
      !this.chargers
    ) {
      return true;
    } else if (this.data.managechargers.length != this.chargers.length) {
      return true;
    } else if (this.isModifiedChargerListData()) {
      return true;
    } else {
      return false;
    }
  }
  isModifiedChargerListData() {
    let mismatch = false;
    if (this.chargers && this.data.managechargers) {
      for (
        let i: number = 0;
        i < this.chargers.length && mismatch == false;
        i++
      ) {
        let notfound = true;
        for (
          let j: number = 0;
          j < this.data.managechargers.length && notfound;
          j++
        ) {
          if (
            this.chargers[i].chargerId == this.data.managechargers[j].chargerId
          ) {
            notfound = false;
          }
        }
        if (notfound) {
          mismatch = true;
        }
      }
    }
    return mismatch;
  }
  dataOperatorId() {
    if (!this.data.operator) {
      return undefined;
    } else if (!this.data.operator.operatorId) {
      return undefined;
    } else {
      return this.data.operator.operatorId;
    }
  }
  dataGSTLocation() {
    if (!this.data.gstLocation) {
      return undefined;
    } else if (!this.data.gstLocation) {
      return undefined;
    } else {
      return this.data.gstLocation;
    }
  }
  dataStationGroup() {
    if (!this.data.stationGroup) {
      return undefined;
    }  else {
      return this.data.stationGroup;
    }
  }
  async getCoprorate() {
      
    this.communitylist = undefined;
    await this.communityService.getAllCommunity().toPromise().then(data => {
        if (data["success"]) {
          
          let communities: Array<CommunityModel> = new Array<CommunityModel>();
          let community: CommunityModel = new CommunityModel();
          community.communityId = undefined;
          community.communityName = "-";
          communities.push(community);
          const datacommunities: Array<CommunityModel> = data["result"];
          console.log(datacommunities);
          for (let i: number = 0; i < datacommunities.length; i++) {
            communities.push(datacommunities[i]);
            
          }
          
          this.communitylist = communities;
          
          this._data = this.tools.copyFullObject(this.data);
        } else {
          console.error("Error in fetching alll tariffs");
        }
      });
  }
  async getOperators() {
    this.operatorlist = undefined;
    await this.operatorservice
      .getAllOperators()
      .toPromise()
      .then(data => {
        
        if (data["success"]) {
          this.operatorlist = data["result"];
        } else {
          console.error("Error in fetching all operators");
        }
      });
  }
  async getGSTLocations() {
    this.operatorlist = undefined;
    await this.gstlocationservice
      .getAllGSTLocations()
      .toPromise()
      .then(data => {
        
        if (data["success"]) {
          this.gstlocationlist = data["result"];
        } else {
          console.error("Error in fetching all operators");
        }
      });
  }

  removeCharger(i: number) {
    this.unmappedChargers.push(this.chargers[i]);
    this.chargers.splice(i, 1);
   // this.data.managechargers = this.chargers;
  //  console.log(this.chargers);
  }
  getUnmappedChargerTextId(index: number) {
    return (
      this.unmappedChargers[index].chargerName +
      " - " +
      this.unmappedChargers[index].chargerType +
      " - " +
      this.unmappedChargers[index].category
    );
  }

  changeSelectCharger() {
    let notfound: boolean = true;
    this.selectedCharger = undefined;
    if (this.selectedChargerId != undefined) {
      for (
        let i: number = 0;
        i < this.unmappedChargers.length && notfound;
        i++
      ) {
        if (this.selectedChargerId == this.unmappedChargers[i].chargerId) {
          this.selectedCharger = this.unmappedChargers[i];
          notfound = false;
        }
      }
    }
  }
  addChagers() {
    let notfound: boolean = true;
    for (let i: number = 0; i < this.unmappedChargers.length && notfound; i++) {
      if (this.selectedChargerId == this.unmappedChargers[i].chargerId) {
        if (this.chargers == undefined) {
          this.chargers = new Array<ManageChargerModel>();
        }
        this.chargers.push(this.unmappedChargers[i]);
        this.unmappedChargers.splice(i, 1);
        this.selectedChargerId = undefined;
        notfound = false;
        this.changeSelectCharger();
      }
    }
  }

  hideOperatorScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (!this.isChargerScreen && this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  hideChargerScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (this.isChargerScreen && !this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  hideStationDetailScreen() {
    let result: Boolean = true;
    if (this.isDetailScreen) {
      if (!this.isChargerScreen && !this.isOperatorScreen) {
        result = false;
      }
    }
    return result;
  }
  saveChargerEvent(objchargersaveresult: any) {
    if (objchargersaveresult) {
      if (objchargersaveresult.success) {
        this.unmappedChargers.push(objchargersaveresult.data);
        this.selectedChargerId = objchargersaveresult.data.chargerId;
        this.topbartext = [{ key: "stations", displayText: "Stations" }];
      }
      this.isChargerScreen = false;
    }
  }

  isAmenityExistsInAmenities(amenity : string) {
    if(this.selectedAmenities) {
        for(let i=0 ; i<this.selectedAmenities.length;i++) {
            if(this.selectedAmenities[i]==amenity) {
                return true;
            }
        }
    }
    return false;
    }
      onSelectAllAmenities(items: any){
            for(let i=0;i<this.amenitiesList.length;i++) {
             if(!this.isAmenityExistsInAmenities(this.amenitiesList[i])) {
                if(!this.selectedAmenities){
                  this.selectedAmenities=new Array<string>()
                }
                this.selectedAmenities.push(this.amenitiesList[i]); 
          }
        }
        this.data.amenities=this.selectedAmenities.join(',');
        console.log(this.data.amenities);
        
      }

      onDeSelectAllAmenities(items: any){
       this.amenitiesSelectDeselect();
       this.data.amenities=null;
      }
      amenitiesSelectDeselect() {
        this.data.amenities=this.selectedAmenities.join(',');
        //console.log(this.data.amenities);
      }
      onAmenitySelect(item:any){
        this.amenitiesSelectDeselect();
       }
      onAmenityDeSelect(item:any){
        this.amenitiesSelectDeselect();
    }

    export(){
      var csvData = this.ConvertToCSV( this.stationData);
      var a = document.createElement("a");
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      var blob = new Blob([csvData], { type: 'text/csv' });
      var url= window.URL.createObjectURL(blob);
      a.href = url;
      var x:Date = new Date();
      var link:string ="Stations_"+ this.tools.formatDateToDDMMYY(x) +'.csv';
      a.download = link.toLocaleLowerCase();
      a.click();
  
    }

    downloadExcel () {
      let curdate = new Date();
      let curdatestring = this.tools.formatDateToDDMMYY(curdate);
      const worksheet = XLSX.utils.json_to_sheet(this.stationData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "Stations_"+curdatestring+".xlsx");
    };

    createStationData(dataArray){
      console.log(dataArray)
      if(dataArray){
        let stationlist: Array<StationData> = new Array<StationData>();
        
        for(let i=0;i<dataArray.length;i++){
          const station: StationData = new StationData();
          station.StationName = dataArray[i].stationname;
          station.OperatorName = dataArray[i].operator.operatorName;
          station.Address = dataArray[i].address;
          station.Contact = dataArray[i].contact;
          station.Email = dataArray[i].email;
          station.longitude= dataArray[i].longitude;
          station.lattitude = dataArray[i].lattitude;
          station.OperationalHours = dataArray[i].operationalHours;
          console.log(station);
          stationlist.push(station);
        } 
        this.stationData = stationlist;
      }
    }
    ConvertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
      var row = "";
  
      for (var index in objArray[0]) {
          //Now convert each value to string and comma-separated
          row += index + ',';
      }
      row = row.slice(0, -1);
      //append Label row with line break
      str += row + '\r\n';
  
      for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
              if (line != '') line += ','
              line += '"'+array[i][index]+'"';

          }
          str += line + '\r\n';
      }
      console.log(str)
      return str;
    }

 /*   isGroupExistsInGroups(group : string) {
      if(this.selectedGroups) {
          for(let i=0 ; i<this.selectedGroups.length;i++) {
              if(this.selectedGroups[i]==group) {
                  return true;
              }
          }
      }
      return false;
      }
        onSelectAllGroup(items: any){
              for(let i=0;i<this.groupList.length;i++) {
               if(!this.isGroupExistsInGroups(this.groupList[i])) {
                  if(!this.selectedGroups){
                    this.selectedGroups=new Array<string>()
                  }
                  this.selectedGroups.push(this.selectedGroups[i]); 
            }
          }
          this.data.stationGroup=this.selectedGroups.join(',');
          console.log(this.data.stationGroup);
          
        }
  
        onDeSelectAllGroup(items: any){
         this.groupSelectDeselect();
         this.data.stationGroup=null;
        }
        groupSelectDeselect() {
          this.data.stationGroup=this.selectedGroups.join(',');
          //console.log(this.data.amenities);
        }
        onGroupSelect(item:any){
          this.groupSelectDeselect();
         }
        onGroupDeSelect(item:any){
          this.groupSelectDeselect();
      }*/
      async getGroupAdding() {
        this.GroupAddinglist = undefined;
        await this.gstlocationservice
          .getAllGroupAdding()
          .toPromise()
          .then(data => {
            if (data["success"]) {
             this.GroupAddinglist = data["result"];
           /* let Groups: Array<GroupAddingModel> = new Array<GroupAddingModel>();
              let group: GroupAddingModel = new GroupAddingModel();
             
              group.id = undefined;
              group.groupName = "-";
              Groups.push(group);
              const datastations: Array<GroupAddingModel> = data["result"];
              for (let i: number = 0; i < datastations.length; i++) {
                Groups.push(datastations[i]);
              }
              this.GroupAddinglist = Groups;*/
             // console.log(this.data.config);
            } else {
              console.error("Error in fetching all GroupAdding");
            }
          });
      }
}
