export class FTransactionsModel{
   
    transactionId?:number;
    amount?:number;
    appuserName?:String;
    bookingId?:String;
    pgLog?:String;
    status?:string;
    type?:string;
    lastUpdateTime?:Date;
    pgOrderGenTime?:Date;
    pgPaymentId?:string;
    pgOrderId?:string;
    pgSIgnature?:string;
    statusUpdateBy?:string;
}