import { Component, OnInit } from '@angular/core';
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { DatePipe } from "@angular/common";
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { StationsService } from "src/app/services/Stations.Service";
import { StationModel } from "src/app/models/station.model";
import { ChargerService } from "src/app/services/charger.service";
import { OperatorModel } from "src/app/models/operator.model";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { ChargingConfigService } from "../../services/chargingconfig.service";
export class searchField {
  stationId?: number;
  operatorId?: number;
  chargerId?: number;
  chargerName?: string;
  connectorId?: number;
}
export class chargerConfigKey {
  id?:number;
  chargerId?:string;
  port?:string;
  keyName?: string;
  currentValue?: string;
  previousValue?: string;
  newValue?: string;
  chhangeable?: boolean;
  createTS?:Date;
  changeTS?:Date;
  changeSignature?:string;
  tag?:string;
}
@Component({
  selector: 'app-chargerconfig',
  templateUrl: './chargerconfig.component.html',
  styleUrls: ['./chargerconfig.component.css']
})
export class ChargerconfigComponent implements OnInit {
  topbartext: TopbarText[] = [
    { key: "chargerconfig", displayText: "Charger Config" }
  ];
  showConfigModal: boolean=false;
  searchField: searchField;
  stationlist: Array<StationModel>;
  station: StationModel;
  operator: OperatorModel;
  chargers: Array<ManageChargerModel>;
  operatorlist: Array<OperatorModel>;
  chargerslist: Array<ManageChargerModel>;
  keyList:Array<chargerConfigKey>;
  keytoUpdate:chargerConfigKey;
  _menuId: string;
  keyData:chargerConfigKey;
  data: StationModel;
  constructor(
    private ngbModalService: NgbModal,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private chargerConfigService: ChargingConfigService,
    public chargerservice: ChargerService,
    private stationservice: StationsService,
    private operatorservice: OperatorService,
    private managechargerservice: ManageChargerService,
  ) { 
  this._menuId = this.tools.getMenuName("main/chargerconfig");
  this.objGlobal.setActiveMenu(this._menuId);
  this.searchField = new searchField();
  this.data = new StationModel();
  this.keyData = new chargerConfigKey();
  this.keytoUpdate = new chargerConfigKey();
  this.searchField.operatorId= 0;
  this.searchField.stationId= 0;
  this.searchField.chargerId= 0;
  this.searchField.connectorId= 0;
  this.getStations();}

  ngOnInit() {
  }
  async getStations() {
    await this.stationservice
      .getAllStationsWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let stations: Array<StationModel> = new Array<StationModel>();
          let station: StationModel = new StationModel();
          station.stationId = undefined;
          station.stationname = "All";
          stations.push(station);
          this.stationlist = data["result"];
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }
  getStationsList() {
    if (this.searchField.operatorId && this.searchField.operatorId != 0) {
      return this.stationlist.filter(
        obj => obj.operator.operatorId == this.searchField.operatorId
      );
    } else {
      return this.stationlist;
    }
  }
  formatDate(val: Date) {
    let datepipe = new DatePipe("en");
    return datepipe.transform(val, "dd-MM-yyyy hh:mm:ss");
  }
  setStation(){
    this.getDetail(this.searchField.stationId)
      }

      
  setCharger(){
        this.getChargerKeys(this.searchField.chargerId)
          }
          
      async getDetail(_id) {
        console.log(_id)
        if (_id) {
          this.stationservice.getStationDetail(_id).subscribe(data => {
            if (data["success"]) {
              this.data = data["result"];
              if (this.data && this.data.operator) {
                this.operator = this.data.operator;
                console.log(this.data.managechargers);
              } else {
                this.operator = new OperatorModel();
              }
              if (this.data.managechargers) {
                this.chargers = this.tools.copyFullObject(this.data.managechargers);
             } else {
                this.data = new StationModel();
              alert("Failed to get station data!");
            }
          }
        });
      }
    }


    showConfig(key:any){
      let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.size = 'lg';
      this.showConfigModal=true;
      this.keytoUpdate = key;
    //  console.log(this.keytoUpdate);
    }


    hideConfig()
    {
      this.showConfigModal = false;
    }
    
    async getChargerKeys(id) {
        console.log(id)
        if (id) {
          this.chargerConfigService.getConfig(id).subscribe(data => {
            if (data["success"]) {
              this.keyList = data["result"];
            }
              else {
                this.keyData = new chargerConfigKey();
                alert("Failed to get key data!");
              } 
          });
        }
      }

      savekeyConfig(){
        let key:chargerConfigKey= this.keytoUpdate;
        console.log(key)
        if (key) {
          this.chargerConfigService.setKeyConfig(key.chargerId,key.keyName,key.newValue).subscribe(data => {
            if (data["success"]) {
              let ngmodaloptions: NgbModalOptions = {};
              ngmodaloptions.backdrop = "static";
              ngmodaloptions.size = "sm";
              const ngmodalref = this.ngbModalService.open(
                NgInformationModalContent,
                ngmodaloptions
              );
              ngmodalref.componentInstance.title = "Information";
              ngmodalref.componentInstance.message = "Key saved successfully";
            }
              else {
                this.keyData = new chargerConfigKey();
                alert("Failed to update key data!");
              } 
          });
        }
      }
        
}
