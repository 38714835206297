import { Component, Input, OnInit } from '@angular/core';

export class MerchantTabs {
  companyprofiletab: boolean;
  banktab: boolean;
  financialstab: boolean;
}

@Component({
  selector: 'app-chargingdashboard',
  templateUrl: './chargingdashboard.component.html',
  styleUrls: ['./chargingdashboard.component.css']
})
export class CorporatedetailComponent implements OnInit {
 @Input()
  action: string = "";
  @Input()
  tabStatus: MerchantTabs;

  constructor() { }

  ngOnInit() {
  }

  tabClick(tabObj: any) {
    console.log(tabObj);
    console.log(this.tabStatus);
  }

}
