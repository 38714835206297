import { Component, OnInit,Input,Output,EventEmitter, DoCheck } from '@angular/core';
import { FormsModule, Form } from '@angular/forms';
//import { IMultiSelectOption,IMultiSelectSettings,IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
//import {mul}  from 'ng-multiselect-dropdown'
import {UserModel} from '../../models/user.model';
import {UserService} from '../../services/user.service';
import {UserroleService} from '../../services/userrole.service';
import {Tools} from '../../modules/tools';
import {Router} from '@angular/router';
import { NgbModal, NgbActiveModal,NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgYesNoModalContent,NgInformationModalContent } from '../../util/components/modalcomponent/modal-content';
import { AuthService} from '../../services/auth.service';
import {OperatorModel} from '../../models/operator.model';
import { OperatorService } from 'src/app/services/operator.service';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, DoCheck {
 @Input()
 _param : any;
 @Input()
 _menuId : string;
 @Input()
 _action : string;
 //@Output()
 //cancelClickEvent : EventEmitter<object>= new EventEmitter<object>();  
 @Output()
 backClickEvent :  EventEmitter<object>= new EventEmitter<object>();
 @Output()
 saveEvent : EventEmitter<object>= new EventEmitter<object>();
user : UserModel;
dropdownList = [];
selectedItems = [];
mySettings: any;
optionsModel: number[];
myTexts: any;
dropdownSettings= {};
_user :UserModel;
_disableSaveCancel : boolean=true;

operator : OperatorModel;
operatorlist : Array<OperatorModel>;
constructor(private objuserservice : UserService, private tools : Tools,
    private router : Router, private ngbModalService : NgbModal,
    private objuserroleservice : UserroleService,
    private auth : AuthService, private operatorservice : OperatorService) {    
        //console.log("user constructor called"); 
        this.operator=new OperatorModel();
        this.getOperators();
        
    }

  ngOnInit() {
    this.user=new UserModel();              
    this.getRoleIdName();
    this.dropdownSettings = { 
        singleSelection: false,
        idField: 'roleId',
        textField: 'roleName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        showCheckbox : true,
        enableCheckAll:true,
        enableFilterSelectAll:true,
        filterSelectAllText: "All roles selected",
        maxHeight : 200
        }       
    this.selectedItems=undefined;
}
ngDoCheck() {
    this._disableSaveCancel=true;
    if(this.isUserModified()) {        
        if(this.isAllowAddorEdit()) {            
            this._disableSaveCancel=false;        
        }
    }  
}
onItemSelect(item:any){
    this.roleSelectDeselect();
}
OnItemDeSelect(item:any){
    this.roleSelectDeselect();
}
onSelectAll(items: any){
    //this.roleSelectDeselect();
    for(let i=0;i<this.dropdownList.length;i++) {
        if(!this.isRoleExistsInUser(this.dropdownList[i].roleId)) {
            if(!this.user.roleId) {this.user.roleId=new Array<number>()}
            this.user.roleId.push(this.dropdownList[i].roleId);
        }
    }
   // this.user.roleId=this.dropdownList;
}
isRoleExistsInUser(roleId : number) {
    if(this.user.roleId) {
        for(let i=0 ; i<this.user.roleId.length;i++) {
            if(this.user.roleId[i]==roleId) {
                return true;
            }
        }
    }
    return false;
}
onDeSelectAll(items: any){
    this.roleSelectDeselect();
}
roleSelectDeselect() {
    var roleIds : Array<number>=new Array<number>();
    this.selectedItems.forEach(itm => {
        roleIds.push(itm.roleId);
    });
    this.user.roleId=roleIds;
}
saveUser(f:Form) {
    if(this.user.password != this.user.userRePassword && this._action =="new") {
        let ngmodaloptions : NgbModalOptions = {};
        ngmodaloptions.backdrop='static';
        ngmodaloptions.size="sm"
        const modalRef=this.ngbModalService.open(NgInformationModalContent,ngmodaloptions);
        modalRef.componentInstance.title="Alert";
        modalRef.componentInstance.message="Password mismatch!";
        modalRef.componentInstance.btnCLass="btn-info"
        modalRef.result.then((result) => {
            return;
        },
        (reason) => {
            return;
        });
        
    } else {
        this.objuserservice.saveUser(this.user).subscribe(data =>{    
            let ngmodaloptions : NgbModalOptions = {};
            ngmodaloptions.backdrop='static';
            ngmodaloptions.size="sm"    
            if(data["success"]) {                
                const modalRef=this.ngbModalService.open(NgInformationModalContent,ngmodaloptions);
                modalRef.componentInstance.title="Information";
                modalRef.componentInstance.message="User saved successfully!";
                modalRef.componentInstance.btnCLass="btn-info"
                modalRef.result.then((result) => {
                    this._user=this.tools.copyFullObject(this.user);
                    if(result=='ok'){
                        this.saveEvent.emit({success: true});
                    }
                },
                (reason) => {

                });
                //this.backClickEvent.emit();
                //this.backClickEvent.emit({userId: this.user.userId,isModified : this.isUserModified()});
                
            } else {        
                const modalRef=this.ngbModalService.open(NgInformationModalContent,ngmodaloptions);
                modalRef.componentInstance.title="Error";
                modalRef.componentInstance.message="Failed to save User!";
                modalRef.componentInstance.btnCLass="btn-warning"
                modalRef.result.then((result) => {
                    this._user=this.tools.copyFullObject(this.user);
                },
                (reason) => {

                });
                alert("user not saved");       
            }    
        })
    }
}

getUserDetail(param :any, action : string) {
    this.selectedItems =undefined;
    let _id : number;
    if(param) {
        _id=param.id;
    }

    if(_id) {
        this.objuserservice.getUserDetail(_id).subscribe(data =>{
        if(data["success"]) {              
            this.user= data["result"];
            this._user=this.tools.copyFullObject(this.user);
            const roleIds : Array<number> = this.user.roleId;
            const roleNames : Array<string> = this.user.roleName;
            let roles : Array<Object> = new Array<Object>();
            for(let i=0; i<roleIds.length; i++) {                
                roles.push({roleId : roleIds[i], roleName : roleNames[i]})
            }
            this.selectedItems=roles;
        } else {
        alert("Failed to get user list!")
        }    
    })
}
}
addNewUser() {
    this.user=new UserModel();
    this.user.operatorId=0;
    this.user.distributorId=0;
    this.selectedItems=undefined;
    this._user = Object.assign({},this.user);
}
isActionNew(){
    if(this._action == 'new') {
        return true;
    } else {
        return false;
    }
}
onCancelClick() {
    if(this.isUserModified() ==true) {
        let ngmodaloptions : NgbModalOptions = {};
        ngmodaloptions.backdrop='static';
        ngmodaloptions.size="sm"
        const ngmodalref = this.ngbModalService.open(NgYesNoModalContent,ngmodaloptions);
        ngmodalref.componentInstance.title = "Confirmation";
        ngmodalref.componentInstance.message = "Are you sure to discard changes?";
        ngmodalref.result.then((result) => {
          if(result=="yes") {
           this.user=this.tools.copyFullObject(this._user);
          }
        },
        (reason) => {
    
        });
      }
    //this.cancelClickEvent.emit({userId: this.user.userId,isModified : this.isUserModified()});
}
onBackbuttonClick() {
  this.backClickEvent.emit({userId: this.user.userId,isModified : this.isUserModified()});
}
isUserModified() {    
    let user1=this.user;
    let user2=this._user;
    
    if(!(user1 && user2)) {
        return false;
    }
    if(user1.username != user2.username) {
        return true;
    } else if(user1.name != user2.name) {
        return true;
    } else if (user1.userEmail !=user2.userEmail) {
        return true;
    } else if(user1.password !=user2.password) {
        return true;
    } else if(user1.userRePassword !=user2.userRePassword) {
        return true;
    } else if(user1.operatorId !=user2.operatorId) {
        return true;
    } else if((user1.roleId && (!user2.roleId))||((!user1.roleId) && user2.roleId)) {
        return true;
    } else if (!(user1.roleId && user2.roleId)) {
        return false;
    }  else if(user1.roleId.length != user2.roleId.length) {
        return true;
    }  else {        
        for(let i=0; i<user1.roleId.length; i++) {
            let found=false;
            for(let j=0; j<user2.roleId.length;j++) {
                if(user1.roleId[i]==user2.roleId[j]) {
                    found=true;
                    break;
                }
            }
            if(!found) {
                return true;
            }
        }
    }
    return false;
}

getRoleIdName() {
        this.objuserroleservice.getRoleIdName().subscribe(data =>{
        if(data["success"]) {              
            this.dropdownList= data["result"];            
        } else {
        alert("Failed to get role id and name list!")
        }    
    })
}
isAllowAddorEdit() {
    let isAllowSave=false;
    if(this._action=="new") {
        isAllowSave=this.auth.isPrivExists(this._menuId + "=>add");
    } else if(this._action=="update") {    
        isAllowSave=this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

  disableSave(f : any) {
    let isDisableSave : boolean=true; 
    if(f.valid) {
        console.log(this.isUserModified()); 
        if(this.isUserModified()) {        
            if(this.isAllowAddorEdit()) {            
                isDisableSave=false;        
            }
        }  
    }  
    return isDisableSave; 
  }
  disableCancel() {
    return !this.isUserModified();
  }

  onSubmit() {
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.user))
  }
  async getOperators() {
    this.operatorlist=undefined;
    await this.operatorservice.getAllOperators().toPromise().then(data => {
      if(data["success"]) {
        this.operatorlist=data["result"];
      } else {
        console.error("Error in fetching all operators");
      }
    });
  }
  dataOperatorId() {
  if(!this.user.operatorId) {
    return 
  } else if (!this.user.operatorId) {
    return undefined;
  } else {
      this.getOperators();
    return this.user.operatorId;
  }
}

}
