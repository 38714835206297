import { UserEVModel } from "./userEV.model";
import { TransactionModel } from "./transaction.model";

export class BookingModel {
  bookingId?: number;
  chargerName?: string;
  chargingpoint?: string;
  userEVId?: number;
  userEV?: UserEVModel;
  username?: string;
  ScheduleId?: number;
  start_time?: Date;
  stop_time?: Date;
  discountcode?: string;
  requested_stop_duration?:number;
  requested_stop_time?:Date;
  requested_stop_unit?:number;
  book_time?:Date;
  location?:string;
  amount?:number;
  planId?:number;
  scheduledID?:number;
  vehicleName?:string;
  stopReason?:string;
  vehicleChasis?:string;
  vin?:string;
  paymentId?:string;
  /*
   * S- Booking accepted
   * R -  Charging Running
   * C - Charging Complete
   */
  bookedvia?:string;
  pricing?: number;
  damount?:number;
  tdamount?:number;
  extracharges?:number;
  taxes?:number;
  status?: string;
  tran_id?: number;
  stopchargingby?:string;
  userstopattempts?:string;
  userstartattempts?:string;
}
