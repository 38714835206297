import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppSettings } from '../config/AppSettings';
import {GridPage} from '../util/components/gridtable/gridtable.component';
import { Tools } from '../modules/tools';
import { PortModel } from '../models/chargerPort.model';
import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private http: HttpClient, private appsetting : AppSettings,private tools: Tools) { }

  getLogs(pageinfo : GridPage, searchField) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("page", pageinfo.number.toString());
    httpParam=httpParam.append("size",pageinfo.size.toString());
    httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));    
    if (searchField.chargerId) {
      httpParam = httpParam.append("charger", searchField.chargerId);
    }  
    if (searchField.api) {
      httpParam = httpParam.append("api", searchField.api);
    } 
    return this.http.get( this.appsetting.get("server-url") + '/api/getlogs',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }
  getNetworkLogs(pageinfo : GridPage, searchField) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
    httpParam=httpParam.append("page", pageinfo.number.toString());
    httpParam=httpParam.append("size",pageinfo.size.toString());
    httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
    httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));    
    if (searchField.chargerId) {
      httpParam = httpParam.append("charger", searchField.chargerId);
    }  
    if (searchField.state) {
      httpParam = httpParam.append("state", searchField.state);
    } 
    return this.http.get( this.appsetting.get("server-url") + '/api/getnetworklogs',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }
  getData(){
    let header = new HttpHeaders();
    let param : HttpParams=new HttpParams();
    return this.http.get( './assets/test.json',
    {headers : header, observe : "body", params : param,responseType : "json"})
  .map(res=>res);
  }
  getLogsByCharger(chargerName:string) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("chargerName",chargerName.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/getlogsbyCharger", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  getNetworkLogsByCharger(chargerName:string) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("chargerName",chargerName.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/getnetworklogsbyCharger", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  getOcppStatus(chargerName:string, port:number){
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("chargerName",chargerName.toString());
    param = param.append("port",port.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/getocppstatus", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
}
